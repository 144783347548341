// appPerms.js
import Builder, { hasAllGrants, hasAnyGrant } from "@armus/perm-utils";
const b = new Builder();
b.addAlias("CUSTOM_6","SYSTEM_NOTIFICATION");
const permsMethods = {
    perms: b.build(),
    hasAllGrants,
    hasAnyGrant
};
export default permsMethods;
