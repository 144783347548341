import React from "react";
import Label from "./Label";
import Text from "./Text";
import Typeahead from "./Typeahead";
import Select from "./Select";
import DatePicker from "./DatePicker";
import Group from "./Group";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import DescriptorErrorContent from "../dashboard/components/DescriptorErrorContent";
import { buildPropTypesWithDescriptor } from "../../lib/propTypeHelpers";
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
import Slider from "./Slider";

const ControlResolver = (props) => {
  const {
    isLoading,
    descriptor,
    data,
    value,
    onClick,
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
  } = props;
  let Component = null;
  switch (descriptor.type) {
    case "button":
      Component = Button;
      break;
    case "buttongroup":
      Component = ButtonGroup;
      break;
    case "label":
      Component = Label;
      break;
    case "text":
      Component = Text;
      break;
    case "slider":
      Component = Slider;
      break;
    case "select":
      Component = Select;
      break;
    case "typeahead":
      Component = Typeahead;
      break;
    case "date":
      Component = DatePicker;
      break;
    case "checkbox":
      Component = Checkbox;
      break;
    case "radio":
      Component = Radio;
      break;
    case "group":
      Component = Group;
      break;
    default:
      Component = (props) => <DescriptorErrorContent descriptor={descriptor} />;
  }
  const v = value[descriptor.id] || null;
  return (
    <Component
      {...props}
      key={descriptor.id}
      isLoading={isLoading}
      descriptor={descriptor}
      data={descriptor.dataKey ? data[descriptor.dataKey] : undefined}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      onKeyDown={onKeyDown}
      value={v}
      isResolver={true}
    />
  );
};

ControlResolver.propTypes = buildPropTypesWithDescriptor();

export default ControlResolver;
