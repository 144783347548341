import React from "react";
import PropTypes from "prop-types";
import makeSxStyles from "../../lib/makeSxStyles";
import { Box, Divider, Link, Paper } from "@mui/material";
import { formatMessage } from "../providers/IntlProvider";

const useStyles = makeSxStyles((theme) => ({
    root: {
        "& .error-button": {
            width: 215
        }
    },

}));

const ErrorLayout = ({code, homeUrl}) => {
    const classes = useStyles();
    let body;
    switch(code) {
        case 401:
        case 403: 
            body = (
                <>
                    <Box className="content-column">
                        <Box className="access-error-img"></Box>
                    </Box>
                    <Box className="content-column">
                        <Box className="error-header-string">
                            {formatMessage({id: "ErrorPage.accessDenied.title", defaultMessage: "Access Denied"})}
                        </Box>
                        <Box className="error-message-light">
                            {formatMessage({id: "ErrorPage.accessDenied.message", defaultMessage: "Looks like you are missing permissions to access this resource. Please contact your systems administrator."})}
                        </Box>
                    </Box>
                </>
            );
            break;
        case 500:
        case 999:
            body = (
                <>
                    <Box className="content-column">
                        <Box className="error-img"></Box>
                    </Box>
                    <Box className="content-column">
                        <Box className="error-header-string">
                            {formatMessage({id: "ErrorPage.unknownError.title", defaultMessage: "Oops!"})}
                        </Box>
                        <Box className="error-message-light">
                            {formatMessage({id: "ErrorPage.unknownError.message", defaultMessage: "An unexpected error has occurred please refresh or return to the home page."})}
                        </Box>
                    </Box>
                </>
            );
            break;
        case 404:
        default: 
            body = (
                <>
                    <Box className="content-column">
                        <Box className="error-img"></Box>
                    </Box>
                    <Box className="content-column">
                        <Box className="error-header-code">
                            {formatMessage({id: "ErrorPage.404.title", defaultMessage: "404"})}
                        </Box>
                        <Box className="error-message">
                            {formatMessage({id: "ErrorPage.404.message", defaultMessage: "Sorry, we can't find the page you're looking for!"})}
                        </Box>
                    </Box>
                </>
            );
    }

    return (
        <Paper className="error-tile" sx={classes.root}>
            {body}
            <Box height={80}>
                <Divider />
                <Link href={homeUrl} className="error-button">Go to Homepage</Link>
            </Box>
        </Paper>
    );
};

ErrorLayout.defaultProps = {
    code: 404,
    homeUrl: "/",
};

ErrorLayout.propTypes = {
    code: PropTypes.number,
    homeUrl: PropTypes.string,
};

export default ErrorLayout;
