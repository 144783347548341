import React from "react";
import PropTypes from "prop-types";
import { Button, Box } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.onRefresh = this.onRefresh.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  onRefresh(e) {
    e.preventDefault();
    this.setState(() => ({ hasError: false }));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.errorContent) {
        return this.props.errorContent;
      }

      return (
        <Box p={2}>
          Something went wrong.{" "}
          {this.props.allowRetry && <Button color={"secondary"} size="small" onClick={this.onRefresh}>
            {this.props.retryText}
          </Button>}
        </Box>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.defaultProps = {
  allowRetry: true,
  retryText: "Click here to try again."
};

ErrorBoundary.propTypes = {
  allowRetry: PropTypes.bool,
  retryText: PropTypes.string
};

export default ErrorBoundary;
