import React from "react";
import PropTypes from "prop-types";
import {
    Typography,
    Icon,
    Box,
} from "@mui/material";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        //marginLeft: theme.spacing(-1)
    },
    title: {
        cursor: "pointer",
    },
    buttonContainer: {
        width: 16,
        display: "inline-block",
    },
    titleContainer: {
        width: "calc( 100% - " + 16 + "px)",
        display: "inline-block",
    },
    button: {
        height: 16,
        width: 16,
        minWidth: 16,
        marginLeft: -4,
        marginTop: -6,
        background: "transparent",
        verticalAlign: "middle",
        border: "none",
        borderRadius: 4,
        cursor: "pointer",
        padding: 0,
        color: theme.palette.text.main
    },
    buttonIcon: {
        height: 16,
        width: 16,
        padding: 0,
    },
    content: {
        transition: ".25s",
        overflow: "auto",
        maxHeight: 2000,
        opacity: 1,
    },
    hide: {
        maxHeight: 0,
        opacity: 0,
    }
}));

const SectionExpando = ({show = true, title, children, indentContent = 3, ...extraProps}) => {
    const [expanded, setExpanded] = React.useState(show);
    const classes = useStyles();
    const handleExpand = () => setExpanded(!expanded);
    React.useEffect(() => {
        setExpanded(show);
    }, [show]);
    return (
        <Box component={"section"} sx={classes.root} {...extraProps}>
            <Box>
                <Box sx={classes.buttonContainer}>
                    <button
                        style={classes.button}
                        onClick={handleExpand}
                        size="small"
                        color="minimal"
                    >
                        <Icon sx={classes.buttonIcon}>{expanded ? "keyboard_arrow_down_icon" : "keyboard_arrow_right_icon"}</Icon>
                    </button>
                </Box>
                <Box sx={classes.titleContainer}>
                    <Box sx={classes.title} onClick={handleExpand}>
                    {typeof title === "string" ? (
                            <Typography component={"span"}>
                                <strong>{title}</strong>
                            </Typography>
                        ) : (
                            <React.Fragment>{title}</React.Fragment>
                        )
                    }
                    </Box>
                </Box>
            </Box>

            <Box sx={{...classes.content, ...(expanded ? {} : classes.hide)}} pl={indentContent}>
                {children}
            </Box>
        </Box>
    );
};
SectionExpando.defaultProps = {
    show: true,
    title: "",
    indentContent: 2,
};

SectionExpando.propTypes = {
    ...Box.propTypes,
    show: PropTypes.bool,
    title: PropTypes.any,
    indentContent: PropTypes.number,
};

export default SectionExpando;

