import React from "react";
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

const LoadingWrapper = (props) => {
    const { status, children } = props;
    switch (status) {
        case "ERROR":
            return (
                <Typography>
                    Something went wrong with your request, please try again in a few moments.
                </Typography>
            );
        case "LOADED":
            return children;
        case "LOADING":
        default:
            return (
                <Typography component="div" align="center">
                    <CircularProgress/><br/>
                    Loading...
                </Typography>
            );
    }
};

LoadingWrapper.propTypes = {
    status: PropTypes.string
};

export default LoadingWrapper;
