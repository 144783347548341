import React from "react";
import {Dialog as DialogView, useDialogs} from "@armus/armus-dashboard";

const Dialog = (props) => {
    const {queue, onHide} = useDialogs();
    return (
        <DialogView {...props} queue={queue} onHideDialog={onHide} />
    );
};

Dialog.propTypes = {
};

export default Dialog;
