const hasKey = (key) => {
    return localStorage.getItem(key) != null;
};

const load = (key) => {
    if(!hasKey(key)) return null;
    return JSON.parse(localStorage.getItem(key));
};

const save = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};
const remove = (key) => {
    localStorage.removeItem(key);
};

const reset = () => {
    localStorage.clear();
};

const storage = {
    hasKey,
    load,
    save,
    remove,
    reset,
};
export default storage;