import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "../providers/IntlProvider";

const FormattedMessage = ({id, defaultMessage, values = []}) => {
    const intl = useIntl();
    return (
        <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id, defaultMessage}, values)}}/>
    );
};

FormattedMessage.propTypes = {
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
    description: PropTypes.string,
    values: PropTypes.object
};

export default FormattedMessage;