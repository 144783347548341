import * as d3 from "d3";

// TODO: This file needs to be cleaned up and moved into the common/Theme Provider.

export const COLOR_THEME_OPTIONS = {
    series: "series",
    primary: "primary",
    secondary: "secondary",
    success: "success",
    warning: "warning",
    error: "error",
    info: "info",
    default: "default",
    auto: "",
    autoLinear: "autoLinear",
    autoBucket: "autoBucket",
    autoThreshold: "autoThreshold",
    autoConfidence: "autoConfidence",
    autoDumbbell: "autoDumbbell",
};

export const getColorScheme = (theme, config = {}, returnArray = false) => {
    const cfg = {
        dataKeys: [],
        markers: [],
        valueKey: "value",
        colorScheme: "series",
        colorSchemeReversed: false,
        steps: undefined,
        min: 0,
        mid: undefined,
        max: 100,
        opacity: 1,
        ...config
    };
    cfg.mid =  cfg.mid !== undefined ? cfg.mid : (cfg.max - cfg.min) / 2 + cfg.min;
    cfg.steps = cfg.steps !== undefined ? cfg.steps : cfg.dataKeys.length;
    const name = cfg.colorScheme;
    let colors = Array.isArray(cfg.colorScheme) ? cfg.colorScheme : (theme.colors[name] || theme.colors.default);
    if(!Array.isArray(colors)) {
        colors = [colors];
    }
    if(name === "auto") {
        colors = theme.colors.auto || [theme.colors['error'],theme.colors['warning'],theme.colors['success']];
    }
    else if(name.indexOf("auto") !== -1) {
        colors = getColorScheme(theme, {
            colorSchemeReversed: cfg.colorSchemeReversed,
            colorScheme: "auto"},
            true
        );
        if (!returnArray) {
            // AUTO THEME FUNCTIONS BUILT
            switch (name) {
                case COLOR_THEME_OPTIONS.autoBucket:
                    return buildAutoBucketColorValueFunc(colors, cfg);
                case COLOR_THEME_OPTIONS.autoThreshold:
                    return buildAutoThresholdColorValueFunc(colors, cfg);
                case COLOR_THEME_OPTIONS.autoConfidence:
                    return buildAutoConfidenceColorValueFunc(theme, colors, cfg);
                case COLOR_THEME_OPTIONS.autoDumbbell:
                    return buildAutoDumbbellColorValueFunc(theme, colors, cfg);
                case COLOR_THEME_OPTIONS.autoLinear:
                default:
                    return buildAutoLinearColorValueFunc(colors, cfg);
            }
        }
    }

    colors = ramp(
        [...colors],
        (cfg.steps || colors.length)
    ).map(c => {
        c = safeColor(c);
        return c.copy({opacity: c.opacity || cfg.opacity}).formatRgb();
    });

    if(cfg.colorSchemeReversed) colors.reverse();
    if(returnArray) {
        return colors;
    }
    const valueToColorFunc = buildAutoLinearColorValueFunc(colors, cfg);

    return (data) => {
        if(name.indexOf("auto") === -1 && (data.index !== undefined || data.info !== undefined)) {
            const index = (
                cfg.dataKeys && cfg.dataKeys.length > 1 && cfg.dataKeys.indexOf(data.id) !== -1 ? // grouped data
                    cfg.dataKeys.indexOf(data.id)
                    : data.data && data.data.info ? // info available
                    data.data.info.index % colors.length
                    : data.info ?
                        data.info.index % colors.length
                        : data.index % colors.length
            );
            return safeColor(colors[index]).formatRgb();
        }
        else if(data.value !== undefined) {
            return valueToColorFunc(data);
        }
        else if(data.color !== undefined) {
            return data.color;
        }
        return safeColor();
    };
};

export const ramp = (colors, steps = 4) => {
    if(colors.length === 0) {
        throw Error("No colors provided to ramp between.");
    }
    if(colors.length === 1) {
        colors = [...colors];
        colors.push(colorShift(colors[0], {s: 0, l: Math.min(Math.max(1, (steps/3)), 3)}));
    }
    if(colors.length >= steps) {
        return colors;
    }

    const domain = interpolateArray([0,steps], colors.length);

    const colorsScaler = d3.scaleLinear().domain(domain).clamp([0,steps]).range(colors);
    const result = [];
    for (let i = 0; i < steps; ++i) {
        result.push(colorsScaler(i));
    }
    return result;
};

export const buildAutoLinearColorValueFunc = (colors, cfg) => {
    const valueKey = cfg.valueKey || "value";
    const markerValues = cfg.markers.map((m) => m.value);
    markerValues.sort(function(a, b) { return a-b; });

    let domain = [ cfg.min, cfg.mid, cfg.max ];
    if(markerValues.length === 1) {
        domain[1] = markerValues[0];
    }
    else {
        markerValues.forEach((v, i) => {
            domain[i] = v;
        });
    }
    domain = interpolateArray(domain, colors.length);
    let t = d3.scaleLinear().domain(domain).clamp([domain[0], domain[domain.length-1]]).range(colors);
    return (data) => {
        const value = Math.abs(data[valueKey]);
        const c = safeColor(t(value));
        return d3.color(c).copy({opacity: c.opacity || cfg.opacity}).formatRgb();
    };
};

export const buildAutoBucketColorValueFunc = (colors, cfg) => {
    const valueKey = cfg.valueKey || "value";
    const markerValues = cfg.markers.map((m) => m.value);
    markerValues.sort(function(a, b) { return a-b; });
    let domain = [ cfg.min, cfg.mid, cfg.max ];
    if(markerValues.length === 1) {
        domain[1] = markerValues[0]; // set middle
    }
    else {
        markerValues.forEach((v, i) => {
            domain[i] = v;
        });
    }

    domain = interpolateArray(domain, colors.length);
    const dx = d3.scaleLinear().domain(domain).clamp([domain[0], domain[2]]).range([0,1,2]).clamp([0, 2]);
    const t = (value) => safeColor(colors[Math.round(dx(value))]);
    return (data) => {
        const value = Math.abs(data[valueKey]);
        const c = t(value);
        return d3.color(c).copy({opacity: c.opacity || cfg.opacity}).formatRgb();
    };
};

export const buildAutoThresholdColorValueFunc = (colors, cfg) => {
    const valueKey = cfg.valueKey || "value";
    const markers = [...cfg.markers];
    if(cfg.uniqueMarkers) {
        return (data) => {
            const tempColors = [...colors];
            // each marker is for a specific series index
            const value = Math.abs(data[valueKey]);
            let c = safeColor();
            const m = markers[data.index];
            if(m) {
                if(m.colorReversed) {
                    tempColors.reverse();
                }
                if(value > m.value) {
                    c = tempColors[tempColors.length-1];
                }
                else {
                    c = tempColors[0];
                }
            }
            return d3.color(c).copy({opacity: c.opacity || cfg.opacity}).formatRgb();
        };
    }
    markers.sort(function(a, b) { return a.value-b.value; });
    return (data) => {
        const value = Math.abs(data[valueKey]);
        let c = null;
        markers.forEach((m, i) => {
            if(value > m.value) {
                c = colors[colors.length-1];
            }
        });
        if(c == null) {
            c = colors[0];
        }
        return d3.color(c).copy({opacity: c.opacity || cfg.opacity}).formatRgb();
    };
};

export const buildAutoConfidenceColorValueFunc = (theme, colors, cfg) => {
    const valueKey = cfg.valueKey || "value";
    const markerValues = cfg.markers.map((m) => m.value);
    markerValues.sort(function(a, b) { return a-b; });
    const threshold = markerValues[0];
    const t = (value, min, max) => {
        let c = theme.textColor;
        if(min !== undefined && max !== undefined) {
            if (min > threshold && max > threshold) {
                c = colors[colors.length - 1];
            }
            if (max < threshold && min < threshold) {
                c = colors[0];
            }
        }
        else {
            c = value > threshold ? colors[colors.length - 1] : colors[0];
        }
        return safeColor(c);
    };
    return (data) => {
        const value = data[valueKey];
        const c = t(value, data.min, data.max);
        return d3.color(c).copy({opacity: c.opacity || cfg.opacity}).formatRgb();
    };
};


export const buildAutoDumbbellColorValueFunc = (theme, colors, cfg) => {
    const valueKey = cfg.valueKey || "value";
    const t = (value, value2) => {
        const diff = value-value2;
        let c = theme.textColor;
        if(value2 !== undefined) {
            if (diff > 0) {
                c = colors[colors.length - 1];
            }
            else {
                c = colors[0];
            }
        }
        return safeColor(c);
    };
    return (data) => {
        const value = data[valueKey];
        const c = t(value, data["value2"]);
        return d3.color(c).copy({opacity: c.opacity || cfg.opacity}).formatRgb();
    };
};


export const colorFunctionToArray = (colorScheme, min = 0, max = 1, steps = 1) => {
    const dx = (max-min)/steps;
    const colors = [];
    for (let i = 0; i < steps; i++) {
        colors.push(colorScheme({value: min + (i * dx)}));
    }
    return colors;
};

// makes a brighter or darker color
// automatically creates a second color brighter or darker based on the
// provided color.
export const colorShift = (color, opts = {}, auto = true) => {
    const offsets = {
        r: 0,
        g: 0,
        b: 0,
        h: 0,
        s: 0,
        l: 0,
        opacity: 0,
        ...opts,
    };
    const mid = {
        r: 127,
        g: 127,
        b: 127,
        h: 180,
        s: 0.5,
        l: 0.5,
        opacity: 0.5,
    };
    let c = safeColor(color);
    const t = (k) => {
        const d = (c[k] > mid[k]) ? -1 : 1;
        c[k] *= auto && k === "l" ? 1 + (d * offsets[k]) : 1 + offsets[k];
    };
    // transform RGB
    ['r','g','b'].forEach(t);

    // transform HSL and opacity
    c = d3.hsl(c);
    ['h','s','opacity'].forEach(t);

    if(offsets.l !== 0) {
        // use rgb brighter/darker functions instead of hsl.
        c = auto ?
            (c.l >= mid.l ? d3.rgb(c).darker(Math.abs(offsets.l)) : d3.rgb(c).brighter(Math.abs(offsets.l)))
            : (offsets.l < 0 ? d3.rgb(c).darker(Math.abs(offsets.l)) : d3.rgb(c).brighter(Math.abs(offsets.l)));
    }
    return c.formatRgb();
};

function interpolateArray(data, fitCount) {
    const linearInterpolate = function (before, after, atPoint) {
        return before + (after - before) * atPoint;
    };

    const newData = [];
    const springFactor = (data.length - 1) / (fitCount - 1);
    newData[0] = data[0]; // for new allocation
    for ( let i = 1; i < fitCount - 1; i++) {
        const tmp = i * springFactor;
        const before = Math.floor(tmp).toFixed();
        const after = Math.ceil(tmp).toFixed();
        const atPoint = tmp - before;
        newData[i] = linearInterpolate(data[before], data[after], atPoint);
    }
    newData[fitCount - 1] = data[data.length - 1]; // for new allocation
    return newData;
};

export const safeColor = (color) => d3.color(color) || d3.color("#ddd");