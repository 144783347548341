import React from "react";
import ChartResolver from "../../charts/ChartResolver/ChartResolver";
import { Grid, Paper, Divider, Box } from "@mui/material";
import PropTypes from "prop-types";
import { buildDescriptorPropTypes } from "../../../lib/propTypeHelpers";
import TabResolver from "./TabResolver";
import SectionResolver from "./SectionResolver";
import ControlResolver from "../../controls/ControlResolver";
import DescriptorErrorContent from "./DescriptorErrorContent";
import ErrorBoundary from "../../common/ErrorBoundary";

const defaultItemHeight = 50;

const DescriptorResolver = ({
  isLoading,
  descriptor,
  data,
  value,
  onClick,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  isElevated,
  disabled = false,
  enablement,
  visibility,
}) => {
  if (!descriptor) {
    descriptor = {
      id: "empty",
      label: "Invalid Descriptor",
      description: "Missing or invalid descriptor shape.",
      config: {},
      items: [],
    };
  }
  let content;
  const config = descriptor.config || {};
  let w = config.width || 12,
    h = config.height || 6;
  let elevate = config.elevate !== undefined ? config.elevate : false;
  let showChildren = true;

  const dataKeyValue = descriptor.dataKey
    ? data[descriptor.dataKey]
    : undefined;
  const v = value[descriptor.id] || null;
  if (typeof visibility === "function") {
    if (visibility(descriptor, v, value, dataKeyValue, data) === false) {
      return null;
    }
  }
  if (config.visible === false) {
    return null;
  }
  disabled =
    disabled || (config.enabled !== undefined ? !config.enabled : false);
  if (typeof enablement === "function") {
    disabled =
      disabled || !enablement(descriptor, v, value, dataKeyValue, data);
  }

  const height = h > 12 ? h : h * defaultItemHeight;
  let id = undefined;

  switch (descriptor.type) {
    // common
    case "tabs": {
      content = (
        <ErrorBoundary
          key={descriptor.id}
          errorContent={
            <DescriptorErrorContent descriptor={descriptor} errorType="error" />
          }
        >
          <TabResolver
            key={descriptor.id}
            isLoading={isLoading}
            descriptor={descriptor}
            data={data}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            height={height}
            isElevated={isElevated || elevate}
            disabled={disabled}
            enablement={enablement}
            visibility={visibility}
          />
        </ErrorBoundary>
      );
      showChildren = false;
      break;
    }
    case "tab": {
      content = null;
      w = 12;
      showChildren = true;
      break;
    }
    case "paragraph": {
      content = (
        <Box pl={2} pr={2}>
          <Box 
            sx={{
              fontWeight: "normal",
              whiteSpace: "nowrap",
              fontSize: "125%",
              textAlign: "left",
            }}
          >
            {descriptor.label}
          </Box>
          <Box mt={2}>
            {descriptor.description}
          </Box>
        </Box>
      );
      break;
    }
    case "section":
    case "header": {
      content = (
        <ErrorBoundary
          key={descriptor.id}
          errorContent={
            <DescriptorErrorContent descriptor={descriptor} errorType="error" />
          }
        >
          <SectionResolver
            key={descriptor.id}
            isLoading={isLoading}
            descriptor={descriptor}
            data={data}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            height={height}
            isElevated={isElevated || elevate || true}
            disabled={disabled}
            enablement={enablement}
            visibility={visibility}
          />
        </ErrorBoundary>
      );
      showChildren = false;
      break;
    }
    case "divider": {
      content = <Divider />;
      w = 12;
      showChildren = false;
      break;
    }
    // inputs resolver
    case "label":
    case "input":
    case "text":
    case "slider":
    case "select":
    case "typeahead":
    case "checkbox":
    case "radio":
    case "date":
    case "button":
    case "buttongroup":
      elevate = config.elevate !== undefined ? config.elevate : true;
      content = (
        <ErrorBoundary
          key={descriptor.id}
          errorContent={
            <DescriptorErrorContent descriptor={descriptor} errorType="error" />
          }
        >
          <ControlResolver
            isLoading={isLoading}
            descriptor={descriptor}
            data={data}
            value={value}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            height={height}
            disabled={disabled}
            enablement={enablement}
            visibility={visibility}
          />
        </ErrorBoundary>
      );
      break;
    // charts
    case "bar":
    case "line":
    case "confidence":
    case "dumbbell":
    case "pie":
    case "sunburst":
    case "gauge":
    case "table":
    case "advancedTable":
    case "heatmap":
    case "box": {
      elevate = config.elevate !== undefined ? config.elevate : true;
      content = (
        <ErrorBoundary
          key={descriptor.id}
          errorContent={
            <DescriptorErrorContent descriptor={descriptor} errorType="error" />
          }
        >
          <ChartResolver
            isLoading={isLoading}
            descriptor={descriptor}
            data={data}
            onClick={onClick}
            height={height}
            disabled={disabled}
            enablement={enablement}
            visibility={visibility}
          />
        </ErrorBoundary>
      );
      break;
    }
    case "dashboard":
    case "grid": {
      id = descriptor.id;
      showChildren = true;
      elevate = config.elevate !== undefined ? config.elevate : false;
      content = null;
      break;
    }
    default:
      content = <DescriptorErrorContent descriptor={descriptor} />;
  }

  if (descriptor.items && descriptor.items.length > 0 && showChildren) {
    // descriptor has children items
    content = (
      <React.Fragment>
        {content}
        <Grid container spacing={1} mb={2} style={{ flexGrow: 1 }}>
          {descriptor.items.map((d, i) => (
            <ErrorBoundary
              key={`${descriptor.id}${(d || {}).id}${i}`}
              errorContent={
                <DescriptorErrorContent
                  descriptor={descriptor}
                  errorType="error"
                />
              }
            >
              <DescriptorResolver
                isLoading={isLoading}
                descriptor={d}
                data={data}
                value={value}
                onClick={onClick}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                isElevated={isElevated || elevate}
                disabled={disabled}
                enablement={enablement}
                visibility={visibility}
              />
            </ErrorBoundary>
          ))}
        </Grid>
      </React.Fragment>
    );
  }

  if (elevate && !isElevated) {
    // add elevation if not already elevated.
    content = (
      <Box>
        <Paper>{content}</Paper>
      </Box>
    );
  }

  return (
    <Grid id={id} key={`${descriptor.id}`} item xs={12} md={w}>
      {content}
    </Grid>
  );
};

DescriptorResolver.propTypes = {
  descriptor: PropTypes.shape(buildDescriptorPropTypes()).isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  value: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default DescriptorResolver;
