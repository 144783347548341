import React from "react";
import ErrorBoundary from "./views/components/ErrorBoundary";
import Router from "./Router";
import {BrowserRouter} from "react-router-dom";
import { DashboardProvider, defaultThemes, NotificationsProvider, DialogsProvider } from "@armus/armus-dashboard";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";

// initialize stores
import {AppStoreProvider} from "./data";
const App = () => (
    <AppStoreProvider>
        <DashboardProvider themes={defaultThemes}>
            <DialogsProvider>
                <NotificationsProvider>
                    <CssBaseline />
                    <BrowserRouter>
                        <ErrorBoundary>
                            <Router />
                        </ErrorBoundary>
                    </BrowserRouter>
                </NotificationsProvider>
            </DialogsProvider>
        </DashboardProvider>
    </AppStoreProvider>
);

export default App;
