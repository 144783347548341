import {
    buildAsyncReducer,
    buildAsyncActions,
    asyncInitialState
} from "./utils/asyncReducer";

const initialState = {
    ...asyncInitialState,
    data: []
};
export const streamsActions = buildAsyncActions("streams");
export const streamsReducer = buildAsyncReducer(streamsActions, initialState);


export const getStream = (streamsState, streamOrgKey, streamName) => {
    return streamsState.data.find((stream) => stream.organization === streamOrgKey && stream.shortname === streamName);
};
