import React from "react";
import PropTypes from "prop-types";
import { Menu } from '@mui/material';

const ITEM_HEIGHT = 32;
const SmartMenu = ({ open, onClose, children, anchorEl, horizontal, vertical = 'bottom', maxItems = 8, ...extraProps}) => {
    const transformVertical = vertical === 'bottom' ? 'top' : 'bottom';
    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: vertical || 'bottom',
                horizontal: horizontal || 'left',
            }}
            transformOrigin={{
                vertical: transformVertical,
                horizontal: horizontal || 'left',
            }}
            onClose={onClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * maxItems,
                }
            }}
            MenuListProps={{
                style: {
                    padding: 0,
                }
            }}
            color="inherit"
            {...extraProps}
        >
            {children}
        </Menu>
    );
};


SmartMenu.propTypes = {
    ...Menu.propTypes,
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    vertical: PropTypes.oneOf(['bottom', 'center', 'top']),
    maxItems: PropTypes.number,
};

export default SmartMenu;
