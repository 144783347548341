import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from '@mui/material';
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
    }
}));
const Footer = (props) => {
    const classes = useStyles();
    return (
        <Box component="footer" position={"static"} width={"100%"} bottom={0} id={"footer"} sx={{...classes.root, ...props.sx}} {...props}>
            {props.children}
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                &copy; {new Date().getFullYear()}. Health Catalyst. All rights reserved
            </Typography>
        </Box>
    );
};

Footer.propTypes = {
    ...Box.propTypes,
    children: PropTypes.any
};

export default Footer;
