import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import PropTypes from "prop-types";

export const DialogBox = ({queue, onHideDialog}) => {
    return (
        queue.map((dialog,index) => {
            const onClose = () => {
                onHideDialog && onHideDialog();
            };
            const actions = dialog.actions ? dialog.actions : [{title: "Close", isPrimary: true, autoFocus: true}];
            return (
                <Dialog
                    className={"alert-dialog-container"}
                    disableBackdropClick
                    disableEscapeKeyDown
                    key={index}
                    open={index === queue.length-1}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    {...(dialog.props || {})}
                >
                    {dialog.children ? (
                        <>
                            {dialog.children}
                        </>
                    ) : (
                        <>
                            <DialogTitle className="alert-dialog-title">
                                {dialog.title}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText component={"div"} className="alert-dialog-description">
                                    {dialog.content}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="alert-dialog-actions">
                                {actions.map((action, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => {onClose(); action.onClick && action.onClick();}}
                                        color={(action.isPrimary || actions.length === 1) ? "primary" : "default"}
                                        variant={(action.isPrimary || actions.length === 1) ? "contained" : "text"}
                                        autoFocus={!!action.autoFocus || actions.length === 1}
                                        disableElevation
                                        className={"alert-dialog-action-button"}
                                    >
                                        {action.title}
                                    </Button>
                                ))}
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            );
        })
    );
};


DialogBox.propTypes = {
    queue: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.any.isRequired,
        children: PropTypes.any,
        actions: PropTypes.arrayOf(PropTypes.shape({
            isPrimary: PropTypes.bool,
            onClick: PropTypes.func,
            autoFocus: PropTypes.bool,
            title: PropTypes.string,
        })),
        props: PropTypes.object,
    })).isRequired,
    onHideDialog: PropTypes.func.isRequired
};
export default DialogBox;