import React from "react";
import EnhancedTable from "../../common/EnhancedTable";
import {
    buildPropTypesFromObject,
    buildPropTypesWithDescriptor
} from "../../../lib/propTypeHelpers";
import { Box } from "@mui/material";


const descendingComparator = (a, b, orderBy) => {
    if(orderBy) {
        if (b[orderBy].value < a[orderBy].value) {
            return -1;
        }
        if (b[orderBy].value > a[orderBy].value) {
            return 1;
        }
    }
    return 0;
};

const getComparator = (
    order,
    orderBy
  ) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};


const AdvancedTable = (props) => {
    const descriptor = props.descriptor || {};
    const {data, onClick} = props;
    const cfg = defaultConfig(descriptor.config);
    let complexData = false;
    if(data.length && Array.isArray(data[0].value)) {
        complexData = true;
    }

    const columns = [];

    const cellRenderer = ({row, cell, x, y}) => {
        const data = row[cell.label] || {};
        return (
            <Box sx={{background: data.color, padding: "4px 16px", marginLeft:-2}}>
                {data.value}
            </Box>
        );
    };

    const tableData = (data || []).map((it, index) => {
        if(index === 0) {
            if(complexData) {
                columns.push(
                    {
                        id: " ",
                        label: " ",
                        props: {component: "th"},
                        isSortable: cfg.sortableColumnLabels.includes(" "),
                        disablePadding: true,
                        Content: cellRenderer
                    }
                );
                it.value.forEach(cell => {
                    if(cfg.columnLabels.length && !cfg.columnLabels.includes(cell.label)) {
                        return;
                    }
                    columns.push(
                        {
                            id: cell.label,
                            label: cell.label,
                            props: {},
                            isSortable: cfg.sortableColumnLabels.includes(cell.label),
                            disablePadding: true,
                            Content: cellRenderer
                        }
                    );
                });
            }
            else {
                columns.push(
                    {
                        id: "Label",
                        label: "Label",
                        props: {},
                        isSortable: true, 
                        disablePadding: true,
                        Content: cellRenderer
                    },
                    {
                        id: "Value",
                        label: "Value",
                        props: {},
                        isSortable: true, 
                        disablePadding: true,
                        Content: cellRenderer
                    }
                );
            }
        }

        const row = {};
        if(complexData) {
            row[" "] = {...it, value: it.label};
            it.value.forEach(v => { 
                row[v.label] = v;
            });
        }
        else {
            row.Label = {...it, value: it.label};
            row.Value = it;
        }
        return row;
    });
    // add sortable features
    const [order, setOrder] = React.useState(cfg.order);
    const [orderBy, setOrderBy] = React.useState(cfg.orderBy);
    const [selectedCell, setSelectedCell] = React.useState({x: undefined, y: undefined});

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        if(orderBy === property && order === "desc") {
            setOrder("");
            setOrderBy("");
        }
        else {
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        }
    };

    const handleCellClick = (event, row, cell, x, y) => {
        const value = {
            value: row[cell.id],
            x,
            y
        };
        if(selectedCell.x === x && selectedCell.y === y) {
            value.value = null;
            value.x = undefined;
            value.y = undefined;
        }
        setSelectedCell({x: value.x, y: value.y});
        onClick && onClick(event, value, descriptor);
    };

    return (
        <EnhancedTable 
            isLoading={props.isLoading}
            dense={cfg.dense}
            showHeader={cfg.headers}
            rows={stableSort(tableData, getComparator(order, orderBy))}
            columns={columns}
            dataTable={cfg.dataTable}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
            hover={cfg.hover} // row/cell
            selectedCell={selectedCell}
            selectedType={cfg.selectedType}
            height="auto"
            onCellClick={handleCellClick}
            emptyMessage={cfg.emptyMessage}
        />
    );
};

export const defaultConfig = (config = {}) => ({
    dataTable: true,
    headers: true,
    columnLabels: [],
    sortableColumnLabels: [],
    isLoading: false,
    dense: true,
    hover: false,
    page: 0,
    orderBy: "",
    order: "",
    selectedType: "row", // row, cell, undefined
    emptyMessage: "No items to show.",
    ...config
});

AdvancedTable.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject({
    ...defaultConfig(),
    formatValue: "",
}));

AdvancedTable.defaultProps = {
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            ...defaultConfig()
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus: undefined, // (event, value, descriptor) => {}
    onBlur: undefined, // (event, value, descriptor) => {}
    onClick: undefined, // (event, value, descriptor) => {}
    onKeyDown: undefined, // (event, value, descriptor) => {}
};

export default AdvancedTable;



