import React from "react";
import { Box } from "@mui/material";

const DescriptorErrorContent = ({ descriptor = {}, errorType = "shape" }) => {
  let errorMessage = `There was a problem with this descriptors shape "${descriptor.id}".`;
  if (errorType === "error") {
    errorMessage = `An error was thrown trying to render this descriptor "${descriptor.id}".`;
  }
  React.useEffect(() => {
    console.error(errorMessage, descriptor);
  }, [errorMessage, descriptor]);
  return (
    <Box p={1} border={"1px solid red"} bgcolor={"rgba(255,0,0,.1)"}>
      {errorMessage} Check the console for details.
      <br />
      <strong>ID:</strong> {descriptor.id} <strong>Label:</strong>{" "}
      {descriptor.label} <strong>Type:</strong>{" "}
      {!descriptor.type ? "undefined" : descriptor.type}
    </Box>
  );
};
export default DescriptorErrorContent;
