import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        "&.preBootstrap": {
            position: "fixed",
            top: "0px",
            left: "0px",
            height: "100%",
            width: "100%",
            backgroundColor: theme.palette.mode === "dark" ? "#2E3946": "#FFFFFF",
            zIndex: "999999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .triflame": {
                position: "absolute",
                height: "50px",
                width: "50px",
                top: "calc(50% - 25px)",
                left: "calc(50% - 25px)",
                "& .st0": {
                    fill: theme.palette.mode === "dark" ? "#ffffff" : "#a1a1a1"
                }
            },
            "& .logoContainer": {
                position: "absolute",
                top: "calc(50% - 100px)",
                left: "0",
                width: "100%",
                fontSize: 20,
                fontWeight: 500,
                letterSpacing: 1,
                color: theme.palette.mode === "dark" ? "#ffffff" : "#a1a1a1"
            },
            "& .logo": {
                width: "180px",
                height: "30px"
            },
            "& .shape": {
                fill: theme.palette.mode === "dark" ? "#ffffff" : "#a1a1a1"
            },
            "& .hcSplashSpinner": {
                width: "100px",
                height: "100px",
                transformOrigin: "center center",
                border: "3px solid rgba(0, 0, 0, 0.2)",
                borderRadius: "50%",
                borderTop: "3px solid " + (theme.palette.mode === "dark" ? "#ffffff" : "#a1a1a1"),
            },
            "& .center-children": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            "& .fade-spin": {
                animation: "spin 1.2s linear infinite, fade 1.5s",
                webkitAnimation: "spin 1.2s linear infinite, fade 1.5s"
            },
            "@keyframes spin": {
                "0%": {
                    transform: "rotate(0deg)",
                    webkitTransform: "rotate(0deg)"
                },
                "100%": {
                    transform: "rotate(360deg)",
                    webkitTransform: "rotate(360deg)"
                }
            },
            "& .fade": {
                animation: "fade 1.5s",
                webkitAnimation: "fade 1.5s"
            },
            "@keyframes fade": {
                "0%": {
                    opacity: "0"
                },
                "100%": {
                    opacity: "1"
                }
            },
        }
    }
}));

const LaunchLayout = ({ title }) => {
    const classes = useStyles();
    return (
        <Box className="preBootstrap center-children" sx={classes.root}>
            <Box className="logoContainer center-children fade">
                {title}
            </Box>
            <svg className="triflame fade" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 11.1 18.5" style={{ enableBackground: "new 0 0 11.1 18.5" }}>
                <path className="st0" d="M5.7,18.3c-0.6-0.3-1.1-0.7-1.5-1.2c-0.4-0.5-0.8-1.1-1-1.8c-0.2-0.7-0.4-1.4-0.4-2.2c0-4.6,3.8-7,3.4-11.6 c0-0.3,0-0.5-0.1-0.8C5.9,0.5,5.8,0.3,5.7,0.2c0.6,0.3,1.1,0.7,1.5,1.2c0.4,0.5,0.8,1.1,1,1.8c0.2,0.7,0.4,1.4,0.4,2.2 c0,3.9-3.6,6.8-3.6,11c0,0.4,0.1,0.8,0.2,1.1C5.3,17.8,5.4,18.1,5.7,18.3" />
                <path className="st0" d="M9.1,18.1c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.7-0.7-1.2c-0.2-0.4-0.2-0.9-0.2-1.5c0-3.1,2.8-4.8,2.4-8.7 c0.5,0.5,1,1.4,1.3,2C10.9,8.3,11,8.8,11,9.3c0,2.6-2.4,4.6-2.4,7.4c0,0.3,0,0.5,0.1,0.7C8.8,17.7,8.9,17.9,9.1,18.1" />
                <path className="st0" d="M2,12.9c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.7-0.7-1.2C0.2,10.4,0.1,10,0.1,9.4c0-3.1,2.8-4.8,2.4-8.7 c0.5,0.5,1,1.4,1.3,2C3.9,3.2,4,3.6,4,4.2c0,2.6-2.4,4.6-2.4,7.4c0,0.3,0,0.5,0.1,0.7C1.8,12.5,1.9,12.7,2,12.9" />
            </svg>
            <Box className="hcSplashSpinner fade-spin" />
        </Box>
    );
};

LaunchLayout.defaultProps = {
    title: "",
};

LaunchLayout.propTypes = {
    title: PropTypes.string,
};

export default LaunchLayout;