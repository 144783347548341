import PropTypes from "prop-types";

export const buildPropTypesWithDescriptor = (extendProps = {}, extendConfig = {}) => ({
    value: PropTypes.any,
    data: PropTypes.any,
    isLoading: PropTypes.bool,

    // descriptor
    descriptor: PropTypes.shape(
        buildDescriptorPropTypes(extendConfig)
    ),

    // callbacks
    onChange: PropTypes.func, // (event, value, descriptor) => {}
    onFocus: PropTypes.func, // (event, value, descriptor) => {}
    onBlur: PropTypes.func, // (event, value, descriptor) => {}
    onClick: PropTypes.func, // (event, value, descriptor) => {}
    onKeyDown: PropTypes.func, // (event, value, descriptor) => {}
    ...extendProps
});

export const buildDescriptorPropTypes = (extendConfig = {}) => ({
    // base descriptor
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string,
    dataKey: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    config: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            count: PropTypes.string,
        })),
        ...extendConfig
    }),
});

// this is somewhat of a hack to build PropTypes from our standard defaultConfig function.
export const buildPropTypesFromObject = (obj = {}, requireAll = false) => {
    const pt = {};
    Object.keys(obj).forEach((key) => {
        const v = obj[key];
        pt[key] = PropTypes.any; // default type
        if(v === null) {
            /* null value could be anything fall through to see if it is required */
        }
        else if(Array.isArray(v)) {
            pt[key] = PropTypes.array;
        }
        else {
            switch (typeof v) {
                case "object":
                    pt[key] = PropTypes.shape(
                        buildPropTypesFromObject(v) // recursive
                    );
                    break;
                case "number":
                    pt[key] = PropTypes.number;
                    break;
                case "boolean":
                    pt[key] = PropTypes.bool;
                    break;
                case "function":
                    pt[key] = PropTypes.func;
                    break;
                default:
            }
        }
        if(requireAll) {
            pt[key] = pt[key].required;
        }
    });
    return pt;
};