import {
    buildAsyncActions,
    buildAsyncReducer,
    asyncInitialState
} from "./utils/asyncReducer";
import appPermissions from "../lib/appPermissions";

const initialState = {
    ...asyncInitialState,
    data: {
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        organizations: []
    },
    isLoading: true, // start off loading.
    status: "LOADING"
};

export const userActions = buildAsyncActions("user");
export const userReducer = buildAsyncReducer(userActions, initialState);

// accessors/selectors
export const getOrganizations = (userState) => {
    return userState.data.organizations;
};

export const getOrganization = (userState, orgKey) => {
    return userState.data.organizations.find((org) => org.key === orgKey);
};

export const getOrganizationName = (userState, orgKey) => {
    return (getOrganization(userState, orgKey) || {name: "Unknown Organization"}).name;
};

export const getImplementation = (userState, orgKey, impKey) => {
    const org = getOrganization(userState, orgKey);
    if(org) {
        return org.implementations.find((imp) => imp.key === impKey);
    }
    return false;
};

export const canRead = (userState, orgKey, impKey) => {
    const impl = getImplementation(userState, orgKey, impKey);
    if (impl) {
        return appPermissions.hasAllGrants(impl.igrants, appPermissions.perms.READ);
    }
    return false;
};


export const canWrite = (userState, orgKey, impKey) => {
    const impl = getImplementation(userState, orgKey, impKey);
    if (impl) {
        return appPermissions.hasAllGrants(impl.igrants, appPermissions.perms.WRITE);
    }
    return false;
};


export const canSystemNotification = (userState, orgKey, impKey) => {
    const impl = getImplementation(userState, orgKey, impKey);
    if (impl) {
        return appPermissions.hasAllGrants(impl.igrants, appPermissions.perms.SYSTEM_NOTIFICATION);
    }
    return false;
};
