import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        height: "100%",
        minHeight: 250,
        padding: 1,
        paddingTop: 2,
        paddingBottom: 2,
        overflow: "hidden",
        position: "relative",
    },
    stacked: {
        "& > div.hidden": {
            zIndex: 0,
            width: "100%",
            display: "none"
        },
        "& > div.active": {
            position: "absolute",
            width: "100%"
        }
    }
}));

const PaneLayout = ({sx = {}, stackWidth = 0, ...extraProps}) => {
    const [stacked, setStacked] = React.useState(false);
    const classes = useStyles();
    const containerRef = React.useRef();
    const checkWidth = () => {
        if(!stackWidth || !containerRef.current || isNaN(stackWidth)) {
            return;
        }
        // check container width and see if its smaller then stackWidth
        // if its less then stackWidth apply class to display none on everything except the last opened item.
        if(containerRef.current.clientWidth <= stackWidth && !stacked) {
            setStacked(true);
        }
        else if(containerRef.current.clientWidth > stackWidth &&stacked)  {
            setStacked(false);
        }
    };

    React.useEffect(() => {
        const container = containerRef.current;
        const resizeObserver = new ResizeObserver(
            () => {
                checkWidth();
            }
        );
        // add resize observer
        resizeObserver.observe(container);
        return () => {
            // remove resize observer
            resizeObserver.unobserve(container);
        };
    });

    return (
        <Box ref={containerRef} sx={{...classes.root, ...(stacked ? classes.stacked : {}), ...sx}} {...extraProps}  />
    );
};


PaneLayout.defaultProps = {
    stackWidth: 0
};

PaneLayout.propTypes = {
    ...Box.propTypes,
    stackWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default PaneLayout;
