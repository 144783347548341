import React from "react";
import PropTypes from "prop-types";
import storage from "../../lib/storage";
import Joyride, { STATUS } from "react-joyride";
import { Typography, Box, Paper, Button, Grid, useTheme, lighten } from "@mui/material";

const Tooltip = (props) => {
  const {
    continuous,
    index,
    size,
    step,
    backProps,
    closeProps,
    skipProps,
    primaryProps,
    tooltipProps,
  } = props;
  return (
    <Box {...tooltipProps} zIndex={1000} minWidth={300} maxWidth={400} sx={{
      "& .__floater__arrow": {
        border: "1px solid red"
      }
    }}>
      <Paper>
        <Box p={2}>
          {step.title && <Typography variant="h6" component={"strong"} mb={.25}>{step.title}</Typography>}
          <Box>
            {size > 1 && <Typography variant="caption">Step: {index+1} of {size}</Typography>}
            <Typography variant="body1">{step.content}</Typography>
          </Box>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={4}>
                {size > 1 && <Button variant="contained" size="small" color="secondary" {...skipProps}>
                  End Tour
                </Button>}{" "}
              </Grid>
              <Grid item xs={8}>
                <Box textAlign={"right"}>
                  {index > 0 && (
                    <Button variant="contained" size="small" color="secondary" {...backProps}>
                      Back
                    </Button>
                  )}{" "}
                  {continuous && (
                    <Button variant="contained" size="small" color="primary" {...primaryProps}>
                      {index+1 === size ? "Close" : "Next"}
                    </Button>
                  )}{" "}
                  {!continuous && (
                    <Button variant="contained" size="small" color="primary" {...closeProps}>
                      Close
                    </Button>
                  )}{" "}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const getInitialState = (options = {}) => ({
  run: false,
});

export const OnboardingContext = React.createContext({});

const OnboardingProvider = ({ children, appKey = "armus.dashboard", ...props }) => {
  const theme = useTheme();
  const storageKey = appKey + ".onboarding";
  if (!storage.hasKey(storageKey)) {
    // make sure the local storage bucket exists
    storage.save(storageKey, {});
  }

  const [__state, __setState] = React.useState(getInitialState());
  const __setStateRef = React.useRef(__setState);
  const __stepsRef = React.useRef([]);

  const addSteps = React.useCallback((key, steps, canStart) => {
    const queue = __stepsRef.current;
    const found = queue.find((it) => it.key === key);
    steps = steps.map((it) => ({
      // disable the beacon by default
      ...it,
      disableBeacon: it.disableBeacon !== undefined ? it.disableBeacon : true,
    }));
    if (!found) {
      queue.push({
        key,
        steps,
        canStart,
      });
    } else {
      found.steps = steps;
      found.canStart = canStart;
    }
  }, []);

  const removeSteps = React.useCallback((key) => {
    const queue = __stepsRef.current;
    const foundIndex = queue.findIndex((it) => it.key === key);
    if (foundIndex !== -1) {
      __stepsRef.current.splice(foundIndex, 1);
    }
  }, []);

  const onStart = React.useCallback(
    (forceStart = false) => {
      if (forceStart) {
        // clear the onboarding local storage before starting
        storage.save(storageKey, {});
      }
      __setStateRef.current({
        run: true,
      });
    },
    [storageKey]
  );

  const ctx = {
    ...__state,
    hasSteps: __stepsRef.current.length > 0,
    addSteps,
    removeSteps,
    onStart,
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      // all done
      const finishedKeys = __stepsRef.current
        .filter((it) => it.canStart === true)
        .map((it) => it.key);
      const completedOnboardKeys = storage.load(storageKey);
      finishedKeys.forEach((key) => {
        completedOnboardKeys[key] = true;
      });
      storage.save(storageKey, completedOnboardKeys);
      __setStateRef.current({ run: false });
    }
  };

  // setup the default joyride options
  const options = {
    run: false,
    continuous: true,
    debug: false,
    // beaconComponent: null,
    tooltipComponent: Tooltip,
    // locale: { back: 'Back', close: 'Close', last: 'Last', next: 'Next', open: 'Open the dialog', skip: 'Skip' }
    disableCloseOnEsc: true,
    disableOverlay: false,
    disableOverlayClose: true,
    disableScrolling: true,
    disableScrollParentFix: false,
    scrollToFirstStep: true,
    // scrollDuration: 300,
    scrollOffset: 200,
    hideBackButton: false,
    hideCloseButton: false,
    showProgress: true,
    showSkipButton: true,
    spotlightClicks: false,
    spotlightPadding: 16,
    // stepIndex: 0, // controlled vs uncontrolled
    // styles: {},
    // nonce: "",
    callback: handleJoyrideCallback,
    options: {
      zIndex: 10000,
    },
    styles: {
      options: {
        arrowColor: lighten(theme?.palette?.background?.paper, .07) || "#fff",
      }
    },
    ...props,
  };

  // only show steps that can start and have not already been seen
  const completedOnboardKeys = storage.load(storageKey) || [];
  let steps = [];
  __stepsRef.current
    .filter(
      (it) => completedOnboardKeys[it.key] !== true && it.canStart === true
    )
    .forEach((it) => (steps = steps.concat(it.steps)));

  return (
    <OnboardingContext.Provider value={ctx}>
      <Joyride {...options} steps={steps} run={ctx.run} />
      {children}
    </OnboardingContext.Provider>
  );
};

OnboardingProvider.propTypes = {
  //...Joyride.propTypes,
  appKey: PropTypes.string.isRequired,
};
// delete OnboardingProvider.propTypes.run;
// delete OnboardingProvider.propTypes.steps;

export default OnboardingProvider;

export const useOnboarding = (key, steps, canStart = true) => {
  const { run, onStart, removeSteps, addSteps, hasSteps } =
    React.useContext(OnboardingContext);

  React.useEffect(() => {
    // mount, update
    if (key !== undefined) {
      addSteps(key, steps, canStart);
    }
    return () => {
      // unmount
      if (key !== undefined) {
        removeSteps(key);
      }
    };
  }, [addSteps, key, steps, removeSteps, canStart]);

  React.useEffect(() => {
    if (key !== undefined && canStart) {
      // auto start
      onStart();
    }
  }, [onStart, key, canStart]);

  return {
    run,
    hasSteps,
    onStart,
    addSteps,
    removeSteps,
  };
};
