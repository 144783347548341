import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";

/* Automatically switches between react router NavLink or Link based on the
   value of to or href prop. (NavLink does not produce a full page load)
*/
const forwardRefNavLink = React.forwardRef(
        (props, ref) =>
            <NavLink ref={ref} {...props}  />
    );

const SmartLink = React.forwardRef((props, ref) => {
    const { to, href } = props;
    if( href || (to && to.indexOf("http") !== -1)) {
        // ... this check could probably be "smarter"
        // default link (not react router)
        return (
            <Link {...{...props, href: href || to}} ref={ref} />
        );
    }

    if(!href && !to) {
        return (
            <Link {...props} ref={ref} />
        );
    }

    // react router link
    return (
        <Link {...props} ref={ref} component={forwardRefNavLink} />
    );
});


SmartLink.propTypes = {
    ...Link.propTypes,
    ...NavLink.propTypes,
    to: PropTypes.any,
};

export default SmartLink;
