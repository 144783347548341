import React from 'react';
import { Box, CircularProgress} from '@mui/material';
import makeSxStyles from '../../../lib/makeSxStyles';

const useStyles = makeSxStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
    },
    label: {
        textAlign: "center",
        marginTop: theme.spacing(.5),
    },
    valueContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 75,
    },
    progress: {
        position: "absolute",
        transform: "rotate(180)"
    },
    progressOutline: {
        position: "absolute",
        color: theme.palette.divider,
    },
    value: {
        position: "absolute",
        fontSize: 20,
        textAlign: "center"
    }
}));

const BoxGauge = ({
    color,
    formattedValue,
    value,
    label,
    min = 0,
    max = 1,
    onClick,
}) => {
    const classes = useStyles();
    const percent = (value - min)/(max - min) * 100;
    return (
        <Box sx={classes.root} onClick={(e) => onClick(e, {value, label, color})}>
            <Box sx={classes.valueContainer}>
                <CircularProgress
                    sx={classes.progressOutline}
                    variant="static"
                    value={100}
                    size={75}
                />
                <CircularProgress
                    sx={classes.progress}
                    variant="static"
                    value={percent}
                    size={75}
                    style={{color: color,}}
                />
                <Box
                    sx={classes.value}
                    style={{color: color}}
                >
                    {formattedValue}
                </Box>
            </Box>
            <Box sx={classes.label}>{label}</Box>
        </Box>
    );
};

export default BoxGauge;