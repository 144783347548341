import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Main, Paper, SmartLink, makeSxStyles } from "@armus/armus-dashboard";
import LoadingWrapper from "../components/LoadingWrapper";
import api from "../../lib/api";
import {connectAppStore} from "../../data";
import {
    getOrganization,
    canRead,
    getOrganizationName
} from "../../data/UserStore";
import {streamsBreadcrumb} from "./Streams";
import {streamBreadcrumb, streamsFilesBreadcrumb} from "./Stream";
import Chip from "../components/Chip";
import moment from "moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ErrorPage from "../ErrorPage";
import CustomerReport from "./components/CustomerReport";
import { Box } from "@mui/material";
import FileIcon from "@mui/icons-material/Description";

export const streamsFileDetailsBreadcrumb = (organization = {}, streamOrgKey, streamName, fileDetails) => ({
    label: fileDetails.filename || "",
    url: `/organization/${organization.key}/streams/${streamOrgKey || organization.key}/${streamName}/${fileDetails.id}`
});

const useStyles = makeSxStyles((theme) => ({
    chip: {
        marginTop: 1.5,
        float: "right"
    },
    title: {
        padding: 2,
        paddingBottom: 1,
        paddingTop: 1,
        borderBottom: "2px solid rgba(0,0,0,.15)"
    },
    header: {
        position: "relative",
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        background: theme.palette.mode === 'light' ? "#f5f5f5" : "#424242",
        borderBottom: "1px solid rgba(0,0,0,.15)"
    },
    tableHead: {
        marginLeft: 0,
        "& th": {
            paddingLeft: 2
        }
    },
    headerCell: {
        fontWeight: "bold",
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800]
    },
    tableBodyContainer: {
        marginLeft: 0,
        maxHeight: 296,
        overflowY: "auto",
        "& th": {
            paddingLeft: 2
        }
    },
    reportContainer: {
        border: "1px solid #ddd",
        width: "100%",
        borderRight: "none",
        borderLeft: "none",
        height: 296
    }
}));

const StreamFile = (props) => {
    const {userState, streamFileDetails, streamFileDetailsStatus, organization, stream, getOrgName} = props;
    const classes = useStyles();
    const params = props.match.params || {};

    useEffect(() => { // didMount
        api.loadStreamFileDetails(params.orgKey, params.streamOrgKey, params.streamName, params.streamFileId);
    }, [params.orgKey, params.streamOrgKey, params.streamName, params.streamFileId]);

    if(!organization) {
        return (
            <ErrorPage statusCode={404} />
        );
    }

    return (
        <Main
            breadcrumbs={[
                streamsBreadcrumb(organization),
                streamBreadcrumb(organization, params.streamOrgKey, params.streamName),
                streamsFilesBreadcrumb(organization, params.streamOrgKey, params.streamName),
                streamsFileDetailsBreadcrumb(organization, params.streamOrgKey, params.streamName, streamFileDetails)
            ]}
            fullWidth={true}
        >
            <Box sx={{padding: 4}}>
                <Paper>
                    <LoadingWrapper status={streamFileDetailsStatus} >
                        <Typography sx={classes.title} component="h1" variant={"h4"}>
                            <FileIcon />{streamFileDetails.filename}
                            {<Chip status={streamFileDetails.lastState} sx={classes.chip} size="small" />}
                        </Typography>
                        <Box sx={classes.header}>
                            <Typography variant="h4" gutterBottom>
                                File Details
                            </Typography>
                            <Typography color="text.secondary" variant="subtitle1">
                                Historical file process statuses, customer report and file details.
                            </Typography>
                        </Box>
                        <Box sx={{padding: 2}}>
                            <strong>Organization:</strong> {getOrgName(params.streamOrgKey)}<br/>
                            <strong>Stream:</strong> {stream.shortname}<br/>
                            <strong>File Name:</strong> {streamFileDetails.filename}<br/>
                            <strong>Last Updated:</strong> {moment(streamFileDetails.lastUpdated).format("MM/DD/YYYY HH:mm:ss")}<br/>
                            <strong>SHA1:</strong> {streamFileDetails.sha1} <br/>
                            { streamFileDetails.reprocessedAs && (
                                <React.Fragment>
                                    <strong>Reprocessed Status:</strong> <SmartLink to={`/organization/${organization.key}/streams/${params.streamOrgKey}/${params.streamName}/${streamFileDetails.reprocessedAs.id}`}>{<Chip status={streamFileDetails.reprocessedAs.lastState} size="small"/>} {moment(streamFileDetails.reprocessedAs.lastUpdated).format("MM/DD/YYYY HH:mm:ss")}</SmartLink> <br />
                                </React.Fragment>
                            )}
                            <strong>File Source: </strong>
                            { streamFileDetails.ancestor ? (
                                <React.Fragment>
                                    <SmartLink to={`/organization/${organization.key}/streams/${params.streamOrgKey}/${params.streamName}/${streamFileDetails.ancestor.id}`}>Previous Import {moment(streamFileDetails.ancestor.lastUpdated).format("MM/DD/YYYY HH:mm:ss")}</SmartLink>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    Submitted File
                                </React.Fragment>
                            )}
                        </Box>
                        <Table sx={classes.tableHead} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" sx={classes.headerCell} width={"20%"} padding={"none"}>File History</TableCell>
                                    <TableCell component="th" sx={classes.headerCell} align="left">Transitioned To</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Box sx={classes.tableBodyContainer}>
                            <Table size="small">
                                <TableBody>
                                    {streamFileDetails.transitions.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row" width={"20%"} padding={"none"}>
                                                {moment(row.occurredAt).format("MM/DD/YYYY HH:mm:ss")}
                                            </TableCell>
                                            <TableCell align="left">{<Chip status={row.toState} size="small"/>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box sx={{padding: 2}}>
                            {
                                canRead(userState, params.orgKey, params.implKey) && (
                                    <React.Fragment>
                                        <strong>Customer Report:</strong> {streamFileDetails.hasCustomerReport ? "YES" : "NO"} <br/>
                                        {streamFileDetails.hasCustomerReport && <CustomerReport {...params}/>}
                                    </React.Fragment>
                                )
                            }
                        </Box>
                    </LoadingWrapper>
                </Paper>
            </Box>
        </Main>
    );
};

export default withRouter(connectAppStore(([state, dispatch], props) => {
    const params = props.match.params || {};
    return {
        userState: state.user,
        stream: state.stream.data || {},
        streamFileDetailsStatus: state.streamFileDetails.status,
        streamFileDetails: state.streamFileDetails.data || {},
        organization: getOrganization(state.user, params.orgKey),
        getOrgName: (orgKey) => getOrganizationName(state.user, orgKey)
    };
})(StreamFile));
