const initialState = {
    isLoading: 0 // count in-progress async activities.
};

export const appActions = {
};

export const appReducer = (state = initialState, action = {}) => {
    if(action.type.indexOf("LOADING") !== -1) {
        return {
            ...state,
            isLoading: state.isLoading+1
        };
    }
    if((action.type.indexOf("LOADED") !== -1 || action.type.indexOf("ERROR")  !== -1) && state.isLoading > 0) {
        return {
            ...state,
            isLoading: state.isLoading-1
        };
    }
    return state;
};
