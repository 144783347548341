import React from "react";
let pingIntervalStarted = null;
let lastActivityTime = new Date().getTime();

const defaultConfig = {
    autoLogout: true,
    keepAlive: true, // will invoke the pingCallback() every interval.
    timeout: 45 * 60 * 1000, // the amount of time no activity is detected before calling logoutCallback()
    interval: 5 * 60 * 1000, // how often the client will check the usersActivity.
};

export const checkActivity = (pingCallback, logoutCallback, config) => {
    config = {...defaultConfig, ...config};
    // keep the session alive as long as the user is active.
    const now = new Date().getTime();
    const diff = now - lastActivityTime;
    if(config.autoLogout && diff > config.timeout) {
        logoutCallback();
    }
    else if(config.keepAlive) {
        // keep the user session active.
        pingCallback();
    }
};

export const setLastActivityTime = (t) => {
    lastActivityTime = t;
};

const updateUserActivity = () => {
    setLastActivityTime(new Date().getTime());
};

const activityMonitor = (pingCallback, logoutCallback, config) => {
    if(!config.autoLogout && !config.keepAlive) {
        // config states
        return;
    }
    if(pingIntervalStarted) {
        // only need to setup once.
        // the following events will refresh the users last active time
        document.addEventListener("click", updateUserActivity);
        document.addEventListener("keydown", updateUserActivity);
        document.addEventListener("mousemove", updateUserActivity);
        clearInterval(pingIntervalStarted);
    }

    // update the interval
    pingIntervalStarted = setInterval(() => {
        checkActivity(pingCallback, logoutCallback, config);
    }, config.interval );
    return pingIntervalStarted;
};

export const useActivityMonitor = (pingCallback, logoutCallback, config) => {
    const pingCallbackRef = React.useRef();
    const logoutCallbackRef = React.useRef();
    pingCallbackRef.current = pingCallback;
    logoutCallbackRef.current = logoutCallback;
    activityMonitor(() => pingCallbackRef.current(), () => logoutCallbackRef.current(), config);
};


export default activityMonitor;