import React from "react";
import Paper from "./Paper";
import SmartLink from "./SmartLink";
import { Typography, Breadcrumbs, Icon, Box } from '@mui/material';
import PropTypes from "prop-types";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles(theme => ({
    ...theme.spreadIt,
    root: {
        background: theme.palette.mode === "dark" ? "" : "#f0f3f6",
        "& .MuiOutlinedInput-root": {
            backgroundColor: theme.palette.background.paper
        }
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    subtitle: {
        marginBottom: 25
    },
    breadcrumbs: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: "rgba(127,127,127, .1)",
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.25),
        width: 20,
        height: 20,
    },
    fullWidth: {
        width: "100%"
    },
    fullHeight: {
        height: "100%",
        position: "relative"
    }
}));
const Main = ({fullWidth, fullHeight, children, breadcrumbs, className = "", sx = {}}) => {
    const classes = useStyles();
    const [height, setHeight] = React.useState(0);
    if(breadcrumbs && breadcrumbs.length) {
        document.title = breadcrumbs[breadcrumbs.length-1].label;
    }

    const calculateHeight = () => {
        const elemBred = document.getElementById("breadcrumbs");
        const elemHeader = document.getElementById("header");
        const elemFooter = document.getElementById("footer");
        const height = window?.innerHeight - (elemBred?.clientHeight || 0) - (elemHeader?.clientHeight || 0) - (elemFooter?.clientHeight || 0);
        return height;
    };

    React.useEffect(() => {
        const watchResize = () => setHeight(calculateHeight());
        window.addEventListener("resize", watchResize);
        return () => window.removeEventListener("resize", watchResize);
    }, []);

    React.useEffect(() => {
        if(fullHeight) {
            setHeight(calculateHeight());
        }
    }, [fullHeight, setHeight]);


    return (
        <Box className={className} sx={{...classes.root, ...sx}}>
            {breadcrumbs && <Breadcrumbs id={"breadcrumbs"} arial-label="Breadcrumb" sx={classes.breadcrumbs}>
                {breadcrumbs.map((crumb, index) => {
                    if(index === breadcrumbs.length-1) {
                        return (
                            <Typography sx={classes.link} key={index}>
                                {index === 0 && <Icon sx={classes.icon}>home_icon</Icon>} {crumb.label}
                            </Typography>
                        );
                    }
                    return (
                        <SmartLink sx={classes.link} key={index} color="inherit" to={crumb.url}>
                            {index === 0 && <Icon sx={classes.icon}>home_icon</Icon>} {crumb.label}
                        </SmartLink>
                    );
                })}
            </Breadcrumbs>}

            {(fullWidth || fullHeight) ? (
                <Box
                    id={"layout"}
                    className={{...(fullWidth ? classes.fullWidth : {}),  ...(fullHeight ? classes.fullHeight : {})}}
                    style={{height: fullHeight ? height || "" : ""}}
                >
                    {children}
                </Box>
            ) : (
                <Box
                    id={"layout"}
                    sx={classes.layout}
                >
                    <Paper sx={classes.paper} m={2}>
                        {children}
                    </Paper>
                </Box>
            )}
        </Box>
    );
};

Main.propTypes = {
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    })),
};

export default Main;
