import React from "react";
import { Box, Paper as MUIPaper } from "@mui/material";

const Paper = ({className, component, ...extraProps}) => {
    const Component = component ? component : MUIPaper;
    return (
        <Box
            component={Component}
            {...extraProps}
        />
    );
};

Paper.propTypes = {
    ...Box.propTypes,
    ...MUIPaper.propTypes,
};

export default Paper;
