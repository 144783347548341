const packageJson = require('../package.json');
// these config defaults will be overloaded by "window.AppConfig" which gets
// set by config "public/config.js" included in the index.html file.

const defaultConfig = {
    title: "ARMUS Streams Manager",
    endpoints: {
        apiRoot: "/api",
        login: "/api",
        logout: "/api/logout",
        mock: "",
        support: "https://armussupport.zendesk.com"
    },
    analytics: {},
    cache: {
        useCache: true
    },
    session: {
        timeout: 45 * 60 * 1000,
        interval: 5 * 60 * 1000,
        keepAlive: true,
        autoLogout: true,
        CSRF_COOKIE_KEY: "XSRF-TOKEN",
        CSRF_POST_KEY: "_csrf"
    },
    version: packageJson.version,
    ui: {
        // logoUrl - To use for the co-brand image in the top right of the application navbar.
        // Can be one of the following:
        //   - "" empty string for default value.
        //   - HTTPS Fully Qualified URL from a CDN
        //   - Relative url from within the project "/images/armus-logo.png"
        //   - You can also use a base64 data image/png;nase64,<image data>...
        //     "data:image/png;base64,<image data...>"
        logoUrl: ""
    },
    mocks: {}
};

const config = {...defaultConfig, ...window.AppConfig};

if(process.env.NODE_ENV === "development") {
    config.session.keepAlive = false;
    config.session.autoLogout = false;
    config.cache.useCache = true;

    config.endpoints.mock = "/static/mocks";
    config.mocks.useMocks = true;
    config.mocks.showMockMenu = true;
    config.mocks.loadingDelay = 3000; // between 0 - 1000 ms

    // For local server-less development
    // initialize with a mockset selected.
    // or use ?mockset=trekkie
    config.mocks.useMocksetKey = "trekkie";
}

export default config;
