import React from "react";
import {buildPropTypesFromObject, buildPropTypesWithDescriptor} from "../../lib/propTypeHelpers";
import {
    RadioGroup,
    Radio as BaseRadio,
    FormControlLabel,
} from "@mui/material";
import Outline from "./components/Outline";
import Spinner from "../common/Spinner";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        minHeight: 37.13,
        marginTop: 1,
        marginBottom: .5,
        "& .MuiRadio-root .hc-radio-overlay": {
            minWidth: 18,
            width: 18,
            height: 18,
            "&:after": {
                top: 3,
                left: 3,
            }
        },
        "& .MuiInputBase-root": {
            padding: "8.065px 14px",
        },
        "& .MuiRadio-root": {
            padding: "0px 9px 0px 9px",
        },
        "& .MuiFormControlLabel-root": {
            "& > .MuiFormControlLabel-label": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }
        }
    }
}));

const Radio = ({isLoading, descriptor, data = [], value, disabled, options, onClick, onChange, onBlur, onFocus, onKeyDown, inputProps, required, ...extraProps}) => {
    const [focused, setFocused] = React.useState(false);
    const classes = useStyles();
    const cfg = defaultConfig(descriptor.config);
    let mergedOptions = [];
    if(data || options) {
        mergedOptions = [...(data || options || [])];
        cfg.options.forEach(a => {
            if (data.findIndex(b => a.label === b.label) !== -1) return;
            mergedOptions.push(a);
        });
    }

    required = descriptor.required === true ? true : required;
    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        mergedOptions = [];
        disabled = true;
    }

    value = value === null || value === undefined ? false : value;

    const handleOnChange = (e, v) => {
        onChange && onChange(e, v, descriptor);
    };

    return (
        <Outline
            sx={classes.root}
            focused={focused}
            label={(descriptor.label)}
            margin={cfg.dense ? "dense" : undefined}
            title={descriptor.description}
            fullWidth={true}
            required={required}
            disabled={disabled}
            {...extraProps}
        >
            {isLoading ? <Spinner size={18} overlay={true} /> :null}
            <RadioGroup
                row={true}
                value={value}
                onClick={(e) => onClick && onClick(e, value, descriptor)}
                onChange={(e, v) => handleOnChange(e, v)}
                onBlur={(e) => {
                    setFocused(false);
                    onBlur && onBlur(e, value, descriptor);
                }}
                onFocus={(e) => {
                    setFocused(true);
                    onFocus && onFocus(e, value, descriptor);
                }}
                onKeyDown={(e) => onKeyDown && onKeyDown(e, value, descriptor)}
                {...inputProps}
            >
                {mergedOptions.map((it) =>
                    <FormControlLabel
                        key={descriptor.id + it.label}
                        style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"}}
                        control={<BaseRadio color="primary" />}
                        disabled={disabled || it.disabled}
                        id={it.id || descriptor.id}
                        name={it.id || descriptor.id}

                        value={it.value + ""}
                        label={it.label}
                        title={it.label}
                    />
                )}
            </RadioGroup>
        </Outline>
    );
};

Radio.defaultProps = {
    value: null,
    data: [],
    options: [],
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            options: [],
            dense: true,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Radio.defaultProps.descriptor.config,
    ...config
});

Radio.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));

export default Radio;