import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, useHistory, matchPath } from "react-router-dom";
import {connectAppStore} from "./data";
import ErrorPage from "./views/ErrorPage";
import Streams from "./views/streams/Streams";
import Stream from "./views/streams/Stream";
import StreamFileDetails from "./views/streams/StreamFileDetails";
import api from "./lib/api";
import MenuIcon from "@mui/icons-material/Menu";
import LightIcon from "@mui/icons-material/LightMode";
import DarkIcon from "@mui/icons-material/DarkMode";
import AboutIcon from "@mui/icons-material/QuestionMark";
import SupportIcon from "@mui/icons-material/Support";
import LogoutIcon from "@mui/icons-material/Logout";
import Dialog from "./views/components/Dialog";
import { Header, Footer, withTheme, useDialogs, useActivityMonitor, formatMessage, AboutLayout } from "@armus/armus-dashboard";
import config from "../config";
import Notifications from "./views/components/Notifications";
import {getOrganization} from "./data/UserStore";
import ArmusLogo from "./views/components/armus-logo.png";

const buildLoadedNavUI =(userState, orgKey, leftNavLinks, rightNavLinks, userLinks) => {
    // successfully logged in
    if(userLinks.length) {
        // append the users other application links
        rightNavLinks.push({
            icon: <MenuIcon />,
            items: userLinks
        });
    }

    let organizationLabel = "Organizations";
    const defaultOrgKey = userState.data.organizations && userState.data.organizations.length ? userState.data.organizations[0].key : "";
    if(!orgKey) {
        orgKey = defaultOrgKey;
    }

    const org = getOrganization(userState, orgKey);
    if(org) {
        organizationLabel = org.name || org.key;
    }
    const orgLinks = {
        label: organizationLabel,
        url: "/organization",
        items: userState.data.organizations ? userState.data.organizations.map((org) => ({
            label: org.name || org.key,
            url: `/organization/${org.key}/streams`
        })) : []
    };
    // sort organizations list
    orgLinks.items.sort((a, b) =>
        a.label.toString().localeCompare(b.label.toString(), navigator.languages[0] || navigator.language, {
            numeric: true,
            ignorePunctuation: true
        })
    );

    if(orgLinks && orgLinks.items.length) {
        // prepend the users available stream links
        leftNavLinks.push(orgLinks);
    }

    return (
        <Switch>
            {/* Streams Routes */}
            <Route path="/organization/:orgKey/:implKey/:streamOrgKey/:streamName/file/:streamFileId" component={StreamFileDetails} />
            <Route path="/organization/:orgKey/:implKey/:streamOrgKey/:streamName" component={Stream} />
            <Route path="/organization/:orgKey/:implKey" component={Streams} />

            {/* Root redirect to streams */}
            {defaultOrgKey && <Redirect exact from="/" to={`/organization/${defaultOrgKey}/streams`} component={Streams} />}

            {/* Error Page */}
            <Route path="/*" component={ErrorPage} />
        </Switch>
    );
};

const Router = (props) => {
    const {
        userState,
        themes,
        setTheme,
        theme
    } = props;

    useActivityMonitor(
        () => { // ping check that the user is still active
            api.pingUserData();
        },
        () => { // user is not active log them out
            api.logoutUser();
        },
        config.session
    );

    const {onShow, onHide} = useDialogs();
    const history = useHistory();
    const {params} = matchPath(history.location.pathname, {path: "/organization/:orgKey"}) || {params: {}};

    const userStatus = userState.status;
    const userName = userState.data.firstname || userState.data.username || null;
    const userLinks = userState.data.navTree ? (userState.data.navTree.items || []) : [];

    useEffect(() => { // didMount
        api.loadUserData();
    }, []);

    const leftNavLinks = [];
    const rightNavLinks = [];

    if(process.env.NODE_ENV === "development") {
        if (config.mocks.showMockMenu) {
            // add development links
            const links = require("./lib/mocks").default.buildMockSetNav();
            rightNavLinks.push({
                key: "mocks",
                label: "MOCKS",
                items: links
            });
        }
    }

    const profileNavLinks = [
        {
            label: formatMessage({id: "about", defaultMessage: "About"}),
            icon: <AboutIcon />,
            onClick: () => {
                let mockMessage = "";
                if(process.env.NODE_ENV === "development") {
                    const mockSet = require("./lib/mocks").default.getMockSet();
                    mockMessage = <React.Fragment><strong>Mock Set:</strong> {mockSet.label}</React.Fragment>;
                }
                onShow({
                    title: `${formatMessage({
                      id: "about",
                      defaultMessage: "About"
                    })} ${formatMessage({
                      id: "title",
                      defaultMessage: "ARMUS ADAM"
                    })}`,
                    children: (
                      <AboutLayout
                        title={process.env.REACT_APP_NAME}
                        version={process.env.REACT_APP_VERSION}
                        onClose={onHide}
                      >
                        <code>
                          {mockMessage}
                        </code>
                      </AboutLayout>
                    )
                });
            }
        },
        null,
        ...themes.map((theme, themeIndex) => ({
            label: theme.name + " Theme",
            icon: [<LightIcon />, <DarkIcon />][themeIndex],
            onClick: () => {
                setTheme(themeIndex);
            }
        })),
        null,
        {
            label: "ARMUS Support",
            icon: <SupportIcon />,
            url: config.endpoints.support
        },
        null, // divider
        {
            label: "Logout",
            icon: <LogoutIcon />,
            onClick: api.logoutUser
        }
    ];

    let routes = null;

    switch(userStatus) {
        case "LOADED":
            routes = buildLoadedNavUI(userState, params.orgKey, leftNavLinks, rightNavLinks, userLinks);
            break;
        case "ERROR":
        case "LOADING":
        default:
            return null;
    }

    return (
        <React.Fragment>
            <Header
                // isLoading={!!app.isLoading}
                leftNav={leftNavLinks}
                rightNav={rightNavLinks}
                profileNav={profileNavLinks}
                userName={userName}
                logoUrl={<>Stream Manager</>}
                cobrandUrl={config.ui.logoUrl ? config.ui.logoUrl : ArmusLogo}
                cobrandColor={theme.palette.mode}
            />

            {routes}

            <Footer />
            <Dialog />
            <Notifications />
        </React.Fragment>
    );
};

Router.propTypes = {
    userState: PropTypes.object.isRequired
};

export default connectAppStore(([state]) => {
    return {
        app: state.app,
        userState: state.user
    };
})(withTheme(Router));
