import React from "react";

export const titleCase = (key) => {
    if(typeof key !== "string") {
        return "";
    }
    let out = key.replace( /([A-Z][a-z])/g, " $1")
        .replace( /([a-z])([0-9])/gi, "$1 $2");
    return out.charAt(0).toUpperCase() + out.slice(1);
};

export const valueToString = (value) => {
    if(value === undefined || value === null) {
        return "-";
    }
    else if(React.isValidElement(value)) {
        return value;
    }
    else if(Array.isArray(value)) {
        return value;
    }
    else if(typeof value === "object") {
        return valueToString(value.label || value.title || "");
    }
    else if(typeof value === "boolean") {
        return value ? "Yes" : "No";
    }
    if(typeof value === "string") {
        return value;
    }
    return value;
};