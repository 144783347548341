import React from 'react';
import { withTheme } from "../../providers/DashboardProvider";
import { formatValue } from "../../../lib/number";
import {
    buildPropTypesFromObject,
    buildPropTypesWithDescriptor
} from "../../../lib/propTypeHelpers";

import Legend from "../Legend/Legend";
import { dataWithInfo } from "../../../lib/utils";
import BoxGauge from "./BoxGauge";
import CircularGauge from "./CircularGauge";
import {
    Grid,
    Box,
} from "@mui/material";
import makeSxStyles from '../../../lib/makeSxStyles';

const useStyles = makeSxStyles((theme) => ({
    root: {
        height: "100%",
    },
    gaugeContainer: {
        height: "100%",
        justifyContent: "center",
        alignContent: "center",
        whiteSpace: "pre"
    },
    xLabel: {
        textAlign: "center",
        fontWeight: "bold",
        whiteSpace: "pre"
    },
    yLabel: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        justifyContent: "center",
        whiteSpace: "pre"
    }
}));

const Gauge = (props) => {
    const {descriptor = {}} = props;
    let {data} = props;
    const classes = useStyles();

    if(!data || !data.length) {
        return "No Data";
    }

    if(!data.info) {
        data = dataWithInfo(data);
    }

    const cfg = defaultConfig(descriptor.config);
    if(cfg.formatValue === 'percent') {
        if ((cfg.min == null)) {
            cfg.min = 0;
        }
        if ((cfg.max == null)) {
            cfg.max = 1;
        }
    }

    let colorScheme = props.getColorScheme({
        ...cfg,
        steps: data.length,
    });

    const labels = data.map((d, i) => ({label: d.label, color: colorScheme({index: i, ...d}) }));
    let Component = cfg.displayType === "circular" ? CircularGauge : BoxGauge;
    return (
        <Legend
            show={cfg.legend}
            position={cfg.legendPosition}
            labels={labels}
            showGradient={cfg.colorScheme.indexOf("auto") !== -1}
            colorScheme={colorScheme}
            min={typeof cfg.min === "number" ? cfg.min : data.info.min }
            max={typeof cfg.max === "number" ? cfg.max : data.info.max }
            formatValue={(v) => formatValue(cfg.formatValue, v)}
            onClick={(e,v) => props.onClick && props.onClick(e, descriptor, v)}
        >
            <Grid container sx={classes.root}>
                {!!cfg.yLabel && (<Grid item xs={1} sx={classes.yLabel}>
                    {cfg.yLabel}
                </Grid>
                )}
                <Grid item xs={!!cfg.yLabel ? 11 : 12}>
                    <Grid container sx={classes.gaugeContainer}>
                    {data.map((d, i) => {
                        const v = Array.isArray(d.value) ? d.info.total/data.info.total : d.value;
                        const marker = cfg.markers[i];
                        const color = colorScheme({index: i, ...d});
                        const format = marker && marker.formatValue ? formatValue(marker.formatValue, v) : formatValue(cfg.formatValue, v);
                        return (
                            <Grid
                                key={i}
                                item
                                xs={cfg.layoutMode === "vertical" ? 12 : Math.floor(12 / data.length)}
                            >
                                <Component
                                    color={color}
                                    label={d.label}
                                    value={v}
                                    formattedValue={format}
                                    min={cfg.min}
                                    max={cfg.max}
                                    onClick={(e,v) => props.onClick(e, descriptor, v)}
                                />
                            </Grid>
                        );
                    })}
                    </Grid>
                    {!!cfg.xLabel && (
                        <Box sx={classes.xLabel}>
                            {cfg.xLabel}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Legend>
    );
};

export const defaultConfig = (config = {}) => ({
    xLabel: "",
    yLabel: "",
    formatValue: null,
    min: null,
    max: null,
    layoutMode: "horizontal",
    displayType: "box",
    legend: false,
    legendPosition: "top",
    colorScheme: 'series',
    colorSchemeReversed: false,
    uniqueMarkers: false,
    markers: [],
    ...config
});

Gauge.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject({
    ...defaultConfig(),
    min: 0,
    max: 1,
    formatValue: "",
}));

export default withTheme(Gauge);
