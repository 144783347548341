import React from 'react';
import * as d3 from "d3";
import Group from './Group';

let randomJitter= [];

const Box = (props) => {
    const topBoxHeight = props.yScale(props.data.value.median) - props.yScale(props.data.value.q3);
    const bottomBoxHeight = props.yScale(props.data.value.q1) - props.yScale(props.data.value.median);
    const middleOfBar = props.xScale(props.title) + props.xScale.bandwidth() / 2;
    const color = props.colors.bars({index: props.index, value: props.data.value.median});
    let points = [];
    if (props.addPoints && props.data.value.hasOwnProperty('points')) {


        if (randomJitter.length === 0) {
            randomJitter = props.data.value.points.map(p => Math.random());
        }

        props.data.value.points.forEach((p, i) => {


            // gets y of point, used to determine it the point is an outlier
            // draws either all points or only outliers
            if (!props.drawOnlyOutliers || (props.drawOnlyOutliers && (p > props.data.value.high || p < props.data.value.low))) {
                points.push(
                    <circle
                        key={i}
                        cx={middleOfBar + ((randomJitter[i] - 0.5) * 2) * props.width / 2 * props.style.jitterWidth}
                        cy={props.yScale(p)}
                        r={props.style.pointRadius}
                        //fill={props.style.pointColor}
                        fill={d3.color(color).copy({opacity: .5})}
                        //opacity={props.style.pointOpacity}
                        stroke={props.style.pointStroke}
                    />
                );
            }
        });
    }

    return (
        <Group x={props.x} y={props.y}>
            <line
                x1={middleOfBar}
                y1={props.yScale(props.data.value.high)}
                x2={middleOfBar}
                y2={props.yScale(props.data.value.q3)}
                //stroke={props.style.wiskerColor[0]}
                strokeWidth={3}
                stroke={color}
            />
            <rect
                x={middleOfBar - props.width / 2}
                y={props.yScale(props.data.value.q3)}
                width={props.width}
                height={topBoxHeight + bottomBoxHeight}
                //stroke={props.style.boxBorder}
                stroke={"transparent"}
                //fill={props.style.boxColor}
                fill={color}
                rx={props.style.boxCorner}
                ry={props.style.boxCorner}
            />
            <line
                x1={middleOfBar - props.width / 2}
                y1={props.yScale(props.data.value.median) + (props.style.medianThickness / 2)}
                x2={middleOfBar + props.width / 2}
                y2={props.yScale(props.data.value.median) + (props.style.medianThickness / 2)}
                stroke={props.style.medianColor}
                //stroke={props.colors.other}
                strokeWidth={props.style.medianThickness}
            />
            <line
                x1={middleOfBar}
                y1={props.yScale(props.data.value.q1)}
                x2={middleOfBar}
                y2={props.yScale(props.data.value.low)}
                //stroke={props.style.wiskerColor[1]}
                strokeWidth={3}
                stroke={color}
            />
            {points}
        </Group>
    );
};

export default Box;