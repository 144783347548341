import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { colorFunctionToArray } from "../../../lib/theme";
import makeSxStyles from "../../../lib/makeSxStyles";

const verticalSize = "25%";
const useStyles = makeSxStyles((theme) => ({
    legend: {
        fontSize: theme.chartFontSize,
        height: "100%",
        width: "100%",
        "& .chartContainer": {
            height: "100%",
        },
        "&.hasLegend.legendHorizontal .chartContainer": {
            height: "calc(100% - " + theme.chartFontSize * 1.55 + "px)",
        },
        "&.hasLegend.legendHorizontal.legendGradient .chartContainer": {
            height: "calc(100% - " + theme.chartFontSize * 2.55 + "px)",
        },
        "&.hasLegend.legendHorizontal .legendContainer": {
            height: theme.chartFontSize * 1.55,
            textAlign: "center",
            overflow: "auto"
        },
        "&.hasLegend.legendHorizontal.legendGradient .legendContainer": {
            height: theme.chartFontSize * 2.55,
        },
        "& .legendList": {
            padding: 0,
            margin: 0
        },
        "& .legendItem": {
            whiteSpace: "nowrap",
            display: "inline-block",
            marginRight: 1
        },
        "& .legendIcon": {
            display: "inline-block",
            height: theme.chartFontSize * 1.25,
            width: theme.chartFontSize * 1.25,
            marginRight: 1,
            verticalAlign: "middle"
        },
        "& .legendLabel": {
            display: "inline-block",
            verticalAlign: "middle"
        },
        "&.legendVertical .chartContainer": {
            height: "100%",
            width: "calc(100% - " + verticalSize + ")",
            display: "inline-block"
        },
        "&.legendVertical .legendContainer": {
            height: "100%",
            width: verticalSize,
            display: "inline-flex",
            verticalAlign: "bottom",
            justifyContent: "center",
            overflow: "auto"
        },
        "&.legendVertical .legendContainer > div": {
            display: "grid",
            alignItems: "center",
        },
        "&.legendVertical .legendItem": {
            display: "block",
        },
        "&.legendHorizontal .gradientContainer": {
            "& .gradientImage": {
                display: "inline-block",
                width: "25%",
                height: 10,
                verticalAlign: "middle"
            },
            "& .legendLabel": {
                paddingLeft: 1,
                paddingRight: 1,
                verticalAlign: "middle"
            }
        },
        "&.legendVertical .gradientContainer": {
            height: "100%",
            textAlign: "center",
            "& .gradientImage": {
                display: "inline-block",
                width: theme.chartFontSize * 1.25,
                height: "75%",
                verticalAlign: "middle"
            },
            "& .legendLabel": {
                display: "block",
                paddingTop: 1,
                paddingBottom: 1,
                verticalAlign: "middle"
            }
        }
    },

}));

const Legend = (props) => {
    const {show = true, position = "top", legendRef, labels, children,  showGradient, onClick} = props;
    const classes = useStyles();
    let displayMode = "";
    if (["left", "right"].includes(position)) {
        displayMode = "legendVertical";
    }
    else {
        displayMode = "legendHorizontal";
    }
    const renderLegend = () => (
        <Box className={"legendContainer"}>
            <Box>
                {!!labels.length && <ul ref={legendRef} className="legendList">
                    {labels.map((l,i) => <li key={l.label+i} className="legendItem" onClick={(e) => onClick(e, l)}>
                        <Box component={"span"} className="legendIcon" style={{backgroundColor: l.color}} />
                        <Box component={"span"} className="legendLabel">{l.label}</Box>
                    </li>)}
                </ul>}
                {showGradient && <ValuesToColorGradient {...props} vertical={displayMode === "legendVertical"} />}
            </Box>
        </Box>
    );

    return (
        <Box sx={classes.legend} className={" " + displayMode + " " + (showGradient ? "legendGradient" : "") + " " + (show ? "hasLegend" : "")}>
            {(show && ["top", "left"].includes(position)) && renderLegend()}
            <Box className="chartContainer">
                {children}
            </Box>
            {(show && ["bottom", "right"].includes(position)) && renderLegend()}
        </Box>
    );
};

const ValuesToColorGradient = ({vertical, colorScheme, min, max,formatValue}) => {
    const gradientColors = colorFunctionToArray(colorScheme, min, max, 20);
    if(vertical){
        return (
            <Box className={"gradientContainer"}>
                <Box 
                    component={"span"}
                    className="legendLabel"
                >
                        {(formatValue ? formatValue(max) : max)}
                </Box>
                <Box 
                    className={"gradientImage"}
                    style={{background: "linear-gradient( to top, " + gradientColors.join(",") + ")"}} />
                <Box 
                    component={"span"}
                    className="legendLabel"
                >
                        {(formatValue ? formatValue(min) : min)}
                </Box>
            </Box>
        );
    }
    else {
        return (
            <Box className={"gradientContainer"}>
                <Box component={"span"}
                    className="legendLabel">{(formatValue ? formatValue(min) : min)}</Box>
                <Box className={"gradientImage"}
                     style={{background: "linear-gradient( to right, " + gradientColors.join(",") + ")"}}/>
                <Box component={"span"}
                    className="legendLabel">{(formatValue ? formatValue(max) : max)}</Box>
            </Box>
        );
    }
};

Legend.propTypes = {
    show: PropTypes.bool,
    position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
        })
    ),
    legendRef: PropTypes.func,
    children: PropTypes.any,
    showGradient: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    colorScheme: PropTypes.func,
    onClick: PropTypes.func,
};

export default Legend;