import React, { Component } from 'react';
import Group from './Group';

export default class Title extends Component {

    render() {
        return (
            <Group x={this.props.x} y={this.props.y}>
                <text
                    x={this.props.xOffset}
                    y={this.props.yOffset}
                    textAnchor={"middle"}
                    fontSize={this.props.style.titleFontSize}
                    fontFamily={this.props.style.fontFamily}
                    fill={this.props.style.titleColor}
                >{this.props.children}</text>
            </Group>
        );
    }
}
