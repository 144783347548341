import React from "react";
import {
    CircularProgress,
    Box,
    lighten,
} from "@mui/material";
import PropTypes from "prop-types";
import makeSxStyles from "../../lib/makeSxStyles";
const useStyles = makeSxStyles(theme => ({
    root: {
        position: 'relative',
        display: "inline-block",
        color: theme.palette.text.primary,
    },
    bottom: {
        zIndex: 2,
        color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
        position: "relative",
    },
    top: {
        zIndex: 2,
        position: 'absolute',
        left: 0,
    },
    content: {
        top: "-3%",
        left: "-3%",
        position: 'absolute',
        zIndex: 1,
        height: "106%",
        width: "106%",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: theme.palette.mode === 'light' ? theme.palette.background.paper : lighten(theme.palette.background.paper, .09),
        "&.Mui-DisableBackground": {
            background: "transparent",
        }
    },
    primary: {
        color: theme.palette.primary.main,
        "& .MuiCircularProgress-svg": {
            color: theme.palette.primary.main,
        },
    },
    success: {
        color: theme.palette.success.main,
        "& .MuiCircularProgress-svg": {
            color: theme.palette.success.main,
        },
    },
    warning: {
        color: theme.palette.warning.main,
        "& .MuiCircularProgress-svg": {
            color: theme.palette.warning.main,
        },
    },
    info: {
        color: theme.palette.info.main,
        "& .MuiCircularProgress-svg": {
            color: theme.palette.info.main,
        },
    },
    error: {
        color: theme.palette.error.main,
        "& .MuiCircularProgress-svg": {
            color: theme.palette.error.main,
        },
    },
    default: {
        color: theme.palette.grey["500"],
        "& .MuiCircularProgress-svg": {
            color: theme.palette.grey["500"],
        },
    }
}));

const Gauge = ({isLoading, size, children, color, value, offsetAngle, arcAngle, disableBackground, ...extraProps }) => {
    const classes = useStyles();
    let overrideColor = color;
    if(!["primary", "secondary", "inherit"].includes(color)) {
        overrideColor = "inherit";
    }
    return (
        <Box sx={classes.root} style={{height: size || 40, width: size || 40}}>
            <Box sx={{...classes.content, ...(!!classes[color] ? classes[color] : {})}} className={(disableBackground ? "Mui-DisableBackground" : "")}>
                {children}
            </Box>
            <CircularProgress
                {...extraProps}
                size={size}
                variant="determinate"
                color={"inherit"}
                sx={classes.bottom}
                value={arcAngle / 360 * 100}
                style={{transform: `rotate(${offsetAngle - 90}deg)`}}
            />
            <CircularProgress
                {...extraProps}
                variant={isLoading ? "indeterminate" : extraProps.variant}
                size={size}
                value={(value / 100) * (arcAngle / 360) * 100}
                color={overrideColor}
                sx={{...classes.top, ...(!!classes[color] ? classes[color] : {})}}
                style={{transform: `rotate(${offsetAngle - 90}deg)`}}
            />
        </Box>
    );
};

Gauge.defaultProps = {
    isLoading: false,
    size: 100,
    variant: "determinate",
    thickness: 4,
    offsetAngle: 0,
    arcAngle: 360,
    disableBackground: false,
    value: 0,
    color: "primary"
};

Gauge.propTypes = {
    ...CircularProgress.propTypes,
    value: PropTypes.number,
    size: PropTypes.number,
    thickness: PropTypes.number,
    variant: PropTypes.oneOf(['static', 'determinate', 'indeterminate']),
    offsetAngle: PropTypes.number,
    arcAngle: PropTypes.number,
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'default']),
    disableBackground: PropTypes.bool,
};

export default Gauge;