import {
    buildAsyncReducer,
    buildAsyncActions,
    asyncInitialState
} from "./utils/asyncReducer";

const initialState = {
    ...asyncInitialState,
    data: []
};

export const notificationSubscriptionActions = buildAsyncActions("notificationSubscription");
export const notificationTestActions = buildAsyncActions("notificationTest");

export const notificationSubscriptionReducer = buildAsyncReducer(notificationSubscriptionActions, initialState);
