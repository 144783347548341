import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "../providers/DashboardProvider";
import { exportImage } from "../../lib/exportHelpers";
import { useIntl } from "../providers/IntlProvider";
import { Grid, Box } from "@mui/material";
import TitleBar from "../common/TitleBar";
import { buildPropTypesFromObject } from "../../lib/propTypeHelpers";
import DescriptorResolver from "./components/DescriptorResolver";
import ErrorBoundary from "../common/ErrorBoundary";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
  dashboard: {
    ...theme.typography.body2,
    padding: theme.spacing(2),
  },
  titleBar: {
    marginBottom: theme.spacing(1),
  },
}));

const Dashboard = (props) => {
  const dashboardRef = React.useRef();
  const {
    
    descriptor,
    data,
    value,
    onClick,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    enablement,
    visibility,
    isLoading = false,
    children,
  } = props;
  const {
    theme,
    themes,
    themeIndex,
    setTheme
  } = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  
  if (!descriptor) {
    return intl.formatMessage({
      id: "noDescriptor",
      defaultMessage: "no descriptor supplied",
    });
  }
  const config = descriptor.config || {};
  const d = defaultDescriptor(descriptor);
  const handleExportDashboard = () => {
    setTimeout(() => {
      exportImage(
        d.label || "Dashboard",
        dashboardRef.current,
        theme.palette.background.default
      );
    }, 100);
  };
  const themeOptions = themes.map((t, i) => ({
    label: intl.formatMessage({
      id: "themeLabel" + i,
      defaultMessage: t.name,
    }),
    active: themeIndex === i,
    value: () => setTheme(i),
  }));

  const menuOptions = [];
  if (config.allowThemes) {
    menuOptions.push(...themeOptions);
  }
  if (config.allowThemes && config.allowExport) {
    menuOptions.push(null);
  }
  if (config.allowExport) {
    menuOptions.push({
      label: intl.formatMessage({
        id: "exportFullDashboard",
        defaultMessage: "Export Dashboard as Image",
      }),
      value: handleExportDashboard,
    });
  }

  return (
      <div className={"dashboard"} sx={classes.dashboard} ref={dashboardRef}>
        {config.showTitleBar && (
          <TitleBar
            sx={classes.titleBar}
            title={
              d.label ||
              intl.formatMessage({ id: "title", defaultMessage: d.label })
            }
            description={d.description}
            menuOptions={menuOptions}
            logo={config.logoURL}
          >
            {children}
          </TitleBar>
        )}
        <Box mt={1}>
          <Grid container spacing={1} style={{ flexGrow: 1 }}>
            <ErrorBoundary>
              <DescriptorResolver
                descriptor={d}
                isLoading={isLoading}
                data={data}
                value={value}
                onClick={onClick}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                enablement={enablement}
                visibility={visibility}
              />
            </ErrorBoundary>
          </Grid>
        </Box>
      </div>
  );
};

const defaultDescriptor = (descriptor = {}) => ({
  id: "",
  label: "",
  description: "",
  config: {
    allowThemes: true,
    allowExport: true,
    showTitleBar: true,
    logoURL: "",
  },
  items: [], // tabs
  ...descriptor,
});

Dashboard.defaultProps = {
  descriptor: defaultDescriptor(),
  data: {},
  value: {},
  filterData: {},
  isLoading: false,
  isFiltersLoading: false,
};

Dashboard.propTypes = {
  descriptor: PropTypes.shape(buildPropTypesFromObject(defaultDescriptor()))
    .isRequired,
  data: PropTypes.object.isRequired,
  value: PropTypes.object,
  filterData: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  enablement: PropTypes.func,
  visibility: PropTypes.func,
  isLoading: PropTypes.bool,
  isFiltersLoading: PropTypes.bool,
};

export default Dashboard;
