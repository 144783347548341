import React from "react";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";

const StatusChip = (props) => {
    const fileStatusProps = {
        CANCELLED: {
            label: "Cancelled",
            disabled: true,
            color: "default"
        },
        NEW: {
            label: "New",
            color: "info"
        },
        FAILED: {
            label: "Failed",
            color: "error"
        },
        SUCCESS: {
            label: "Success",
            color: "success"
        },
        PROCESSING: {
            label: "Processing",
            color: "warning"
        },
        REPROCESS: {
            label: "Reprocess",
            variant: "default"
        }
    };
    const chipProps = {
        size: "small",
        //variant: "default",
        label: "Unknown",
        disabled: false,
        color: "primary",
        style: {},
        ...(fileStatusProps[props.status] || {}),
        ...props
    };
    return <Chip {...chipProps} />;
};


StatusChip.propTypes = {
    status: PropTypes.oneOf(["CANCELLED", "NEW", "SUCCESS", "FAILED", "PROCESSING", "REPROCESS"])
};

export default StatusChip;
