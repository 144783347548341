import React from "react";
import {
    Box,
    Button as BaseButton,
    ButtonGroup as BaseButtonGroup,
    InputLabel,
    alpha,
} from "@mui/material";
import Spinner from "../common/Spinner";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        position: "relative",
        width: "100%",
        marginTop: 1,
        marginBottom: .5,
        "& button": {
            padding: "0!important",
            height: 53,
            transition: "none",
            flex: "auto",
        },
        "&.dense": {
            "& button":{
                height: 37,
            }
        },
        "&.MuiButtonGroup-focused": {
            "& .MuiButtonGroupLabel-root": {
                color: theme.palette.primary.main,
            },
        },
        "& .MuiButtonGroupLabel-root": {
            position: "absolute",
            borderRadius: 4,
            background: "linear-gradient(to top, "+theme.palette.background.default+" 70%, "+alpha(theme.palette.background.default, 0)+" 70%)",
            left: 10,
            top: -9,
            zIndex: 2,
            padding: "0 4px",
            overflow: "hidden",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: "10px",
        },
    }
}));

const ButtonGroup = (props) => {
    const {descriptor = {}, onClick, options = [], data, onFocus, onChange, onBlur, onKeyDown, value, children, dense, isLoading} = props;
    const [hasFocus, setHasFocus] = React.useState(false);
    let disabled = props.disabled;
    const cfg = defaultConfig(descriptor.config);
    const classes = useStyles();
    // merge options
    let mergedOptions = [];
    if(data || options) {
        mergedOptions = [...(data || options)];
        cfg.options.forEach(a => {
            if (data.findIndex(b => a.label === b.label) !== -1) return;
            mergedOptions.push(a);
        });
    }
    const newProps = {
        ...props
    };
    delete newProps.isLoading;
    delete newProps.showCarrot;
    delete newProps.descriptor;
    delete newProps.options;
    delete newProps.data;
    delete newProps.children;
    delete newProps.dense;
    delete newProps.value;
    let BtnComponent = BaseButton;
    const isMultivalued = cfg.multivalued || false;
    const handleChange = (e, v, d) => {
        if(isMultivalued) {
            if(value.includes(v)) { // remove
                v = value.filter(it => it !== v);
            }
            else { // add
                v = [...(Array.isArray(value) ? value : []), v];
            }
        }
        onChange && onChange(e, v, d);
    };

    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        disabled = true;
    }
    return (
        <Box sx={classes.root} className={(cfg.dense ? " dense" : "") + (hasFocus ? " MuiButtonGroup-focused " : "") + " MuiFormControl-root MuiFormControl-fullWidth"}>
            <BaseButtonGroup
                className={"Mui-" + cfg.colorScheme + " MuiButtonGroup-" + cfg.variant}
                id={descriptor.id}
                title={descriptor.label + (descriptor.description ? " - " + descriptor.description : "") }
                color={"default"}
                variant={cfg.variant}
                fullWidth={true}
            >
                {mergedOptions.map((o, i) => (
                    <BtnComponent
                        key={o.value}
                        id={o.id || descriptor.id}
                        name={o.id || descriptor.id}
                        {...newProps}
                        className={isSelected(value, o.value) ? "MuiActive" : "MuiInactive"}
                        color={isSelected(value, o.value) ? (cfg.colorScheme === "primary" ? "info" : cfg.colorScheme) : "default"}
                        disabled={disabled || o.disabled}
                        size={dense || cfg.dense ? "small" : "medium"}
                        onClick={(e) => {
                            onClick && onClick(e, o, descriptor);
                            handleChange(e, o.value, descriptor);
                        }}
                        onFocus={(e) => {
                            setHasFocus(true);
                            onFocus && onFocus(e, o, descriptor);
                        }}
                        onBlur={(e) => {
                            setHasFocus(false);
                            onBlur && onBlur(e, o, descriptor);
                        }}
                        onKeyDown={(e) => {onKeyDown && onKeyDown(e, o, descriptor);}}
                        disableElevation
                    >
                        {children || o.label || o.value}
                    </BtnComponent>
                ))}
            </BaseButtonGroup>
            {descriptor.label && <InputLabel className={"MuiButtonGroupLabel-root MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-colorPrimary MuiButtonGroupLabel-root MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled css-zxplcr-MuiFormLabel-root-MuiInputLabel-root"}
            >
                {descriptor.label}
            </InputLabel>}
            {isLoading ? <Spinner size={18} overlay={true} /> : null}
        </Box>
    );
};

const isSelected = (state, value) => {
    if(Array.isArray(state)) {
        return state.includes(value);
    }
    return state === value;
};

ButtonGroup.defaultProps = {
    value: null,
    data: [],
    options: [],
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            options: [],
            dense: true,
            colorScheme: 'primary',
            variant: "outlined",
            multivalued: false,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...ButtonGroup.defaultProps.descriptor.config,
    ...config
});

export default ButtonGroup;