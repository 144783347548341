import {combineReducers, createContextReducer, combineActions} from "react-context-reducer";
import { userReducer, userActions } from "./UserStore";
import { appReducer, appActions } from "./AppStore";
import { streamsReducer, streamsActions } from "./StreamsStore";
import { streamReducer, streamActions } from "./StreamStore";
import { streamFilesReducer, streamFilesActions } from "./StreamFilesStore";
import { streamFileDetailsReducer, streamFileDetailsActions } from "./StreamFileDetailsStore";
import { notificationTestActions, notificationSubscriptionActions, notificationSubscriptionReducer} from "./NotificationSubscriptionStore";

const store = createContextReducer("AppStore",
    combineReducers({
        app: appReducer,
        user: userReducer,
        streams: streamsReducer,
        stream: streamReducer,
        notificationSubscription: notificationSubscriptionReducer,
        streamFiles: streamFilesReducer,
        streamFileDetails: streamFileDetailsReducer
    }) );

export default store;

export const AppStoreActions = combineActions({
    app: appActions,
    user: userActions,
    streams: streamsActions,
    stream: streamActions,
    notificationSubscription: notificationSubscriptionActions,
    notificationTest: notificationTestActions,
    streamFiles: streamFilesActions,
    streamFileDetails: streamFileDetailsActions
});

export const AppStoreProvider = store.Provider;
export const connectAppStore = store.connect;
