import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const DatePickerProvider = ({children, ...extraProps}) => {
    return (
        <LocalizationProvider  dateAdapter={AdapterMoment} {...extraProps}>
            {children}
        </LocalizationProvider>
    );
};

export default DatePickerProvider;