import React from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import Paper from "../../common/Paper";
import PropTypes from "prop-types";
import { buildDescriptorPropTypes } from "../../../lib/propTypeHelpers";
import DescriptorResolver from "./DescriptorResolver";
import makeSxStyles from "../../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
  paper: {
    //marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    color: theme.palette.text.disabled,
  },
}));

const TabResolver = ({
  descriptor,
  isLoading,
  data,
  onClick,
  isElevated = false,
  ...extraProps
}) => {
  const items = descriptor.items || [];
  const config = descriptor.config || {};
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const hasLabel = !!descriptor.label;
  const tab = items[hasLabel ? activeTab : activeTab] || {};
  return (
    <div>
      <Paper sx={classes.paper} elevation={isElevated ? 0 : 1}>
        {items.length && (
          <AppBar
            position="static"
            color="default"
            sx={classes.appBar}
            elevation={0}
          >
            <Tabs
              orientation="horizontal"
              value={hasLabel ? activeTab + 1 : activeTab}
              onChange={(e, v) => {
                v = hasLabel ? v - 1 : v;
                setActiveTab(v);
                onClick && onClick(e, v, items[v]);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {hasLabel && (
                <Tab
                  disabled
                  sx={classes.label}
                  label={descriptor.label}
                />
              )}
              {items.map((t, i) => (
                <Tab key={i} label={t.label} />
              ))}
            </Tabs>
          </AppBar>
        )}
        {!!tab.description && (
          <Box p={2} marginBottom={0}>
            <Typography>{tab.description}</Typography>
          </Box>
        )}
        {config.elevate && (
          <TabContent
            isLoading={isLoading}
            tab={tab}
            data={data}
            isElevated={true}
            onClick={onClick}
            {...extraProps}
          />
        )}
      </Paper>
      {!config.elevate && (
        <TabContent
          isLoading={isLoading}
          tab={tab}
          data={data}
          isElevated={isElevated}
          onClick={onClick}
          {...extraProps}
        />
      )}
    </div>
  );
};

TabResolver.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(buildDescriptorPropTypes()))
    .isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default TabResolver;

const TabContent = ({
  tab,
  isLoading,
  onClick,
  data,
  isElevated,
  ...extraProps
}) => {
  const d = {
    id: "",
    label: "",
    description: "",
    items: [],
    type: "tab",
    ...tab,
  };
  return (
    <DescriptorResolver
      isLoading={isLoading}
      data={data}
      descriptor={d}
      onClick={onClick}
      isElevated={isElevated}
      {...extraProps}
    />
  );
};
