import React, { Component } from 'react';
import Group from './Group';

export default class ErrorBar extends Component {
    render() {
        const domain = this.props.yScale.domain();
        const y = this.props.yScale(domain[1] + (domain[0] - domain[1]) / 2);
        const x = this.props.xScale(this.props.title) + this.props.xScale.bandwidth() / 2;
        return (
            <Group x={this.props.x} y={this.props.y}>
                <text
                    x={x}
                    y={y}
                    transform={"rotate(-90," + x + "," + y + ")"}
                    textAnchor={"middle"}
                    dominantBaseline={"central"}
                    fontSize={this.props.style.errorFontSize}
                    fontFamily={this.props.style.fontFamily}
                    fill={this.props.style.errorColor}
                >{'Insufficient Data'}</text>
            </Group>
        );
    }
}
