import {
    buildAsyncReducer,
    buildAsyncActions, asyncInitialState
} from "./utils/asyncReducer";

const initialState = {
    ...asyncInitialState,
    data: {
        transitions: []
    }
};

export const streamFileDetailsActions = buildAsyncActions("streamFileDetails");
export const streamFileDetailsReducer = buildAsyncReducer(streamFileDetailsActions, initialState);
