import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SmartLink from "./SmartLink";
import {
  IconButton,
  Icon,
  Snackbar,
  SnackbarContent,
  Button,
  Box,
  LinearProgress
} from "@mui/material";

const variantIcon = {
  success: "check_circle_icon",
  warning: "warning_icon",
  error: "error_icon",
  info: "info_icon",
};

const classes = {
  snackbarContent: {
    minHeight: 42,
    p: 1, pl: 2, pr: 2,
    "& .MuiSnackbarContent-message": {
      padding: 0
    },
    "&.small": {
      minHeight: 34,
    }
  },
  default: {
    backgroundColor: "#fafafa",
    color: "#000000",
  },
  success: {
    backgroundColor: "success.main",
    color: "white",
  },
  error: {
    backgroundColor: "error.main",
    color: "white",
  },
  info: {
    backgroundColor: "info.main",
    color: "white",
  },
  warning: {
    backgroundColor: "warning.main",
    color: "black",
  },
  icon: {
    lineHeight: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 1,
  },
  message: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    width: "100%",
  },
  progressbar: { 
    width: '100%', 
    position: "absolute", 
    bottom: 0, 
    left: 0, 
    borderTop: "solid 1px rgba(255,255,255,.5)"
  },
  small: {p: .5, pl: 1, pr: 1},
  medium: {p: 1, pl: 2, pr: 2},
  close: {
    padding: 0
  }
};

const NotificationItem = React.forwardRef((props, ref) => {
  const { id, message, actions, canClose, progress = 50, onClose, variant, size = "medium", iconName } = props;
  const icon = iconName || variantIcon[variant];
  const allActions = [];
  if (canClose) {
    allActions.push(
      <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      size="small"
      sx={classes.close}
      onClick={onClose}
    >
      <Icon sx={classes.icon}>close_icon</Icon>
    </IconButton>
    );
  }
  if (actions !== undefined) {
    // extra actions
    actions.forEach((action) => {
      allActions.unshift(
        <Button
          color="inherit"
          key="other-action"
          size="small"
          component={SmartLink}
          {...action}
        >
          {action.label}
        </Button>
      );
    });
  }
  return (
    <SnackbarContent
      ref={ref} 
      className={size}
      sx={{...classes.snackbarContent, ...classes[variant], ...(classes[size] || {})}}
      aria-describedby="client-snackbar"
      message={
        <Box key={id}>
          <Box id="client-snackbar" sx={classes.message}>
            {!!icon && <Icon sx={{ ...classes.icon, ...classes.iconVariant }}>
              {icon}
            </Icon>}
            {message}
          </Box>
          {progress !== null && <Box sx={classes.progressbar}>
            <LinearProgress color={variant === "default" ? "primary" : variant} variant="determinate" value={progress} />
          </Box>}
        </Box>
      }
      action={allActions}
    />
  );
});

NotificationItem.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      to: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  canClose: PropTypes.bool,
  progress: PropTypes.number,
  message: PropTypes.node,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  iconName: PropTypes.string,
  variant: PropTypes.oneOf(["success", "warning", "error", "info", "default"]).isRequired,
};

const Notifications = (props) => {
  const { queue, onHideNotification } = props;
  let hideTimeoutRef = useRef();
  const handleClearTimeout = () => {
    clearTimeout(hideTimeoutRef.current);
  };

  const handleSetTimeout = () => {
    // hide one notification every 5 seconds
    clearTimeout(hideTimeoutRef.current);
    hideTimeoutRef.current = setTimeout(() => {
      onHideNotification();
    }, 5000);
  };
  useEffect(() => {
    if (queue.length) {
      handleSetTimeout();
    }
  });
  return (
    <>
      {queue.map((notification, index) => {
        const groupIndex = queue.filter(it => 
            it.verticalPosition === notification.verticalPosition &&
            it.horizontalPosition === notification.horizontalPosition && 
            it.stacked === notification.stacked
          ).findIndex(it => 
            it === notification
          );
        return (
          <Snackbar
            key={notification.message + index}
            anchorOrigin={{
              vertical: notification.verticalPosition,
              horizontal: notification.horizontalPosition,
            }}
            open={notification.open}
            autoHideDuration={null}
            style={{ [notification.verticalPosition === "top" ? "marginTop" : "marginBottom"]: (notification.stacked ? 48 * groupIndex : 0 ) + 55 }}
            onMouseEnter={handleClearTimeout}
            onMouseLeave={handleSetTimeout}
          >
            <NotificationItem
              onClose={() => onHideNotification(index)}
              id={notification.key}
              iconName={notification.iconName}
              canClose={notification.canClose}
              progress={notification.progress}
              variant={notification.type}
              message={notification.message}
              actions={notification.actions}
              size={notification.size}
              index={index}
            />
          </Snackbar>
        );
      }
      )}
    </>
  );
};

Notifications.propTypes = {
  queue: PropTypes.arrayOf(
    PropTypes.shape({
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          onClick: PropTypes.func,
          to: PropTypes.string,
          href: PropTypes.string,
        })
      ),
      stacked: PropTypes.bool,
      size: PropTypes.oneOf(["small", "medium"]),
      type: PropTypes.oneOf(["success", "warning", "error", "info", "default"]).isRequired,
      message: PropTypes.node.isRequired,
      open: PropTypes.bool,
      progress: PropTypes.number,
      canClose: PropTypes.bool,
      canAutoClose: PropTypes.bool,
    })
  ),
  onHideNotification: PropTypes.func.isRequired, // (index) => set open to false
  onRemoveNotification: PropTypes.func.isRequired, // (index) => set open to false
};

export default Notifications;
