import React from "react";
import queryString from "query-string";
import { Icon } from '@mui/material';

const initialConfig = {
    baseUrl: "/static/mocks", // base location where static mock sets are stored
    useMocks: true,
    showMockMenu: true,
    loadingDelay: 500, // loading delay between 0 - 1000 ms
    logRequests: true, // show the mock request in the console.

    // For local server-less development
    // initialize with a mockset selected.
    // or use ?mockset=admin
    mockSetKey: null,
};


const buildMockInstance = (config = {}, mockSets = []) => {
    if(mockSets.length === 0) {
        throw new Error("buildMockInstance: no mock sets provided.");
    }
    let currentMockSet = null;
    let currentConfig = {};

    const setConfig = (config) => {
        currentConfig = {...initialConfig, ...config};
    };

    setConfig(config);

    const parsed = queryString.parse(window.location.search);
    let mockSetKey = parsed.mockset;

    if(mockSetKey === undefined) {
        if (currentConfig.mockSetKey) {
            mockSetKey = currentConfig.mockSetKey;
        }
        else if (currentConfig.mockSetKey == null && mockSets.length > 0) {
            // default to first mock set.
            mockSetKey = mockSets[0].key;
        }
    }

    // Find the mock set set it to the current
    currentMockSet = mockSets.find((mock) => mock.key === mockSetKey);

    const getMockSet = () => {
        return currentMockSet;
    };

    const setMockSet = (mock = {}) => {
        mockSetKey = mock.key;
        currentMockSet = mock;
        return currentMockSet;
    };

    const getMockSets = () => {
        return mockSets;
    };

    const setMockSets = (ms = []) => {
        mockSets = ms;
    };

    const getDelay = () => {
        return currentConfig.loadingDelay;
    };

    // adds a mock interceptor to an axios instance.
    const mockInterceptor = (api) => {
        currentConfig = {...initialConfig, currentConfig};

        // Intercept requests and inject mockset responces
        if(currentMockSet) {
            // user wants to mock all api requests
            api.interceptors.request.use(function (options) {
                if(config.logRequests) {
                    console.log(`MOCK: [${options.method.toUpperCase()}] ${options.url}`, {
                        data: options.data,
                        headers: options.headers
                    });
                }
                // a mock set was requested make all requests use that mock set
                options.baseURL = "";
                options.url = buildMockURL(options.method.toUpperCase(), options.url);
                options.method = "get"; // all mock requests will be GET in the end.
                return options;
            }, function (error) {
                // Do something with request error
                return Promise.reject(error);
            });

            // add some random delay to the mock request.
            api.interceptors.response.use(function (response) {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(response);
                    }, getDelay());
                });
            }, function (error) {
                return error;
            });
        }
        else if(mockSetKey) {
            console.log(`mockInterceptor: unknown mock set "${mockSetKey}".`);
        }
    };

    const buildMockURL = (method = "GET", url, mockFileType = "json") => {
        if(currentMockSet == null) {
            console.log(`buildMockURL: unknown mock set "${mockSetKey}".`);
            return "#";
        }
        const parts = url.split("?");
        const utlTrimmed = parts[0];
        const qs = parts[1];
        return `${currentConfig.baseUrl}${currentMockSet.url}${utlTrimmed}.${method}.${mockFileType}${qs ? "?"+qs : ""}`;
    };

    const buildMockSetNav = () => {
        const nav = [];
        mockSets.forEach((mock) => {
            nav.push({
                label: mock.label,
                url: `${window.location.origin}?mockset=${encodeURI(mock.key)}`,
                icon: <Icon>account_circle_icon</Icon>
            });
        });
        return nav;
    };

    return {
        getMockSet,
        setMockSet,
        getMockSets,
        setMockSets,
        setConfig,
        mockInterceptor,
        buildMockURL,
        buildMockSetNav,
    };
};

export default buildMockInstance;