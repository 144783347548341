import React, { Component } from 'react';
import { scaleLinear } from 'd3-scale';
import SubPlot from './SubPlot';
import * as d3 from 'd3';
import Measure from 'react-measure';

class BoxPlot extends Component {

  constructor(props) {
    super(props);
    this.state = {
        width: this.props.width,
        height: this.props.height
    };
  }


  render() {
    const colorScheme = this.props.colorScheme;

    let style = boxPlotDefaultStyle;
    if (this.props.hasOwnProperty('style')) {
      style = { ...this.props.style };
    }

    // calculates y axis length
    const yAxisHeight = this.state.height - this.props.margin.top - this.props.margin.bottom;

    // stores the global min and max value
    let chartMax = Number.MIN_VALUE;
    let chartMin = Number.MAX_VALUE;

    // adds the iqr, low, and high values to each bar, also calculates global min and max value
    const data = [...this.props.data];
    data.forEach(group => {
      group.value.forEach(bar => {
        const barData = bar.value;

        const requiredValues = ['median', 'q1', 'n', 'q3'];
        const hasAllValues = requiredValues.map(p => barData.map(b => b.label === p).some(i => i)).every(i => i);

        // calculate the required values if needed and points are available
        //if ((!hasAllValues && bar.hasOwnProperty('points')) || (bar.hasOwnProperty('points') && this.props.addPoints)) {

        const hasPoints = barData.map(b => b.label === "points").some(i => i);
        if (hasPoints && (!hasAllValues || this.props.addPoints)) {


          const points = barData.find(it => it.label === "points").value.map(p => p.value);
          barData.points = points;
          barData.n = points.length;

          const sortedPoints = points.sort(d3.ascending);
          chartMax = sortedPoints[sortedPoints.length - 1] > chartMax ? sortedPoints[sortedPoints.length - 1] : chartMax;
          chartMin = sortedPoints[0] > chartMax ? sortedPoints[0] : chartMin;
          barData.median = d3.quantile(sortedPoints, .50);
          barData.q1 = d3.quantile(sortedPoints, .25);
          barData.q3 = d3.quantile(sortedPoints, .75);
        }
        else if (!hasAllValues) {
          bar.value.error = true;
        }
        else {
          barData.median = barData.find(it => it.label === "median").value;
          barData.q1 = barData.find(it => it.label === "q1").value;
          barData.q3 = barData.find(it => it.label === "q3").value;
          barData.n = barData.find(it => it.label === "n").value;
        }

        // calculation for the iqr, min max, and end of the whiskers
        barData.iqr = (barData.q3 - barData.q1) * 1.5;
        barData.low = barData.q1 - barData.iqr;
        barData.high = barData.q3 + barData.iqr;
        chartMax = barData.high > chartMax ? barData.high : chartMax;
        chartMin = barData.low < chartMin ? barData.low : chartMin;
      });
    });

    // rounds the max up and down to add padding
    const yAxisBufferPercent = 5;
    const chartRange = chartMax - chartMin;
    chartMax = chartMax + chartRange / 100 * yAxisBufferPercent;
    chartMin = chartMin - chartRange / 100 * yAxisBufferPercent;

    const yScale = scaleLinear().domain([chartMax, chartMin]).range([0, yAxisHeight]);
    const yAxisWidth = this.props.margin.left;
    const numSubPlots = data.length;
    const subPlotWidth = (this.state.width - yAxisWidth) / numSubPlots;

    let subPlots = [];
    data.forEach((g, i) => {
      const groupNames = g.value.map(bar => bar.label);

      subPlots.push(
        <SubPlot
          width={subPlotWidth + (i === 0 ? yAxisWidth : 0)}
          height={this.state.height}
          key={i}
          margin={{
            top: this.props.margin.top,
            right: 10,
            bottom: this.props.margin.bottom,
            left: 10 + (i === 0 ? yAxisWidth : 0)
          }}
          data={g}
          groupNames={groupNames}
          yScale={yScale}
          min={chartMin}
          max={chartMax}
          plotNum={i}
          yAxisWidth={yAxisWidth}
          addPoints={this.props.addPoints}
          addCounts={this.props.addCounts}
          addGrid={this.props.addGrid}
          style={style}
          drawOnlyOutliers={this.props.drawOnlyOutliers}
          colorScheme={colorScheme}
        />
      );
    });

    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({
            width: contentRect.bounds.width,
            height: contentRect.bounds.height
          });
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} style={{ height: 'inherit' }}>
            <svg
              width={this.state.width}
              height={this.state.height}
            >
              {/* this is the y axis label */}
              <text
                y={15}
                x={-this.state.height / 2}
                transform={"rotate(-90)"}
                textAnchor={"middle"}
                alignmentBaseline={"middle"}
                fontFamily={style.fontFamily}
                fontSize={style.yLabelFontSize}
                fill={style.yLabelColor}
              >
                {this.props.data.yAxisLabel}
              </text>
              {subPlots}
            </svg>
          </div>
        )}
      </Measure>
    );
  }
}

// default style values are here, and are overridden by the style prop
export const boxPlotDefaultStyle = {
  titleFontSize: 16,
  titleColor: "black",
  yLabelFontSize: 14,
  errorFontSize: 12,
  errorColor: "black",
  groupFontSize: 10,
  countFontSize: 8,
  countColor: "black",
  pointColor: "black",
  pointOpacity: 0.5,
  pointRadius: 2,
  pointStroke: "black",
  boxColor: "white",
  boxBorder: "black",
  boxCorner: 1,
  whiskerColor: ["black", "black"],
  medianColor: "black",
  medianThickness: 2,
  xAxisColor: "black",
  yAxisColor: "black",
  fontFamily: "Arial, Helvetica, sans-serif",
  jitterWidth: 0.75,
  gridColor: "gray"
};

export default BoxPlot;