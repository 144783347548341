import React from "react";
import PropTypes from "prop-types";
import { MenuList, MenuItem, Divider, Link, Box  } from '@mui/material';

const NavMenu = ({items, linkComponent, depth}) => {
    const [state, setState] = React.useState([]);
    const toggleIndex = (index) => {
        if(state.includes(index)) {
            setState(state.filter(it => it !== index));
        }
        else {
            setState([...state, index]);
        }
    };
    return (
        <Box ml={depth ? 1 : 0}>
            <MenuList>
                {items.map((it, index) => {
                    const hasChildren = it.items.length;
                    if(hasChildren) {
                        return (
                            <>
                                <MenuItem onClick={() => toggleIndex(index)}>{it.label}</MenuItem>
                                {!state.includes(index) && <NavMenu items={it.items} depth={depth+1} linkComponent={linkComponent} />}
                                {index !== items.length-1 && <Divider />}
                            </>
                        );
                    }
                    else {
                        return (
                            <MenuItem component={it.linkProps ? linkComponent : "li"} {...it.linkProps || {}} >
                                {it.label}
                            </MenuItem>
                        );
                    }
                })}
            </MenuList>
        </Box>
    );
};

NavMenu.defaultProps = {
    items: [],
    linkComponent: Link,
    depth: 0,
};


const itemShape = {
    label: PropTypes.any,
    props: PropTypes.object,
    items: PropTypes.array,
};
itemShape.items = PropTypes.arrayOf(itemShape); // recursive shape.

NavMenu.propTypes = {
    items: PropTypes.arrayOf(itemShape),
    linkComponent: PropTypes.any,
    depth: PropTypes.number,
};

export default NavMenu;
