import config from "../../config";
import AppStore, {AppStoreActions} from "../data/index";
import axios from "axios";
import {cache, cookie} from "@armus/armus-dashboard";

// Create an axios instance for api requests
const api = axios.create({
    baseURL: config.endpoints.apiRoot,
    timeout: 60000,
    headers: { "X-Custom-Header": "armus" }
});


// Set up Axios Interceptors.
// These functions will get called before every request/response.
if(process.env.NODE_ENV === "development") {
    if (config.mocks.useMocks) {
        const mock = require("./mocks").default;
        mock.mockInterceptor(api); // adds the mock interceptor to the axios instance
    }
}

api.interceptors.request.use(function (options) {
    switch(options.method) {
        case "post":
            // add CSRF token to params for all API requests.
            options.data = options.data !== undefined ? options.data : {};
            options.data[config.session.CSRF_POST_KEY] = cookie.get(config.session.CSRF_COOKIE_KEY);
            break;
        default:
    }
    return options;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    // Do something with Status Codes in the 200's range
    switch(response.status) {
        case 200:
            break;
        default:
            console.log(`API: Status Code "${response.status}"`, response);
    }
    return response;
}, function (error) {
    const response = error.response || {};
    let message = "";
    // Do something with all other Status Codes.
    if([undefined, 401, 403].includes(response.status)) {
        // status code 4xx or (canceled/aborted undefined)
        message = "You are not authorized to make that request.";
        if(response.status === undefined) {
            // set the status code to 401
            response.status = 401;
        }
        console.error(`API Error: Status Code "${response.status}"`, error.message, response.config);
    }
    else {
        message = "Something bad happened, Please try again in a few moments.";
        console.error(`API Error: Status Code "${response.status}"`, error.message, response.config);
    }
    console.log(message);
    return Promise.reject(error);
});

// now for the actual API helper functions.
const loadUserData = () => {
    const dispatch = AppStore.dispatch;
    dispatch({ type: AppStoreActions.user.Loading });
    // request the user data
    return api.get("/user")
        .then((res) => {
            const data = res.data;
            dispatch({ type: AppStoreActions.user.Loaded, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => {
            // User is most likely not logged in...
            // Kick the user over to sing-in page.
            window.location = config.endpoints.login;
            return Promise.reject(err);
        });
};

// user ping check
const pingUserData = () => {
    return api.get("/user")
        .then((res) => {
            const data = res.data;
            AppStore.dispatch({ type: AppStoreActions.user.Loaded, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            AppStore.dispatch({ type: AppStoreActions.user.Error, error: err, data: {} });
            return Promise.reject(err);
        });
};

const loadStreams = (orgKey) => {
    const dispatch = AppStore.dispatch;
    const url = `/streams/${orgKey}/stream`;
    if(cache.hasKey(url)) {
        const data = cache.load(url);
        dispatch({ type: AppStoreActions.streams.Loaded, data: data });
        return Promise.resolve(data);
    }

    dispatch({ type: AppStoreActions.streams.Loading });
    // request the stream data
    return api.get(url)
        .then((res) => {
            const data = res.data || [];
            cache.save(url, data);
            dispatch({ type: AppStoreActions.streams.Loaded, data: data});
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.streams.Error, error: err });
            return Promise.reject(err);
        });
};

const loadStream = (orgKey, streamOrgKey, streamName) => {
    const dispatch = AppStore.dispatch;
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}`;

    dispatch({ type: AppStoreActions.stream.Loading });
    // request the stream data
    return api.get(url)
        .then((res) => {
            const data = res.data || {content: []};
            dispatch({ type: AppStoreActions.stream.Loaded, data: data});
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.stream.Error, error: err });
            return Promise.reject(err);
        });
};

const loadNotificationSubscription = (orgKey, streamOrgKey, streamName) => {
    const dispatch = AppStore.dispatch;
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/subscriptions`;

    dispatch({ type: AppStoreActions.notificationSubscription.Loading });
    // request the stream data
    return api.get(url)
        .then((res) => {
            const data = res.data || [];
            dispatch({ type: AppStoreActions.notificationSubscription.Loaded, data: data});
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.notificationSubscription.Error, error: err });
            return Promise.reject(err);
        });
};

const saveNotificationSubscription = (orgKey, streamOrgKey, streamName, subscriptions) => {
    const dispatch = AppStore.dispatch;
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/subscriptions`;

    dispatch({ type: AppStoreActions.notificationSubscription.Loading });
    // request the stream data
    return api.put(url, subscriptions)
        .then((res) => {
            const data = res.data || [];
            dispatch({ type: AppStoreActions.notificationSubscription.Loaded, data: data});
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.notificationSubscription.Error, error: err });
            return Promise.reject(err);
        });
};
const sendTestNotification = (orgKey, streamOrgKey, streamName, type) => {
    const dispatch = AppStore.dispatch;
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/subscriptionTest/${type}`;

    dispatch({ type: AppStoreActions.notificationTest.Loading });
    // request the stream data
    return api.put(url, {})
        .then((res) => {
            const data = res.data || [];
            dispatch({ type: AppStoreActions.notificationTest.Loaded, data: data});
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.notificationTest.Error, error: err });
            return Promise.reject(err);
        });
};


const loadStreamFiles = (orgKey, streamOrgKey, streamName, size = 20, page = 0, sort = "lastUpdated,ASC") => {
    const dispatch = AppStore.dispatch;
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/files?page=${page}&size=${size}&sort=${sort}`;

    dispatch({ type: AppStoreActions.streamFiles.Loading });
    // request the stream data
    return api.get(url)
        .then((res) => {
            const data = res.data || {content: []};
            dispatch({ type: AppStoreActions.streamFiles.Loaded, data: data});
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.streamFiles.Error, error: err });
            return Promise.reject(err);
        });
};


const reprocessFile = (orgKey, streamOrgKey, streamName, fileId) => {
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/files/${fileId}/reprocess`;
    // request the stream data
    return api.put(url)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            return Promise.reject(err);
        });
};

const uploadFile = (orgKey, streamOrgKey, streamName, fileName, file, onProgress = (percent) => {}) => {
    const url = `${config.endpoints.apiRoot}/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/files`;
    // request the stream data
    const data = new FormData();
    data.append(fileName, file);
    return axios.post(
        url, 
        data, 
        {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (e) => {
                const { loaded, total } = e;
                let percent = Math.floor((loaded * 100) / total);
                onProgress(percent);
            }
        })
        .then((res) => {
            return Promise.resolve(res); // success!
        })
        .catch((err) => {
            return Promise.reject(err); // error!
        });
};

const loadStreamFileDetails = (orgKey, streamOrgKey, streamName, fileId) => {
    const dispatch = AppStore.dispatch;
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/files/${fileId}`;

    dispatch({ type: AppStoreActions.streamFileDetails.Loading });
    // request the stream data
    return api.get(url)
        .then((res) => {
            const data = res.data;
            dispatch({ type: AppStoreActions.streamFileDetails.Loaded, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.streamFileDetails.Error, error: err });
            return Promise.reject(err);
        });
};

const getStreamFileReportURL = (orgKey, streamOrgKey, streamName, fileId) => {
    const url = `/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/files/${fileId}/report`;
    if (config.mocks.useMocks) {
        const mock = require("./mocks").default;
        mock.buildMockURL("GET", url, "txt");
    }
    return url;
};

const getStreamFileResourceURL = (orgKey, streamOrgKey, streamName, resourceId, filename) => {
    return `${config.endpoints.apiRoot}/streams/${orgKey}/stream/${streamOrgKey}/${streamName}/resource/${resourceId}/${filename}`;
};

const loadStreamFileReport = (orgKey, streamOrgKey, streamName, fileId) => {
    const url = getStreamFileReportURL(orgKey, streamOrgKey, streamName, fileId);
    if(cache.hasKey(url)) {
        const data = cache.load(url);
        return Promise.resolve(data);
    }
    // request the customer report
    return api.get(url)
        .then((res) => {
            const data = res.data;
            cache.save(url, data);
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            // error!
            return Promise.reject(err);
        });
};

const loadImplementationDocs = (orgKey, impKey) => {
    const dispatch = AppStore.dispatch;
    dispatch({ type: AppStoreActions.documentation.Loading });
    // request the implementation data
    const docBaseURL = `/doc/${orgKey}/${impKey}`;
    return api.get(docBaseURL)
        .then((res) => {
            const data = res.data.map((doc) => ({
                url: config.endpoints.apiRoot + doc.relativeUrl,
                name: doc.title,
                linkType: doc.linkType
            }));
            dispatch({ type: AppStoreActions.documentation.Loaded, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => {
            // error!
            dispatch({ type: AppStoreActions.documentation.Error, error: err });
            return Promise.reject(err);
        });
};

const logoutUser = () => {
    // create form and submit post to logout
    var form = document.createElement("form");
    form.method = "POST";
    form.action = config.endpoints.logout;

    // Add the CSRF token
    var _csrf = document.createElement("input");
    _csrf.setAttribute("type", "hidden");
    _csrf.setAttribute("name", "_csrf");
    _csrf.setAttribute("value", cookie.get(config.session.CSRF_COOKIE_KEY));
    form.appendChild(_csrf);

    document.body.appendChild(form);
    form.submit();
    // cleanup DOM
    form.parentNode.removeChild(form);
    return Promise.resolve(true);
};

const apiMethods = {
    api,
    getStreamFileReportURL,
    getStreamFileResourceURL,
    loadStreamFileReport,
    loadImplementationDocs,
    loadUserData,
    loadStream,
    loadNotificationSubscription,
    saveNotificationSubscription,
    sendTestNotification,
    loadStreams,
    loadStreamFiles,
    loadStreamFileDetails,
    pingUserData,
    logoutUser,
    reprocessFile,
    uploadFile
};

export default apiMethods;
