import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";
import {Box, Tooltip} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import api from "../../lib/api";
import {withRouter} from "react-router-dom";
import {connectAppStore} from "../../data";
import {canSystemNotification} from "../../data/UserStore";
import {makeSxStyles, Button} from "@armus/armus-dashboard";



const types = [
    {
        type: "JOBSUCCESS",
        title: "Job Success",
        description: "Notification will occur when the main job related to the stream, such as an import succeeds."
    },
    {
        type: "JOBFAILURE",
        title: "Job Failure",
        description: "Notification will occur when the main job related to the stream, such as an import fails."
    }
];

const systemNotifications = [
    {
        type: "SYSTEMFAILURE",
        title: "System Failure",
        description: "Notification will occur when there is an error that requires customer support investigation."
    }
];

const useStyles = makeSxStyles((theme) => ({
    header: {
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        background: theme.palette.mode === 'light' ? "#f5f5f5" : "#424242",
        borderTop: "1px solid rgba(0,0,0,.15)",
        borderBottom: "1px solid rgba(0,0,0,.15)"
    },
    list: {
        "& .MuiListItem-root": {
            borderBottom: "1px solid rgba(0,0,0,.15)"
        }
    }
}));

const StreamNofications = (props) => {
    const {params} = props.match;
    const {email} = props.userState.data;
    const {notificationSubscription} = props;
    const classes = useStyles();
    let effectiveTypes = [...types];
    let enableTest = false;
    if(canSystemNotification(props.userState, params.orgKey, params.implKey)) {
        effectiveTypes = effectiveTypes.concat(systemNotifications);
        enableTest = true;
    }
    useEffect(() => { // didMount
            api.loadNotificationSubscription(params.orgKey, params.streamOrgKey, params.streamName);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params.orgKey, params.streamOrgKey, params.streamName]);
    const sendTestNotification = (descriptor) => {
        api.sendTestNotification(params.orgKey, params.streamOrgKey, params.streamName, descriptor.type);
    };
    const handleChange = (checked, type) => {
        let subs = [...notificationSubscription];
        if (checked) {
            if (subs.indexOf(type) === -1) {
                subs.push(type);
            }
        } else {
            if (subs.indexOf(type) !== -1) {
                subs.splice(subs.indexOf(type), 1);
            }
        }
        api.saveNotificationSubscription(params.orgKey, params.streamOrgKey, params.streamName, subs);
    };
    return (
        <Box>
            <Box sx={classes.header}>
                <Typography variant="h4" gutterBottom>
                    Notifications
                </Typography>
                <Typography color="text.secondary" variant="subtitle1">
                    Selections indicate which email notifications you would like to receive
                </Typography>
            </Box>

            <List dense={true} sx={classes.list}>
                {effectiveTypes.map((descriptor, index) =>
                    <ListItem key={descriptor.title}>
                        <FormControlLabel
                            control={<Switch checked={notificationSubscription.indexOf(descriptor.type) !== -1} onChange={(event) => handleChange(event.target.checked, descriptor.type)} name={descriptor.type} key={descriptor.type} value={descriptor.type} color="primary" />}
                            label={<Tooltip title={descriptor.description}><span>{descriptor.title}</span></Tooltip>} key={descriptor.type} aria-label={descriptor.description}
                        />
                        {enableTest && <Button variant="contained" color="default" onClick={() => sendTestNotification(descriptor)}>Send Test Notification</Button>}
                    </ListItem>
                )}
            </List>
            <Box sx={{padding: 2, paddingTop: 1}}>
                <Typography color="text.secondary" variant="subtitle1">
                    When events occur an email will be sent to your registered email address: {email}
                </Typography>
            </Box>

        </Box>
    );
};


export default withRouter(connectAppStore(([state, dispatch], props) => {
    const params = props.match.params || {};
    return {
        userState: state.user || {},
        streamsStatus: state.streams.status,
        stream: state.stream.data || {},
        notificationSubscription: state.notificationSubscription.data || [],
        params: params
    };
})(StreamNofications));
