import { alpha, Box, darken, lighten } from "@mui/material";
import React, {
    createContext,
    useContext,
} from "react";
import { useTheme } from "../providers/DashboardProvider";
import * as echarts from 'echarts';

const getInitialState = () => {
    return {
        theme: {},
        addChart: () => {},
        removeChart: () => {},
    };
};

const ChartThemeProviderContext = createContext(getInitialState());

const ChartThemeProvider = ({ children, ...props }) => {
    const allChartsRef = React.useRef([]);
    const valueRef = React.useRef({});
    const resizeObserverRef = React.useRef(new ResizeObserver(
        () => {
            allChartsRef.current.forEach((chart) => {
                chart.resize();
            });
        }
    ));
    const themeCtx = useTheme();
    const muiTheme = themeCtx.theme;
    
    const theme = buildChartTheme(muiTheme);
    const value = {
        theme,
        config: {
            renderer: 'canvas',
        }
    };
    valueRef.current = value;

    value.addChart = (el, options) => {
        let chart = allChartsRef.current.find(it => it._element === el);
        if(chart === undefined) {
            chart = echarts.init(el, valueRef.current.theme, valueRef.current.config);
            chart._element = el;
            allChartsRef.current.push(chart);
            chart._resizeObserverEl = el.parentElement;
            resizeObserverRef.current.observe(el.parentElement);
        }
        options && chart.setOption(options);
        return chart;
    };
    
    value.removeChart = (chart) => {
        if(allChartsRef.current.includes(chart)) {
            chart._resizeObserverEl && resizeObserverRef.current.unobserve(chart._resizeObserverEl);
            chart && chart.dispose();
            allChartsRef.current = allChartsRef.current.filter(it => it !== chart);
        }
    };

    React.useEffect(() => {
        const observe = resizeObserverRef.current;
        return () => {
            // make sure to remove all the charts on destroy
            allChartsRef.current.forEach(it => {
                valueRef.current.removeChart(it);
            });
            observe && observe.disconnect();
        };
    }, []);


    return (
        <ChartThemeProviderContext.Provider value={value}>
            {children}
        </ChartThemeProviderContext.Provider>
    );
};


export const useChartTheme = () => {
    return useContext(ChartThemeProviderContext);
};

export default ChartThemeProvider;


export const buildChartTheme = (
    muiTheme = {}
) => {
    const seriesColorPalette = [
        muiTheme.colors.blue,
        muiTheme.colors.purple,
        muiTheme.colors.green,
        muiTheme.colors.red,
        muiTheme.colors.orange,
        muiTheme.colors.brown,
        muiTheme.colors["light-green"],
        muiTheme.colors.azure,
        muiTheme.colors["ruby-red"],
        muiTheme.colors["yellow-orange"],
        muiTheme.colors["dark-green"],
        muiTheme.colors["red-orange"],
        muiTheme.colors.teal,
        muiTheme.colors["purple-gray"],
        muiTheme.colors.magenta
    ];
    
    // background colors
    const darkMode = muiTheme.palette.mode === 'dark';
    const backgroundColor = 'transparent';
    
    // text colors
    const defaultTextColor = muiTheme.palette.text.main;
    const subTextColor = alpha(muiTheme.palette.text.main, .75);
    const emphasisTextColor = muiTheme.palette.primary.main;
    
    // grid colors
    const gridMinorLineColor = alpha(muiTheme.palette.divider, 0.5);
    const gridMajorLineColor = muiTheme.palette.divider;

    // timeline colors
    const timelineLineColor = alpha(muiTheme.palette.text.main, .5);
    const timelineSelectOverlayColor = alpha(muiTheme.palette.primary.main, .15);
    const timelineEmphasisLineColor = alpha(muiTheme.palette.primary.main, 1);
    const timelineEmphasisAreaColor = alpha(muiTheme.palette.primary.main, 1);
    const timelineEmphasisOverlayColor = alpha(muiTheme.palette.primary.main, 0.1);
    const timelineHandleColor = darkMode ? darken(muiTheme.palette.text.main, .5) : lighten(muiTheme.palette.text.main, .5);
    const timelineEmphasisHandleColor = muiTheme.palette.primary.main;
    
    // extra colors
    const bearishColor = muiTheme.palette.red.main;
    const bullishColor = muiTheme.palette.green.main;
    const visualMapColors = [muiTheme.palette.primary.main, lighten(muiTheme.palette.primary.main, .9)]; // small to big

    const axisCommon = function () {
        return {
            axisLine: {
                lineStyle: {
                    color: gridMinorLineColor
                }
            },
            splitLine: {
                lineStyle: {
                    color: gridMajorLineColor
                }
            },
            splitArea: {
                areaStyle: {
                    color: [gridMajorLineColor, gridMinorLineColor]
                }
            },
            minorSplitLine: {
                lineStyle: {
                    color: gridMinorLineColor
                }
            }
        };
    };

    const theme = {
        darkMode,
        color: seriesColorPalette,
        backgroundColor: backgroundColor,
        axisPointer: {
            lineStyle: {
                color: gridMajorLineColor
            },
            crossStyle: {
                color: gridMajorLineColor
            },
            label: {
                color: gridMajorLineColor
            }
        },
        legend: {
            textStyle: {
                color: defaultTextColor
            }
        },
        textStyle: {
            color: subTextColor
        },
        title: {
            textStyle: {
                color: defaultTextColor
            },
            subtextStyle: {
                color: subTextColor
            }
        },
        toolbox: {
            iconStyle: {
                borderColor: subTextColor
            },
            emphasis: {
                iconStyle: {
                    textFill: emphasisTextColor,
                }
            }
        },
        dataZoom: {
            borderColor: timelineLineColor,
            textStyle: {
                color: defaultTextColor
            },
            brushStyle: {
                color: timelineSelectOverlayColor
            },
            handleStyle: {
                color: timelineHandleColor,
                borderColor: timelineHandleColor
            },
            moveHandleStyle: {
                color: timelineEmphasisHandleColor,
                opacity: 0.3
            },
            fillerColor: timelineEmphasisOverlayColor,
            emphasis: {
                handleStyle: {
                    borderColor: timelineEmphasisHandleColor,
                    color: timelineEmphasisHandleColor
                },
                moveHandleStyle: {
                    color: timelineEmphasisHandleColor,
                    opacity: 0.7
                }
            },
            dataBackground: {
                lineStyle: {
                    color: timelineLineColor,
                    width: 1
                },
                areaStyle: {
                    color: timelineLineColor
                }
            },
            selectedDataBackground: {
                lineStyle: {
                    color: timelineEmphasisLineColor
                },
                areaStyle: {
                    color: timelineEmphasisAreaColor
                }
            }
        },
        visualMap: {
            textStyle: {
                color: defaultTextColor
            },
            inRange : {   
                color: visualMapColors 
            }
        },
        timeline: {
            lineStyle: {
                color: defaultTextColor
            },
            label: {
                color: defaultTextColor
            },
            controlStyle: {
                color: defaultTextColor,
                borderColor: defaultTextColor
            }
        },
        calendar: {
            itemStyle: {
                color: backgroundColor
            },
            dayLabel: {
                color: defaultTextColor
            },
            monthLabel: {
                color: defaultTextColor
            },
            yearLabel: {
                color: defaultTextColor
            }
        },
        timeAxis: axisCommon(),
        logAxis: axisCommon(),
        valueAxis: axisCommon(),
        categoryAxis: axisCommon(),

        line: {
            symbol: 'circle'
        },
        graph: {
            color: seriesColorPalette
        },
        gauge: {
            title: {
                color: defaultTextColor
            }
        },
        candlestick: {
            itemStyle: {
                color: bearishColor,
                color0: bullishColor,
                borderColor: bearishColor,
                borderColor0: bullishColor
            }
        }
    };

    theme.categoryAxis.splitLine.show = false;
    return theme;
};


export const Chart = ({options, onClick}) => {
    const { addChart, removeChart } = useChartTheme();
    const chartRef = React.useRef(null);
    const chartContainerRef = React.useRef(null);
    const optionsRef = React.useRef();
    optionsRef.current = options;
    React.useEffect(() => {
        // create
        chartRef.current = addChart(chartContainerRef.current);
        return () => {
            // destroy
            removeChart(chartRef.current);
        };
    }, [addChart, removeChart]);

    React.useEffect(() => {
        // update
        chartRef.current.clear();
        chartRef.current.setOption(options);
        onClick && chartRef.current.on('click', onClick);
    }, [options, onClick]);



    return (
        <Box ref={chartContainerRef} height={"100%"} wisth={"100%"} />
    );
};