import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import App from "./app/App";
import './index.scss';
import { createRoot } from 'react-dom/client';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<App />);
