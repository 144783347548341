import React from 'react';
import {Notifications as NotificationsView, useNotifications} from "@armus/armus-dashboard";

const Notifications = (props) => {
    const {queue, onCleanup, onHide} = useNotifications();
    return (
        <NotificationsView
            {...props}
            queue={queue}
            onCleanupNotifications={() => {
                onCleanup();
            }}
            onHideNotification={(index) => {
                onHide(index);
            }}
        />
    );
};

Notifications.propTypes = {
    ...Notifications.propTypes
};

export default Notifications;
