/*
    JSONextract
    Stripped down version of xpath to extract data from a json object that is
    null safe.

    path examples
    data/name
    data/items[0]/name
    data/items[@name="joe"]
 */

const jsonPath = (json, path) => {
    const parts = path.replace(/\[/g, "/[").split("/");
    let current = json;
    let i = 0;
    for(; i < parts.length; i++) {
        let part = parts[i];
        if(current == null) {
            return null;
        }
        if(current.hasOwnProperty(part) && !Array.isArray(current)) {
            // current = object and part is valid key
            current = current[part];
        }
        else if(part.charAt(0) === "[" && Array.isArray(current)) {
            // current = array
            part = part.replace(/\[|\]/g, '');
            if(part.charAt(0) === "@") { // find
                const [key, value] = part.replace("@", "").split("=");
                current = current.find((it) => it[key] === JSON.parse(value)) || null;
            }
            else { // index
                const index = parseInt(part, 10);
                if(!isNaN(index) && index < current.length) {
                    current = current[index];
                }
                else {
                    current = null;
                }
            }
        }
        else {
            // the path item does not exist
            current = null;
        }
    }
    if(i === parts.length) {
        // made it to the end of the path. return current.
        return current;
    }
    return null;
};

export default jsonPath;