(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material"), require("@mui/material/AppBar"), require("@mui/material/Icon"), require("@mui/material/TextField"), require("@mui/material/internal/svg-icons/ErrorOutline"), require("@mui/material/internal/svg-icons/InfoOutlined"), require("@mui/material/internal/svg-icons/ReportProblemOutlined"), require("@mui/material/internal/svg-icons/SuccessOutlined"), require("@mui/material/styles"), require("@mui/system"), require("@mui/x-date-pickers"), require("@mui/x-date-pickers/AdapterMoment"), require("react"), require("react-dom"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material", "@mui/material/AppBar", "@mui/material/Icon", "@mui/material/TextField", "@mui/material/internal/svg-icons/ErrorOutline", "@mui/material/internal/svg-icons/InfoOutlined", "@mui/material/internal/svg-icons/ReportProblemOutlined", "@mui/material/internal/svg-icons/SuccessOutlined", "@mui/material/styles", "@mui/system", "@mui/x-date-pickers", "@mui/x-date-pickers/AdapterMoment", "React", "ReactDOM", "ReactRouterDOM"], factory);
	else if(typeof exports === 'object')
		exports["ArmusDashboard"] = factory(require("@mui/material"), require("@mui/material/AppBar"), require("@mui/material/Icon"), require("@mui/material/TextField"), require("@mui/material/internal/svg-icons/ErrorOutline"), require("@mui/material/internal/svg-icons/InfoOutlined"), require("@mui/material/internal/svg-icons/ReportProblemOutlined"), require("@mui/material/internal/svg-icons/SuccessOutlined"), require("@mui/material/styles"), require("@mui/system"), require("@mui/x-date-pickers"), require("@mui/x-date-pickers/AdapterMoment"), require("react"), require("react-dom"), require("react-router-dom"));
	else
		root["ArmusDashboard"] = factory(root["@mui/material"], root["@mui/material/AppBar"], root["@mui/material/Icon"], root["@mui/material/TextField"], root["@mui/material/internal/svg-icons/ErrorOutline"], root["@mui/material/internal/svg-icons/InfoOutlined"], root["@mui/material/internal/svg-icons/ReportProblemOutlined"], root["@mui/material/internal/svg-icons/SuccessOutlined"], root["@mui/material/styles"], root["@mui/system"], root["@mui/x-date-pickers"], root["@mui/x-date-pickers/AdapterMoment"], root["React"], root["ReactDOM"], root["ReactRouterDOM"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__901__, __WEBPACK_EXTERNAL_MODULE__886__, __WEBPACK_EXTERNAL_MODULE__272__, __WEBPACK_EXTERNAL_MODULE__290__, __WEBPACK_EXTERNAL_MODULE__209__, __WEBPACK_EXTERNAL_MODULE__589__, __WEBPACK_EXTERNAL_MODULE__748__, __WEBPACK_EXTERNAL_MODULE__468__, __WEBPACK_EXTERNAL_MODULE__655__, __WEBPACK_EXTERNAL_MODULE__719__, __WEBPACK_EXTERNAL_MODULE__640__, __WEBPACK_EXTERNAL_MODULE__581__, __WEBPACK_EXTERNAL_MODULE__639__, __WEBPACK_EXTERNAL_MODULE__346__, __WEBPACK_EXTERNAL_MODULE__620__) {
return 