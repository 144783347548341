import React from 'react';
import { Box } from '@mui/material';
import makeSxStyles from '../../../lib/makeSxStyles';


const useStyles = makeSxStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
    },
    label: {
        marginTop: theme.spacing(.5),
        textAlign: "center",
        height: theme.typography.fontSize * 2.5,
        lineHeight: "14px",
    },
    value: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "4px solid " + theme.palette.divider,
        borderRadius: theme.spacing(2),
        height: 50,
        fontSize: 25,
        verticalAlign: "middle",
        textAlign: "center"
    }
}));

const BoxGauge = ({
    color,
    formattedValue,
    value,
    label,
    onClick
}) => {
    const classes = useStyles();
    return (
        <Box sx={classes.root} onClick={(e) => onClick(e, {value, label, color})}>
            <Box sx={classes.value} style={{borderColor: color, color: color,}}>{formattedValue}</Box>
            <Box sx={classes.label}>{label}</Box>
        </Box>
    );
};

export default BoxGauge;