import React from "react";
import {makeSxStyles, SmartLink} from "@armus/armus-dashboard";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import api from "../../lib/api";
import {withRouter} from "react-router-dom";
import {connectAppStore} from "../../data";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar/Avatar";
import ArchiveIcon from "@mui/icons-material/Archive";
import {Box} from "@mui/material";

const useStyles = makeSxStyles((theme) => ({
    header: {
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        background: theme.palette.mode === 'light' ? "#f5f5f5" : "#424242",
        borderTop: "1px solid rgba(0,0,0,.15)",
        borderBottom: "1px solid rgba(0,0,0,.15)"
    },
    content: {
        padding: 0,
        "& .MuiListItem-root": {
            borderBottom: "1px solid rgba(0,0,0,.15)"
        }
    }
}));

const StreamResources = (props) => {
    const {stream, params} = props;
    const classes = useStyles();
    return (
        <Box>
            <Box sx={classes.header}>
                <Typography variant="h4" gutterBottom>
                    Resources
                </Typography>
                <Typography color="text.secondary" variant="subtitle1">
                    Downloadable resource documentation related to the stream
                </Typography>
            </Box>
            <Box sx={classes.content}>
                <List dense={false}>
                    {
                        stream.resourceReferences && stream.resourceReferences.map((value) =>
                        <ListItem>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                component={SmartLink}
                                to={api.getStreamFileResourceURL(params.orgKey, params.streamOrgKey, params.streamName, value.resource.id, value.resource.filename)}
                                spacing={2}
                            >
                                <Grid item>
                                    <Avatar><ArchiveIcon/></Avatar>
                                </Grid>
                                <Grid item>
                                    <strong>{value.resource.title}</strong>
                                </Grid>
                                <Grid item>
                                    {value.resource.filename}
                                </Grid>
                            </Grid>
                        </ListItem>
                        )
                    }
                </List>
            </Box>
        </Box>
    );

};



export default withRouter(connectAppStore(([state, dispatch], props) => {
    const params = props.match.params || {};
    return {
        userState: state.user,
        streamsStatus: state.streams.status,
        stream: state.stream.data || {},
        params: params
    };
})(StreamResources));
