import React from "react";
import PropTypes from "prop-types";
import {
    Fab,
    Zoom,
    Icon,
    Box,
    useScrollTrigger,
} from '@mui/material';
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles(theme => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

export const scrollToTop = () => {
    const anchor = document.querySelector('#top');
    if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

const ScrollTop = ({className, target, threshold = 100, disableHysteresis = true, ...extraProps}) => {
    const classes = useStyles();
    const trigger = useScrollTrigger({
        target,
        disableHysteresis,
        threshold,
    });

    return (
        <Zoom in={trigger} style={{zIndex: 10}}>
            <Box onClick={scrollToTop} role="presentation" className={className} sx={classes.root}>
                <Fab color="primary" size="small" aria-label="scroll back to top" {...extraProps}>
                    <Icon>keyboard_arrow_up_icon</Icon>
                </Fab>
            </Box>
        </Zoom>
    );
};

ScrollTop.propTypes = {
    target: PropTypes.element,
    disableHysteresis: PropTypes.bool,
    threshold: PropTypes.number,
};

export default ScrollTop;