import React from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Icon,
} from "@mui/material";
import PropTypes from "prop-types";
import { buildDescriptorPropTypes } from "../../../lib/propTypeHelpers";
import DescriptorResolver from "./DescriptorResolver";
import DescriptorErrorContent from "./DescriptorErrorContent";
import TitleBar from "../../common/TitleBar";
import Button from "../../controls/Button";
import makeSxStyles from "../../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
  paper: {
    //minHeight: theme.spacing(7)+4
  },
  label: {
    paddingTop: 0,
  },
  expandButton: {
    marginTop: theme.spacing(-1),
    float: "right",
  },
}));

const TabResolver = ({ descriptor, ...extraProps }) => {
  const classes = useStyles();
  const items = descriptor.items || [];
  const config = descriptor.config || {};
  const [collapse, setCollapse] = React.useState(
    config.expanded !== undefined ? !config.expanded : false
  );
  const lastExpanded = React.useRef(config.expanded);
  React.useEffect(() => {
    if (lastExpanded.current !== config.expanded) {
      lastExpanded.current = config.expanded;
      setCollapse(config.expanded !== undefined ? !config.expanded : false);
    }
  }, [config.expanded]);
  const children =
    items.length > 0 ? (
      <Grid container spacing={1} style={{ flexGrow: 1 }}>
        {items.map((d, i) => (
          <DescriptorResolver
            key={`${d.id} ${i}`}
            descriptor={d}
            {...extraProps}
          />
        ))}
      </Grid>
    ) : undefined;
  const hasLabel = !!descriptor.label;
  const hasDescription = !!descriptor.description;
  const expandable =
    config.expandable !== undefined
      ? config.expandable
      : hasDescription || items.length;
  switch (descriptor.type) {
    case "section":
      // elevation
      const Container = extraProps.isElevated ? Box : Paper;
      return (
        <Container sx={classes.paper}>
          <Box
            p={2}
            paddingTop={config.dense ? 1.35 : 2}
            paddingBottom={config.dense ? 1.35 : 2}
          >
            {expandable && (
              <Button
                variant={"iconButton"}
                color="secondary"
                onClick={() => {
                  setCollapse(!collapse);
                }}
                sx={classes.expandButton}
              >
                <Icon>
                  {collapse ? "keyboard_arrow_down" : "keyboard_arrow_up"}
                </Icon>
              </Button>
            )}
            {hasLabel && (
              <Typography
                sx={classes.label}
                variant={"h6"}
                component={"h6"}
              >
                {descriptor.label}
              </Typography>
            )}
            {hasDescription && !collapse && (
              <Box paddingBottom={!!children ? 1 : 0}>
                <Typography>{descriptor.description}</Typography>
              </Box>
            )}
            {!collapse && children}
          </Box>
        </Container>
      );
    case "header":
      return (
        <TitleBar
          key={descriptor.id}
          title={descriptor.label}
          description={descriptor.description}
          dense={config.dense !== undefined ? config.dense : true}
          isElevated={extraProps.isElevated}
          disabled={extraProps.disabled}
          expanded={!collapse}
          expandable={expandable}
        >
          {children}
        </TitleBar>
      );
    default:
      return <DescriptorErrorContent descriptor={descriptor} />;
  }
};

TabResolver.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(buildDescriptorPropTypes()))
    .isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default TabResolver;
