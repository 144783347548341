let dataCache = {};

const generateCacheKey = (data) => {
    // creates a deep serialized string from the provided data to be used as a
    // cache key.
    return JSON.stringify(data);
};

const hasKey = (key) => {
    return dataCache.hasOwnProperty(key);
};

const load = (key) => {
    return hasKey(key) ? dataCache[key] : undefined;
};

const save = (key, data) => {
    dataCache[key] = data;
};
const remove = (key) => {
    if(dataCache[key]) {
        delete dataCache[key];
    }
};

const reset = () => {
    dataCache = {};
};

const cacheAPI = {
    generateCacheKey,
    hasKey,
    load,
    save,
    remove,
    reset,
};
export default cacheAPI;