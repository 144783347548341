import React from "react";
import ControlResolver from "./ControlResolver";
import {
    buildPropTypesFromObject,
    buildPropTypesWithDescriptor
} from "../../lib/propTypeHelpers";
import {
    Grid,
    Icon
} from "@mui/material";
import Button from "./Button";
import Outline from "./components/Outline";

const UNIQUE_KEY = "_key";

const makeUniqueID = (id = "", i = 0) => {
    return id+"_"+i;
};

const Group = ({value, ...props}) => {
    // TODO: this whole control need to be rethought.
    // keeping for talking points but this should not be used.
    const {isLoading, descriptor, onChange, depth = 0} = props;
    const cfg = defaultConfig(descriptor.config);
    const initialValues = {};
    descriptor.items.forEach((d) => {
        initialValues[d.id] = value !== undefined && value !== null ? value[d.id] : d.config.defaultValue || null;
    });
    const initialState = cfg.multivalued ? [] : initialValues;
    if(cfg.multivalued) {
        for (let i = 0; i < cfg.min; i++) {
            initialState.push({
                ...initialValues,
                [UNIQUE_KEY]: makeUniqueID(descriptor.id, i)
            });
        }
    }
    const stateRef = React.useRef();
    const [state, setState] = React.useState(initialState);
    stateRef.current = [state, setState];

    React.useEffect(() => {
        setState(initialState);
    // eslint-disable-next-line
    }, [cfg.multivalued]);


    const handleChange = (index) => (e, v, d) => {
        let newState;
        if(cfg.multivalued) {
            newState = [...state];
            newState[index] = {...newState[index], [d.id]: v};
        }
        else {
            newState = {...state, [d.id]: v};
        }
        setState(newState);
        onChange && onChange(e, newState,  descriptor);
    };

    const handleAddClick = (i) => (e) => {
        if(cfg.multivalued) {
            const newState = [...state, {...initialValues, [UNIQUE_KEY]: makeUniqueID(descriptor.id, i+1)}];
            setState(newState);
            onChange && onChange(e, newState,  descriptor);
        }
    };
    const handleRemoveClick = (index) => (e) => {
        if(cfg.multivalued) {
            const newState = [...state];
            newState.splice(index, 1);
            setState(newState);
            onChange && onChange(e, newState,  descriptor);
        }
    };

    let items;
    if(cfg.multivalued) {
        items = (Array.isArray(state) ? state : initialState);
        items = items.map((v, i) => {
            return (
                <Grid className={"row"} key={v[UNIQUE_KEY]} container spacing={1} style={{flexGrow: 1}}>
                    {descriptor.items.map((d) => {
                        d = {...d, config: {...d.config}};
                        d.config.dense = cfg.dense || d.config.dense;
                        return (
                            <Grid key={d.id} item xs={12} sm={d.config.fullWidth ? 12 : d.config.gridWidth || 2}>
                                <ControlResolver {...props} depth={depth+1} descriptor={d} value={v} onChange={handleChange(i)} />
                            </Grid>
                        );
                    })}
                    <Grid item xs={2}>
                        {(items.length < cfg.max && i === items.length-1) && <Button variant={"iconButton"} disabled={isLoading} color="primary" onClick={handleAddClick(i)}><Icon>add</Icon></Button>}
                        {items.length > cfg.min && <Button variant={"iconButton"} disabled={isLoading} color="warning" onClick={handleRemoveClick(i)}><Icon>close</Icon></Button>}
                    </Grid>
                </Grid>
            );
        });
    }
    else {
        items = (
            <Grid container spacing={1} style={{flexGrow: 1}}>
                {descriptor.items.map((d) => {
                    d = {...d, config: {...d.config}};
                    d.config.dense = cfg.dense || d.config.dense;
                    return (
                        <Grid key={d.id} item xs={12} sm={d.config.fullWidth ? 12 : d.config.gridWidth || 3}>
                            <ControlResolver {...props} descriptor={d} value={(value || {})[d.id] || {}} onChange={handleChange()} />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    return (
        <Outline label={descriptor.label} fullWidth={true}>
            {items}
        </Outline>
    );
};

export const defaultConfig = (config = {}) => ({
    dense: false,
    multivalued: false,
    min: 1,
    max: Number.MAX_SAFE_INTEGER,
    ...config
});

Group.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));
export default Group;