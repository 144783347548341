import React, { Component } from 'react';
import { scaleBand } from 'd3-scale';
import { Axis, axisPropsFromTickScale, axisPropsFromBandedScale, LEFT, BOTTOM } from 'react-d3-axis';
import Group from './Group';
import Box from './Box';
import ErrorBar from './ErrorBar';
import Title from './Title';

export default class SubPlot extends Component {

  render() {
    // calculates axis lengths
    const xAxisWidth = this.props.width - this.props.margin.left - this.props.margin.right;
    const yAxisHeight = this.props.height - this.props.margin.top-this.props.margin.bottom;

    // creates y scale functions, used to draw the y axis
    const yScale = this.props.yScale;

    // gets the titles of the groups in the plot, makes a scale function used for the x axis
    const groupTitles = this.props.groupNames;
    const xScale = scaleBand().domain(groupTitles).range([0, xAxisWidth]);

    const group = this.props.data;
    const numBarsInGroup = groupTitles.length;
    const barsFillWidth = 0.8;
    const barWidth = (xAxisWidth * barsFillWidth) / numBarsInGroup;

    let bars = [];
    let counts = [];

    group.value.forEach( (bar, i) => {
      if (this.props.addCounts) {
        counts.push(
          <text
            key={i}
            x={this.props.margin.left + xScale(groupTitles[i]) + xScale.bandwidth() / 2}
            y={yAxisHeight + 25}
            textAnchor={"middle"}
            fontSize={this.props.style.countFontSize}
            fontFamily={this.props.style.fontFamily}
            fill={this.props.style.countColor}
          >
            {bar.value.n}
          </text>
        );
      }

      if (bar.value.error) {
        bars.push(
          <ErrorBar
            key={i}
            x={this.props.margin.left}
            y={this.props.margin.top}
            width={barWidth}
            xScale={xScale}
            yScale={yScale}
            title={groupTitles[i]}
            style={this.props.style}
          />);
      }

      else {
        bars.push(
          <Box
            key={i}
            index={i}
            data={bar}
            x={this.props.margin.left}
            y={this.props.margin.top}
            width={barWidth}
            xScale={xScale}
            yScale={yScale}
            title={groupTitles[i]}
            addPoints={this.props.addPoints}
            style={this.props.style}
            drawOnlyOutliers={this.props.drawOnlyOutliers}
            colors={{ bars: this.props.colorScheme.bars[i] || this.props.colorScheme.bars, other: this.props.colorScheme.other[i]}}
          />
        );
      }
    });

    let gridlines = [];
    if (this.props.addGrid) {
      let ticks = yScale.ticks(yAxisHeight/50);
      ticks.pop();
      ticks.forEach((tick, i) => {
        gridlines.push(
          <line
            key={i}
            x1={this.props.margin.left}
            x2={this.props.margin.left + xAxisWidth}
            y1={Math.ceil(yScale(tick) + this.props.margin.top)}
            y2={Math.ceil(yScale(tick) + this.props.margin.top)}
            strokeWidth={0.5}
            stroke={this.props.style.gridColor}
          />
        );
      });
    }

    const yAxis = this.props.plotNum !== 0 ? "" : (
      <g style={{display: 'block'}}>
      <Group x={this.props.margin.left} y={this.props.margin.top}>
        <Axis
          {...axisPropsFromTickScale(yScale, yAxisHeight/50)}
          style={{ orient: LEFT, tickSizeOuter: 0, strokeColor: this.props.style.yAxisColor, strokeWidth: 1 }}
        />
      </Group>
      </g>
    );

    return (
      <Group x={this.props.plotNum * this.props.width + (this.props.plotNum !== 0 ? this.props.yAxisWidth : 0)} y={0} >
        <Title
          x={this.props.margin.left}
          y={yAxisHeight+45}
          width={this.props.width}
          xOffset={xScale.range()[1] / 2}
          yOffset={0}
          style={this.props.style}
        >
          {group.label}
        </Title>
        {gridlines}
        {yAxis}
        <Group x={this.props.margin.left} y={this.props.height - this.props.margin.bottom} >
          <Axis
            {...axisPropsFromBandedScale(xScale)}
            style={{ orient: BOTTOM, tickSizeOuter: 0, tickFontSize: this.props.style.groupFontSize, strokeColor: this.props.style.xAxisColor }}
          />
        </Group>
        {bars}
        {counts}
      </Group>
    );
  }
}
