import {
    buildAsyncReducer,
    buildAsyncActions,
    asyncInitialState
} from "./utils/asyncReducer";

const initialState = {
    ...asyncInitialState,
    data: {
        content: []
    }
};
export const streamFilesActions = buildAsyncActions("streamFiles");
export const streamFilesReducer = buildAsyncReducer(streamFilesActions, initialState);
