import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Main, EnhancedTable, SmartLink, Paper, makeSxStyles } from "@armus/armus-dashboard";
import api from "../../lib/api";
import {connectAppStore} from "../../data";
import {getOrganization, getOrganizationName} from "../../data/UserStore";
import Grid from "@mui/material/Grid/Grid";
import Avatar from "@mui/material/Avatar/Avatar";
import StorageIcon from "@mui/icons-material/Storage";
import Chip from '@mui/material/Chip';
import ErrorPage from "../ErrorPage";
import { Box } from "@mui/material";

const useStyles = makeSxStyles((theme) => ({
    title: {
        padding: 2,
        paddingBottom: 1,
        paddingTop: 1,
        borderBottom: "2px solid rgba(0,0,0,.15)"
    },
    header: {
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        background: theme.palette.mode === 'light' ? "#f5f5f5" : "#424242",
        borderBottom: "1px solid rgba(0,0,0,.15)"
    }
}));

export const streamsBreadcrumb = (organization = {}) => ({
        label: (organization.name || organization.title) + " Streams",
        url: `/organization/${organization.key}/streams`
    });

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

const recentToolTipMessage = "Recent activity over the Past 30 days.";

const buildStreamColumns = (organization, getOrgName) => {
    return [
        { id: 'shortname', isSortable: true, disablePadding: false, label: 'Stream', props: {align: "left"}, content: ({row}) =>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    component={SmartLink}
                    to={`/organization/${organization.key}/streams/${row.organization || organization.key}/${row.shortname}/`}
                    spacing={1}
                >
                    <Grid item>
                        <Avatar><StorageIcon/></Avatar>
                    </Grid>
                    <Grid item>
                        <strong>{row.shortname}</strong>
                    </Grid>
                </Grid>
        },
        { id: 'organization', isSortable: true, disablePadding: false, label: "Organization", props: {align: "left"}, content: ({row}) => getOrgName(row.organization) },
        { id: 'paused', isSortable: true, disablePadding: false, label: 'Status', props: {align: "center"}, content: ({row}) => (<Chip
                size="small"
                label={row.paused ? "Paused" : "Running"}
                disabled={row.paused}
                color={row.paused ? "default" : "primary"}
            />)},
        { id: 'direction', isSortable: true, disablePadding: false, label: 'Direction', props: {align: "center"}, content: ({row}) => (<Chip
                size="small"
                label={row.direction === "INBOUND" ? "Inbound" : "Outbound"}
                variant={"outlined"}
                color={"default"}
            />)},
        { id: 'recentSuccess', isSortable: true, disablePadding: false, label: "Recent Success", props: {align: "right", title: recentToolTipMessage}, content: ({row}) => {
                let p = Math.round(row.recentSuccess/row.recentTotal * 100);
                if (isNaN(p)) {
                    p = 0;
                }
                return <span style={{color:"#183"}}>{row.recentSuccess} ({p}%)</span>;
            } },
        { id: 'recentFailed', isSortable: true, disablePadding: false, label: "Recent Failure", props: {align: "right", title: recentToolTipMessage}, content: ({row}) => {
                let p = Math.round(row.recentFailed/row.recentTotal * 100);
                if (isNaN(p)) {
                    p = 0;
                }
                return <span style={{color: p > 0 ? "#a13" : "inherit"}}>{row.recentFailed} ({p}%)</span>;
            } },
        { id: 'other', isSortable: false, disablePadding: false, label: "Recent Other", props: {align: "right", title: recentToolTipMessage}, content: ({row}) => {
                const inProgress = row.recentTotal - row.recentSuccess - row.recentFailed;
                let p = Math.round(inProgress/row.recentTotal * 100);
                if (isNaN(p)) {
                    p = 0;
                }
                return `${inProgress} (${p}%)`;
            } },
        { id: 'recentTotal', isSortable: true, disablePadding: false, label: "Recent Total", props: {align: "right", title: recentToolTipMessage} }
    ];
};

const Streams = (props) => {
    const {streams, streamsStatus, organization, getOrgName} = props;
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1000);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState("");

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (e, row) => {
        //console.log(e, row);
    };

    const params = props.match.params || {};
    useEffect(() => { // didMount
        api.loadStreams(params.orgKey);
    }, [params.orgKey]);

    if(!organization) {
        return (
            <ErrorPage statusCode={404} />
        );
    }

    const columns = buildStreamColumns(organization, getOrgName);
    const streamsOrdered = streamsStatus === "ERROR"
        ? []
        : stableSort(orderBy
            ? stableSort(streams, getSorting(order, orderBy))
            : [...streams], getSorting(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Main breadcrumbs={[streamsBreadcrumb(organization)]} fullWidth={true}>
            <Box sx={{padding: 4}}>
                <Paper>
                    <Typography sx={classes.title} component="h1" variant={"h4"}>
                        Streams
                    </Typography>
                    <Box sx={classes.header}>

                    <Typography variant="h4" gutterBottom>
                        Available Streams
                    </Typography>
                    <Typography color="text.secondary" variant="subtitle1">
                        Please select a stream to view its files.
                    </Typography>
                    </Box>
                    <EnhancedTable
                        columns={columns}
                        rows={streamsOrdered}
                        isLoading={streamsStatus === "LOADING"}
                        page={page}
                        order={order}
                        orderBy={orderBy}
                        total={streams.length}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[1000]}
                        onRequestSort={handleRequestSort}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        onChangePage={handleChangePage}
                        onRowClick={handleRowClick}
                        emptyListMessage={streamsStatus === "ERROR" ? "Something bad happened, Please try again in a few moments." : "No items available."}
                    />
                </Paper>
            </Box>
        </Main>
    );
};

export default withRouter(connectAppStore(([state, dispatch], props) => {
    const params = props.match.params || {};
    return {
        streamsStatus: state.streams.status,
        streams: state.streams.data || [],
        organization: getOrganization(state.user, params.orgKey),
        getOrgName: (orgKey) => (getOrganizationName(state.user, orgKey))
    };
})(Streams));
