import React, {
    createContext,
    useContext,
    useState,
    useRef,
} from "react";
import Dialog from "../common/Dialog";

const getInitialState = () => {
    return {
        queue: [],
    };
};

const DialogsContext = createContext(getInitialState());

const DialogsProvider = ({children, ...props}) => {
    const [state, setState] = useState(getInitialState());
    const stateRef = useRef();
    const setStateRef = useRef();
    stateRef.current = state;
    setStateRef.current = setState;
    const getQueue = () => [...stateRef.current.queue];
    const setQueue =  (queue) => {
        const newState = {...state, queue};
        stateRef.current = newState;
        setStateRef.current(newState);
    };

    const onShow = ({title, content, actions, children}) => {
        const queue = getQueue();
        queue.push({
            title,
            content,
            actions,
            children,
        });
        setQueue(queue);
    };
    const onHide = () => {
        const queue = getQueue();
        if(queue.length) {
            queue.pop();
        }
        setQueue(queue);
    };
    const onReset = () => {
        setQueue(getInitialState().queue);
    };

    const value = {
        getQueue,
        setQueue,
        onShow,
        onHide,
        onReset,
    };

    return (
        <DialogsContext.Provider value={value}>
            <Dialog
                queue={getQueue()}
                onHideDialog={onHide}
            />
            {children}
        </DialogsContext.Provider>
    );
};


export const useDialogs = () => {
    const {getQueue, setQueue, onShow, onHide, onReset} = useContext(DialogsContext);
    return {
        queue: getQueue(),
        setQueue,
        onShow,
        onHide,
        onReset,
    };
};

export default DialogsProvider;