import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Box } from "@mui/material";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        "&.withOverlay": {
            backgroundColor: theme.palette.mode === "light" ? "rgba(255,255,255,.5)" : "rgba(51,51,51,.5)",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
        }
    },
    container: {
        position: "relative",
    }
}));

const Spinner = ({color = "primary",  className = "", overlay = true, size = 40,  ...extraProps}) => {
    const classes = useStyles();
    return (
        <Box {...extraProps} className={className + (overlay ? " withOverlay" : "")} sx={classes.root}>
            <Box sx={classes.container}>
                <CircularProgress
                    sx={{
                        position: "absolute",
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }
                    }
                    {...extraProps}
                    variant="determinate"
                    color={color}
                    size={size}
                    value={100}
                />
                <CircularProgress
                    {...extraProps}
                    color={color}
                    size={size}
                />
            </Box>
        </Box>
    );
};

Spinner.defaultProps = {
    color: "primary-alt",
    overlay: true,
    size: 60,
    thickness: 2,
    className: ""
};

Spinner.propTypes = {
    ...CircularProgress.propTypes,
    overlay: PropTypes.bool,
    size: PropTypes.number,
    thickness: PropTypes.number,
    color: PropTypes.oneOf(['primary', 'primary-alt', 'secondary', 'success', 'info', 'warning', 'error']),
};

export default Spinner;