import numberFormatter from "number-formatter";

export const isNumber = (value) => {
    if(typeof value === "string" && value.replace(/\d/g, "").length > 2) {
        return false;
    }
    return !isNaN(parseFloat(value));
};


export const formatValue = (format, v, nanText = "-") => {
    if(isNaN(v) || v == null || v === "") {
        return nanText;
    }
    switch(format) {
        case "percent":
            return numberFormatter("#,##0.#%", v * 100);
        case "currency":
            return numberFormatter("$#,##0.00", v);
        default:
            return numberFormatter(format || "#,##0.##", v);
    }
};

export const formatTickValue = (format, min, max, v) => {
    const isPercent = format === "percent";
    const threshold = isPercent ? .02 : 2;
    if(max-min > threshold) {
        return formatValue(format, isPercent ? Math.round(v*100)/100: Math.round(v));
    }
    return formatValue(format, v);
};