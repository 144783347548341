import React from "react";

const ArmusProductIcon = ({height = 100, width = 200}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 200 200">
            <defs>
                <clipPath id="clip-path">
                <circle id="Background" cx="100" cy="100" r="100" fill="#00aeff"/>
                </clipPath>
                <linearGradient id="linear-gradient" x1="0.354" y1="0.151" x2="0.559" y2="0.565" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#00455f"/>
                <stop offset="0.37" stop-color="#006d93" stop-opacity="0.631"/>
                <stop offset="0.792" stop-color="#0099d2" stop-opacity="0.2"/>
                <stop offset="1" stop-color="#00aeef" stop-opacity="0"/>
                </linearGradient>
                <clipPath id="clip-ARMUS">
                <rect width="200" height="200"/>
                </clipPath>
            </defs>
            <g id="ARMUS" clip-path="url(#clip-ARMUS)">
                <circle id="Background-2" data-name="Background" cx="100" cy="100" r="100" fill="#00aeff"/>
                <g id="Mask_Group_33" data-name="Mask Group 33" clip-path="url(#clip-path)">
                <path id="Path_12710" data-name="Path 12710" d="M265.667,138.538,178.554,78.053l-10.824,5.1L150.824,87.8l-37.1,4.009-66.1-12.437s-7.341,6.785-14.06,12.437-4.557,8.124-4.557,8.124L166.663,229.441S265.843,138.655,265.667,138.538Z" transform="translate(-10.179 34.532)" opacity="0.321" fill="url(#linear-gradient)"/>
                </g>
                <path id="ARMUS-2" data-name="ARMUS" d="M101.04,29.458V.352c-2.34-.144-4.7-.25-7.1-.3C92.624.02,91.3,0,89.965,0c-48.383,0-87.6,19.113-87.6,42.69,0,7.868,4.386,15.232,12,21.561C2.954,71.813-.225,77.237.012,79.335c.38,3.356,4.647,4.289,4.647,4.289S-.534,78.059,17.267,66.516C33,77.895,59.689,85.379,89.965,85.379c29.618,0,55.783-7.169,71.639-18.134L140.28,51.609c-12.891,8.473-33.55,14.86-52.818,14.86-21.327,0-39.3-3.562-51.021-10.238C47.575,51.078,62.436,45.177,82.25,38.509c4.086-1.375,7.973-2.629,11.693-3.785V62.417h7.1V32.583C159.9,15.376,169.3,26.757,169.3,26.757c2.176-5.558-15.793-11.639-68.264,2.7M81.034,35.4c-20.751,6.637-36.4,12.914-48.157,18.56C25.815,48.946,21.81,42.5,21.81,34.76,21.81,18.585,51.2,3.052,87.463,3.052q3.281,0,6.481.18V31.457c-4.113,1.2-8.4,2.5-12.909,3.945" transform="translate(17.356 52.642)" fill="#fff"/>
            </g>
        </svg>
    );
};

export default ArmusProductIcon;