import React from "react";
import { ErrorLayout, Main } from "@armus/armus-dashboard";
import PropTypes from "prop-types";
import { Box } from "@mui/material/";


export const errorPageBreadcrumb = (title) => ({label: title, url: "/"});

const ErrorPage = (props) => {
    const {statusCode = 404} = props;
    return (
        <Main breadcrumbs={[errorPageBreadcrumb(`Error ${statusCode}`)]} fullWidth={true}>
            <Box p={8}>
                <ErrorLayout code={statusCode} />
            </Box>
        </Main>
    );
};

ErrorPage.propTypes = {
    statusCode: PropTypes.number
};
export default ErrorPage;
