import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Typography,
} from "@mui/material";
import makeSxStyles from "../../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    chartDescription: {
        display: "block",
        transitionProperty: "height",
        transitionDelay: "0",
        transitionDuration: "0.25s",
        overflowY: "scroll",
        "& .description-text": {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }
    }
}));

const ChartDescription = ({theme, description, height}) => {
    const classes = useStyles();
    if(!description) {
        return null;
    }
    return (
        <Box sx={classes.chartDescription} className={"chart-description"} style={{height: height}}>
            <Typography className="description-text">{description}</Typography>
        </Box>
    );
};


ChartDescription.propTypes = {
    description: PropTypes.any,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};


export default ChartDescription;