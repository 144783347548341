import React from "react";
import PropTypes from "prop-types";
import { titleCase } from "../../../lib/string";
import Button from "../../controls/Button";
import { safeColor } from "../../../lib/theme";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Icon } from "@healthcatalyst/react-cashmere";
import makeSxStyles from "../../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    btn: {
        color: theme.palette.text.primary,
        backgroundColor: safeColor(theme.palette.text.primary).copy({opacity: .1}).toString(),
        border: "1px solid transparent",
        borderRadius: 3,
        //marginLeft: 2,
        minWidth: theme.chartFontSize * 2.5,
        outline: "none",
        transition: ".1s",
        padding: "0px 6px!important",
        fontWeight: "bold",
        fontSize: theme.chartFontSize * 1.25,
        height: (theme.chartFontSize*1.5)+"px!important",
        // "&.MuiButtonBase-root:hover": {
        //     opacity: 1,
        //     border: "1px solid " + theme.palette.primary.main,
        //     color: theme.palette.getContrastText(theme.palette.primary.main),
        //     backgroundColor: theme.palette.primary.main,
        // },
        // "&.MuiButtonBase-root.active": {
        //     color: theme.palette.getContrastText(theme.palette.primary.main),
        //     backgroundColor: theme.palette.primary.main,
        // }
    },
    icon: {
        fontSize: Math.min(theme.chartFontSize * 1.25, 18),
        padding: 0,
        margin: 0,
    }
}));

const ChartToolBar = ({items}) => {
    const classes = useStyles();
    const active = items.filter(it => it.value === true).map(it => it.label);
    return (
        <ToggleButtonGroup size="small" value={active} variant="contained" color="secondary">
            {items.map((it) => {
                return Array.isArray(it.options) ? (
                    <Button
                        key={it.key}
                        sx={classes.btn}
                        options={it.options.map((o) => ({label: titleCase(o), value: o, active: o === it.value}))}
                        onClick={(e, v) => it.onClick(it.key, v.value)}
                        title={it.label}
                        showCarrot={false}
                        size="small" 
                        variant="contained" 
                        color="secondary"
                        value={it.label}
                        component={ToggleButton}
                    >
                        {Icon ? <Icon size="small" sx={classes.icon}>{it.icon}</Icon> : it.label}
                    </Button>
                ) : (
                    <ToggleButton
                        key={it.label}
                        onClick={() => it.onClick(it.key, it.value)}
                        sx={classes.btn}
                        title={it.label}
                        size="small" 
                        variant="contained" 
                        color="secondary"
                        value={it.label}
                    >
                        {Icon ? <Icon size="small" sx={classes.icon}>{it.icon}</Icon> : it.label}
                    </ToggleButton>
                );}
            )}
        </ToggleButtonGroup>
    );
};

ChartToolBar.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.any,
        label: PropTypes.string.required,
        key: PropTypes.string.required,
        value: PropTypes.any,
        onClick: PropTypes.func.required,
        options: PropTypes.arrayOf(PropTypes.string),
    })),
};

export default ChartToolBar;