import React from "react";

const parseMessage = (str, values, defaults = []) => {
    return str.replace(/\{\s*([^}\s]+)\s*\}/g, function(m, i) {
        return values[i] || defaults[i] || "";
    });
};
const buildNextState = (state, locale, messages) => ({...state, locale, messages});

export function createIntl(config = {}) {
    const _this = this;
    this.intlContext = React.createContext();
    this.intlContext.displayName = 'IntlContext';
    this.setIntlRef = {current: () => {
        if(process.env.NODE_ENV !== 'production') {
            console.error("IntlProvider.setLocale() called before being provided. Did you forget to wrap your app with <IntlProvider ...>?");
        }
    }};
    this.setLocale = this.setIntlRef.current;
    this.allMessages = {};
    this.initialState = {
        locale: "en",
        messages: {},
        setLocale: this.setIntlRef.current,
        formatMessage: _this.formatMessage,
        ...config,
    };

    const IntlProvider = (props) => {
        const {
            locale,
            defaultLocale = "en",
            messages = false
        } = props;

        const [state, setState] = React.useState(buildNextState(intl.initialState, locale || defaultLocale, messages));
        const setIntl = (locale, messages) => {
            if(!messages) {
                if(process.env.NODE_ENV !== 'production') {
                    console.log(`IntlProvider.setLocale() messages is undefined for locale "${locale}"`);
                }
                messages = {};
            }
            setState(buildNextState(state, locale, messages));
        };

        _this.setIntlRef.current = setIntl;
        _this.allMessages = state.messages;

        React.useEffect(() => {
            _this.setIntlRef.current(locale, messages);
        }, [locale, messages]);

        const newState = {
            locale: state.locale,
            messages: state.messages,
            setLocale: _this.setIntlRef.current,
            formatMessage: _this.formatMessage,
        };
        const Provider = _this.intlContext.Provider;

        return (
            <Provider value={newState}>
                {props.children}
            </Provider>
        );
    };
    this.IntlProvider = IntlProvider;

    const useIntl = () => {
        const state = React.useContext(_this.intlContext);
        if(!state) {
            if(process.env.NODE_ENV !== 'production') {
                console.log("IntlProvider.useIntl() called before being provided. Did you forget to wrap your app with <IntlProvider ...>?");
            }
            return _this.initialState;
        }
        return state;
    };
    this.useIntl = useIntl;

    const withIntl = (Component) => (props) => {
        const Consumer = _this.intlContext.Consumer;
        return (
            <Consumer>
                {(state = {}) => <Component {..._this.initialState} {...state} {...props} />}
            </Consumer>
        );
    };
    this.withIntl = withIntl;

    this.formatMessage = (message, values) => {
        const {id, defaultMessage} = message;
        if(!_this.allMessages.hasOwnProperty(id)) {
            if(!defaultMessage) {
                console.log(`IntlProvider.formattedMessage() Unknown message id "${id}" in messages and no default message provided.`);
            }
            return parseMessage(defaultMessage || "", values);
        }
        return parseMessage(_this.allMessages[id].defaultMessage, values, _this.allMessages[id].defaultValues);
    };
    this.initialState.formatMessage = this.formatMessage;
    return this;
}

const intl = new createIntl();
export default intl;
export const setLocale = intl.setLocale;
export const IntlProvider = intl.IntlProvider;
export const formatMessage = intl.formatMessage;
export const withIntl = intl.withIntl;
export const useIntl = intl.useIntl;