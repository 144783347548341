import React from "react";
import PropTypes from "prop-types";
import { Icon, Box, Tooltip as TooltipMUI } from "@mui/material";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    icon: {
        "&:hover": {
            color: "rgba(127,127,127,1)",
        },
        color: "rgba(127,127,127,.7)",
        fontSize: 17,
        marginLeft: theme.spacing(1)/2
    }
}));

const SmartTooltip = ({useIcon, children, ...extraProps}) => {
    const classes = useStyles();
    if(!useIcon) {
        return (
            <TooltipMUI
                arrow
                placement="top"
                enterDelay={250}
                leaveDelay={500}
                {...extraProps}
            >
                <span>{children}</span>
            </TooltipMUI>
        );
    }
    return (
        <Box sx={classes.root}>
            {children}
            <TooltipMUI
                arrow
                placement="top"
                enterDelay={250}
                leaveDelay={500}
                {...extraProps}
            >
                <Icon sx={classes.icon}>help_icon</Icon>
            </TooltipMUI>
        </Box>
    );
};

SmartTooltip.propTypes = {
    ...TooltipMUI.propTypes,
    useIcon: PropTypes.bool,
};

export default SmartTooltip;