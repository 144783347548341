import React from "react";
import { TextField } from "@mui/material";
import makeSxStyles from "../../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        cursor: "default",
    }
}));

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const Outline = ({ children, label, ...other }) => {
    const classes = useStyles();
    return (
        <TextField
            variant="outlined"
            label={label}
            multiline
            InputLabelProps={{ shrink: true }}
            InputProps={{
                sx: classes.root,
                inputComponent: InputComponent
            }}
            inputProps={{ children: children }}
            {...other}
        />
    );
};

export default Outline;