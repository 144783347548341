import React from "react";
import PropTypes from "prop-types";
import {
    AppBar,
    Toolbar,
    Typography,
    Icon,
    Box,
    Paper
} from "@mui/material";
import Button from "../controls/Button";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    paper: {
        //marginTop: -1,
        //marginBottom: )
    },
    title: {
        flexGrow: 1,
    },
    menuButton: {},
    appBar: {
        color: theme.palette.text.primary,
    },
    large: {
        paddingLeft: 2,
        paddingRight: 2,
    },
    dense: {
        paddingLeft: 2,
        paddingRight: 2,
        margin: 0,
        minHeight: "48px!important",
    },
    logo: {
        maxHeight: 38,
        marginRight: theme.spacing(2),
    }
}));

const TitleBar = ({title, description, menuOptions, children, className = "", dense = false, logo = "", expanded = true, isElevated = false, expandable = false}) => {
    const classes = useStyles();
    const [collapse, setCollapse] = React.useState(!expanded);
    const lastExpanded = React.useRef(expanded);
    React.useEffect(() => {
        if(lastExpanded.current !== expanded) {
            lastExpanded.current = expanded;
            setCollapse((expanded !== undefined ? !expanded : false));
        }
    },[expanded]);
    let content = (
        <React.Fragment>
            <AppBar position="static" color="default" sx={classes.appBar} elevation={0}>
                <Toolbar sx={dense ? classes.dense : classes.large} disableGutters={true}>
                    {!!logo && <img src={logo} alt={title} style={classes.logo} />}
                    <Typography variant="h3" sx={classes.title}>
                        {title}
                    </Typography>
                    {menuOptions && menuOptions.length >= 1 &&
                        <Button
                            variant={"iconButton"}
                            color="secondary"
                            options={menuOptions}
                            onClick={(e, o) => {o.value();}}
                        >
                            <Icon>menu</Icon>
                        </Button>
                    }
                    {!!expandable &&
                        <Button
                            variant={"iconButton"}
                            color="secondary"
                            onClick={() => {setCollapse(!collapse);}}
                        >
                            <Icon>{!!collapse ? "keyboard_arrow_down" : "keyboard_arrow_up"}</Icon>
                        </Button>
                    }
                </Toolbar>
            </AppBar>
            {(!!description && !collapse) && (
                <Box p={2} paddingBottom={!!children ? 1 : 2}>
                    <Typography>{description}</Typography>
                </Box>
            )}
            {(!!children && !collapse) && (
                <Box p={2} paddingTop={!!description ? 0 : 2}>
                    {children}
                </Box>
            )}
        </React.Fragment>
    );
    if(isElevated) {
        content = (
            <Paper className={className} sx={classes.paper}>
                {content}
            </Paper>
        );
    }
    return content;
};

TitleBar.defaultProps = {
    title: "",
    menuOptions: [],
    dense: false,
    description: "",
    logo: "",
    isElevated: true,
    expandable: false,
    expanded: undefined,
};

TitleBar.propTypes = {
    title: PropTypes.string,
    menuOptions: PropTypes.array,
    dense: PropTypes.bool,
    description: PropTypes.string,
    logo: PropTypes.string,
};

export default TitleBar;