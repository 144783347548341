import React, {useEffect} from "react";
import { Main, Paper, makeSxStyles } from "@armus/armus-dashboard";
import { withRouter } from "react-router-dom";
import {connectAppStore} from "../../data";
import {getOrganization} from "../../data/UserStore";
import { Route, useRouteMatch } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ListIcon from '@mui/icons-material/List';
import NotificationsIcon from '@mui/icons-material/Notifications';
import StreamNotifications from "./StreamNotifications";
import StreamResources from "./StreamResources";
import StreamFiles from "./StreamFiles";
import StreamOutbound from "./StreamOutbound";
import {streamsBreadcrumb} from "./Streams";
import Typography from "@mui/material/Typography";
import Chip from "../components/Chip";
import api from "../../lib/api";

export const streamBreadcrumb = (organization = {}, streamOrgKey, streamName) => ({
    label: streamName,
    url: `/organization/${organization.key}/streams/${streamOrgKey || organization.key}/${streamName}`
});
export const streamsFilesBreadcrumb = (organization = {}, streamOrgKey, streamName) => ({
    label: "Files",
    url: `/organization/${organization.key}/streams/${streamOrgKey || organization.key}/${streamName}/files`
});
export const streamsResourcesBreadcrumb = (organization = {}, streamOrgKey, streamName) => ({
    label: "Resources",
    url: `/organization/${organization.key}/streams/${streamOrgKey || organization.key}/${streamName}/resources`
});
export const streamsNotificationsBreadcrumb = (organization = {}, streamOrgKey, streamName) => ({
    label: "Notification Preferences",
    url: `/organization/${organization.key}/streams/${streamOrgKey || organization.key}/${streamName}/notifications`
});

const useStyles = makeSxStyles((theme) => ({
    tab: {
        marginTop: theme.spacing(2)
    },
    chip: {
        marginTop: 1.5,
        float: "right"
    },
    title: {
        padding: 2,
        paddingBottom: 1,
        paddingTop: 1,
        borderBottom: "2px solid rgba(0,0,0,.15)"
    }
}));

const Stream = (props) => {
    const {
        match,
        history,
        organization,
        stream
    } = props;
    const classes = useStyles();
    const { path, url, params } = match;
    const tabParams = useRouteMatch(path + "/:tab?").params;
    const handleChange = (event, newValue) => {
        history.push(`${url}${url.slice(-1) === "/" ? "" : "/"}${newValue}`);
    };

    useEffect(() => { // didMount
            api.loadStream(params.orgKey, params.streamOrgKey, params.streamName);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params.orgKey, params.streamOrgKey, params.streamName]);

    const breadcrumbs = [
        streamsBreadcrumb(organization),
        streamBreadcrumb(organization, params.streamOrgKey, params.streamName)
    ];

    switch(tabParams.tab) {
        case 'resources':
            breadcrumbs.push(streamsResourcesBreadcrumb(organization, params.streamOrgKey, params.streamName));
            break;
        case 'notifications':
            breadcrumbs.push(streamsNotificationsBreadcrumb(organization, params.streamOrgKey, params.streamName));
            break;
        default:
            breadcrumbs.push(streamsFilesBreadcrumb(organization, params.streamOrgKey, params.streamName));
    }

    return (
        <Main
            breadcrumbs={breadcrumbs}
            fullWidth={true}
        >
            <Box sx={{padding: 4}}>
                <Paper>
                    <Typography sx={classes.title} component="h1" variant={"h4"} >
                        {stream.shortname}
                        &nbsp;
                        <Chip
                            sx={classes.chip}
                            label={stream.paused ? "Paused" : "Running"}
                            disabled={stream.paused}
                            color={stream.paused ? "default" : "primary"}
                        />
                    </Typography>
                    <Tabs
                        value={tabParams.tab || "files"}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon tabs example"
                    >
                        <Tab icon={<ListIcon />} aria-label="files" value="files" label="Stream Files" />
                        <Tab icon={<MenuBookIcon />} aria-label="resources" value="resources" label="Resources" />
                        <Tab icon={<NotificationsIcon />} aria-label="notifications" value="notifications" label="Notification Preferences" />
                    </Tabs>
                    <div className={classes.tab}>
                        <Route path={`${path}/:tab?`} render={({ match }) => {
                            if (match.params.tab === 'resources') {
                                return <StreamResources />;
                            } else if (match.params.tab === 'notifications') {
                                return <StreamNotifications />;
                            } else {
                                if (stream.direction === "INBOUND") {
                                    return <StreamFiles />;
                                } else {
                                    return <StreamOutbound />;
                                }
                            }
                        }}  />
                    </div>
                </Paper>
            </Box>
        </Main>
    );
};

export default withRouter(connectAppStore(([state, dispatch], props) => {
    const params = props.match.params || {};
    return {
        userState: state.user,
        streamsStatus: state.streams.status,
        stream: state.stream.data || {},
        organization: getOrganization(state.user, params.orgKey)
    };
})(Stream));
