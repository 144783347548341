import React, { useState } from "react";
import PropTypes from "prop-types";
import SmartLink from "./SmartLink";
import {
    ListItemIcon,
    MenuItem,
    Divider,
    LinearProgress,
    Grid,
    Box,
    alpha,
} from '@mui/material';
import { Navbar, Icon } from "@healthcatalyst/react-cashmere"; 
import SmartMenu from "./SmartMenu";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        "& .hc-navbar .navbar-dropdown.force-active": {
            backgroundColor: "#28323c",
            fontWeight: "normal",
            color: "white",
        },
        "& .hc-navbar .navbar-dropdown.force-active.active": {
            backgroundColor: "#28323c",
            fontWeight: 600,
            color: "white",
        },
    },
    dropdownMenu: {
        "& .MuiPopover-paper": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        "& .navbar-dropdown-link:hover, & .Mui-focusVisible": { 
            backgroundColor: alpha(theme.palette.primary.main, 0.35),
        },
        "& .navbar-dropdown-link": { 
            color: theme.palette.mode === "dark" ? "#fff" : "#333",
            minWidth: 150,
            textDecoration: 'none',
            "& .MuiListItemIcon-root": {
                color: theme.palette.mode === "dark" ? "#fff" : "#333",
            }
        },
    },
    mainNavItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
     }
}));

export const Header = ({ leftNav, rightNav, profileNav, isLoading, userName, loginUrl, logoUrl, brandUrl, position, children, cobrandUrl, cobrandColor, homeUrl }) => {
    const [ menuState, setMenuState ] = useState({ open: false, target: false, item: null, hasIcons: false});
    const classes = useStyles();
    const handleMenuClose = () => {
        setMenuState((menuState) => ({...menuState, open: false, items: [...menuState.items]}));
    };

    const handleMenuOpen = (e, items = [], horizontal = "left") => {
        e.preventDefault();
        const hasItems = items.length;
        const hasIcons = hasItems ? items.filter(it => it && !!it.icon).length: false;
        const anchorEl = e.target.closest(".navbar-item");
        const minWidth = anchorEl?.clientWidth || 150; 
        const newMenuState = {
            open: true,
            anchorEl: anchorEl,
            minWidth: minWidth,
            items: items,
            horizontal: horizontal,
            hasIcons: hasIcons
        };
        setMenuState(newMenuState);
    };

    const renderNavMenu = (items, horizontal) => {
        return items.map((item, index) => {
            const hasItems = item.items && item.items.length;
            const isActive = item.items === menuState.items;
            return (
                <SmartLink
                    id={horizontal + "MenuButton"}
                    className={"navbar-link navbar-item" + (isActive ? " force-active" : "") + (hasItems ? " navbar-dropdown" : "")}
                    key={index}
                    sx={classes.mainNavItem}
                    to={item.url}
                    onClick={hasItems ? (e) => handleMenuOpen(e, item.items, horizontal) : undefined}
                    color="inherit"
                >
                    <Grid container alignItems="center" sx={{flexFlow: "row"}} height={"100%"} pt={"5px"}>
                        {item.icon && item.icon}
                        {item.icon && <>&nbsp;</>}
                        {item.label}
                        {item.label && hasItems && <>&nbsp;</>}
                        {hasItems && <Icon icon="fa-angle-down" aria-hidden="true" />}
                    </Grid>
                </SmartLink>
            );
        });
    };

    return (
        <Box id={"header"} sx={classes.root}>
            <Navbar 
                position={position}
                appIcon={logoUrl}
                brandIcon={brandUrl}
                cobrandIcon={cobrandUrl}
                cobrandColor={cobrandColor}
                homeUri={homeUrl}
                linkContent={renderNavMenu(leftNav, "left")}
                rightContent={[
                    renderNavMenu(rightNav, "right"),
                    <span key="seperator" className="hc-navbar-vertical-separator"></span>,
                    (userName == null ? (
                        <Box
                            key="user"
                            tabIndex="0"
                            className="hc-navbar-username ng-star-inserted"
                        >
                            <SmartLink
                                id={"loginButton"}
                                color="inherit"
                                href={loginUrl}
                            >
                                Login
                            </SmartLink>
                        </Box>
                    ) : (
                        <Box
                            key="user"
                            tabIndex="0"
                            className="hc-navbar-username ng-star-inserted navbar-item"
                            onClick={(e) => profileNav.length && handleMenuOpen(e, profileNav, "right")}
                        >
                            <span>
                                <span>{userName}</span>
                            </span>
                            {profileNav.length > 0 && <>
                                &nbsp;
                                <Icon 
                                    icon="fa-angle-down" 
                                    aria-hidden="true" 
                                />
                            </>}
                        </Box>
                    ))
                ]}
            />
            <SmartMenu
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={handleMenuClose}
                horizontal={menuState.horizontal}
                sx={classes.dropdownMenu}
            >
                {(menuState.items || []).map((item, index) => {
                    if(item === null) {
                        return (<Divider key={index} />);
                    }
                    return (
                        <MenuItem
                            key={index}
                            component={SmartLink}
                            onClick={(e) => {handleMenuClose(e); item.onClick && item.onClick(e);}}
                            to={item.url}
                            color="inherit"
                            className="navbar-dropdown-link"
                            style={{minWidth: menuState.minWidth > 150 ? menuState.minWidth : 150}}
                        >
                            {!!menuState.hasIcons && (<ListItemIcon>{item.icon || ""}</ListItemIcon>)}{item.label}
                        </MenuItem>
                    );
                })}
            </SmartMenu>
            {isLoading && <LinearProgress id={"loadingBar"} />}
        </Box>
    );
};

Header.defaultProps = {
    position: "static",
    leftNav: [],
    rightNav: [],
    profileNav: [],
    isLoading: false,
    loginUrl: "",
    userName: null,
    homeUrl: "/",
    logoUrl: "",
    brandUrl: <Icon icon="hci-catalyst-logo" size="large" />,
    cobrandUrl: "",
    cobrandColor: "light"
};

const treeItemShape = PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.node,
    url: PropTypes.string,
    onClick: PropTypes.func,
});
treeItemShape.items = PropTypes.arrayOf(treeItemShape); // recursive shape

Header.propTypes = {
    leftNav: PropTypes.arrayOf(treeItemShape),
    rightNav: PropTypes.arrayOf(treeItemShape),
    profileNav: PropTypes.arrayOf(treeItemShape),
    userName: PropTypes.string,
    isLoading: PropTypes.bool,
    loginUrl: PropTypes.string.isRequired,
    logoUrl: PropTypes.any,
    cobrandUrl: PropTypes.any,
    cobrandColor: PropTypes.string,
    brandUrl: PropTypes.any,
    position: PropTypes.string,
    homeUrl: PropTypes.string,
};

export default Header;
