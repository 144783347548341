import {
    buildAsyncReducer,
    buildAsyncActions,
    asyncInitialState
} from "./utils/asyncReducer";

const initialState = {
    ...asyncInitialState,
    data: []
};

export const streamActions = buildAsyncActions("stream");
export const streamReducer = buildAsyncReducer(streamActions, initialState);
