import React, { useContext } from "react";
import { Box } from "@mui/material";
import {
    getColorScheme,
} from "../../lib/theme";
import storage from "../../lib/storage";
import makeSxStyles from "../../lib/makeSxStyles";

import { ThemeProvider, themes } from "@healthcatalyst/react-cashmere";

const buildCombinedThemes = (theme) => {
    const muiTheme = {...theme};
    const colorKeys = Object.keys(muiTheme.palette);
    muiTheme.colors = {};
    colorKeys.forEach(ck => {
        if(muiTheme.palette[ck].main) {
            muiTheme.colors[ck] = muiTheme.palette[ck].main;
        }
    });
    muiTheme.colors.series = [muiTheme.palette.primary.main];
    muiTheme.fontSize = 12;
    muiTheme.chartFontSize = 12;

    return muiTheme;
};

// this is the default shape of a theme.
export const defaultThemes = [
    ...themes,
];

export const THEME_STORAGE_KEY = "armus-dashboard.themeIndex";
const initialState = {
    index: 0,
    theme: buildCombinedThemes({...defaultThemes[0]}),
    themes: [...defaultThemes],
    defaultThemeIndex: 0,
    setTheme: () => {}
};

const ThemeContext = React.createContext(initialState);
ThemeContext.displayName = 'DashboardContext';

const isValidThemeIndex = (themes, index) => {
    return !!themes[index];
};

const buildNextState = (state, themes) => ({...state, themes, theme: buildCombinedThemes(themes[state.index])});
export const DashboardProvider = ({onChange, themes = defaultThemes, defaultThemeIndex = 0, children}) => {
    const setThemeRef = React.useRef();
    let storageIndex = storage.load(THEME_STORAGE_KEY);
    if(!isValidThemeIndex(themes, storageIndex)) {
        storageIndex = 0;
        if(themes?.length === 0) {
            throw new Error("DashboardProvider: No themes available.");
        }
        storage.save(THEME_STORAGE_KEY, storageIndex);
    }

    const [state, setState] = React.useState(buildNextState({...initialState, index: storageIndex || defaultThemeIndex, defaultThemeIndex }, themes));
    const setTheme = (index, defaultThemeIndex) => {
        if(isValidThemeIndex(themes, storageIndex)) {
            storage.save(THEME_STORAGE_KEY, index);
            setState(buildNextState({...state, index, defaultThemeIndex: defaultThemeIndex || state.defaultThemeIndex }, themes));
            onChange && onChange(themes[index]);
        }
        else {
            console.log("DashboardProvider: Invalid theme index provided.");
        }
    };
  
    setThemeRef.current = setTheme;
    React.useEffect(() => {
        if(storageIndex !== state.index) {
            setThemeRef.current(storageIndex);
        }
    }, [storageIndex, state]);

    const newState = {
        ...state,
        setTheme: setThemeRef.current
    };
    return (
        <ThemeContext.Provider value={newState}>
            <ThemeProvider theme={newState.theme}>
                <DashboardProviderContainer theme={newState.theme}>
                    {children}
                </DashboardProviderContainer>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default DashboardProvider;

const useStyles = makeSxStyles((theme) => ({
    dashboardContainer: {
        ...theme.typography.body1
    }
}));

const DashboardProviderContainer = ({theme, containerRef, children}) => {
    const classes = useStyles();
    return (
        <Box
            ref={containerRef}
            className={"dashboard-theme"}
            sx={classes.dashboardContainer}
            style={{
                background: theme.palette.background.default,
                color: theme.palette.text.primary,
            }}
        >
            {children}
        </Box>
    );
};

export const withTheme = (Component) => (props) => {
    const state = useContext(ThemeContext);
    return (
        <Component
            {...props}
            theme={state.theme}
            themeIndex={state.index}
            themes={state.themes}
            setTheme={state.setTheme}
            getColorScheme={(config, returnArray) => getColorScheme(state.theme, config || props.config, returnArray)}
        />
    );
};

export const useTheme = () => {
    const state = useContext(ThemeContext);
    return state;
};