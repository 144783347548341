import React, {
    createContext,
    useContext,
    useState,
    useRef,
} from "react";
import Notifications from "../common/Notifications";

const getInitialState = () => {
    return {
        queue: [],
    };
};

const NotificationsContext = createContext(getInitialState());

const NotificationsProvider = ({children}) => {
    const [state, setState] = useState(getInitialState());
    const stateRef = useRef();
    const setStateRef = useRef();
    stateRef.current = state;
    setStateRef.current = setState;

    const getQueue = () => [...stateRef.current.queue.map(it => ({...it}))];
    const setQueue = (queue) => setStateRef.current({...stateRef.current, queue});
    const onPush = (item) => {
        const queue = getQueue();
        const newItem = {
            key: null,
            iconName: null,
            type: "default",
            message: "",
            size: "medium",
            verticalPosition: "top",
            horizontalPosition: "center",
            stacked: true,
            canClose: true,
            canAutoClose: true,
            progress: null,
            ...item,
            open: true
        };
        const index = queue.findIndex(it => item.key === it.key);
        if(index !== -1) {
            // merge
            queue[index] = {...queue[index], ...item};
        }
        else {
            // insert
            queue.push(newItem);
        }
        setQueue(queue.filter(it => it.open));
    };
    const onPop = () => {
        const queue = getQueue();
        if(queue.length) {
            queue.pop();
        }
        setQueue(queue);
    };
    const onHide = (index) => {
        // remove item from queue
        const queue = getQueue();
        if(index === undefined) {
            let foundIndex = -1;
            // auto remove the oldest open item
            // search the queue find the last canAutoClose == true
            queue.forEach((it, i) => {
                if(it.canAutoClose && it.open) {
                    foundIndex = i;
                }
            });
            if(foundIndex === -1) {
                // nothing can be closed exit no-op.
                return;
            }
            index = foundIndex;
        }
        if(index < queue.length && index >= 0) {
            queue[index] = {...queue[index], open: false}; // for hide animation
            setQueue(queue.filter(it => it.open !== false).map(it => ({...it, open: !!it.open})));
        }
    };
    const onRemove = () => {
        // remove item from queue
        const queue = getQueue();
        const newQueue = queue.filter(it => it.open === true);
        console.log(queue, newQueue);
        setQueue(newQueue);
    };
    const onCleanup = () => {
        // remove all open=false from queue
        const queue = getQueue();
        setQueue(
            queue.filter((n) => (n.open === true))
        );
    };
    const onReset = () => {
        setQueue(getInitialState().queue);
    };
    const value = {
        getQueue,
        setQueue,
        onPush,
        onPop,
        onHide,
        onRemove,
        onCleanup,
        onReset,
    };

    return (
        <NotificationsContext.Provider value={value}>
            <Notifications
                queue={getQueue()}
                onHideNotification={onHide}
                onRemoveNotification={onRemove}
            />
            {children}
        </NotificationsContext.Provider>
    );
};


export const useNotifications = () => {
    const notificationsState = useContext(NotificationsContext);
    return {
        queue: notificationsState.getQueue(),
        ...notificationsState
    };
};

export default NotificationsProvider;