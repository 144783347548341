import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
const StreamOutbound = () => {
    return (
        <React.Fragment>
            <Box p={2}>
                <Typography variant="h4" gutterBottom>
                    Outbound Stream
                </Typography>
                <Typography variant="body1">
                    This is an outbound style stream. Please go to your SFTP account to see the export file.
                </Typography>
            </Box>
        </React.Fragment>
    );
};
export default StreamOutbound;
