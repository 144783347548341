import React from 'react';
import { dataWithInfo } from "../../../lib/utils";
import { formatValue } from "../../../lib/number";
import { Chart } from '../ChartThemeProvider';
import { Box } from '@mui/material';

export const propsFromData = (data, cfg) => {
    // reformat our standard to apache eChart
    const ret = {
        legendLabels: [],
        axisLabels: [],
        data: [], // orderd by legend
        isGroupedData: false
    };
    if (!data.info) {
        data = dataWithInfo(data);
   }
    if (data && data.length > 0) {
        if (Array.isArray(data[0].value)) {
            ret.isGroupedData = true;
            ret.legendLabels = data.map(it => it.label);
            ret.axisLabels = data[0].value.map(it => it.label);
            ret.data = [];
            data.forEach((series, index) => {
                ret.data.push({
                    name: series.label,
                    data: data[index].value.map(it => ({ 
                        name: it.label,
                        value: it.value,
                        itemStyle: { color: it.color || series.color },
                        lineStyle: { color: it.color || series.color },
                        areaStyle: { color: it.color || series.color, opacity: 0.25 }
                    }))
                });
            });
        }
        else {
            ret.isGroupedData = false;
            ret.legendLabels = data.map(it => it.label);
            ret.axisLabels = data.map(it => it.label);
            ret.data = [{
                data: data.map(it => ({
                    name: it.label,
                    value: it.value,
                    itemStyle: { color: it.color },
                    lineStyle: { color: it.color },
                    areaStyle: { color: it.color, opacity: 0.25 }
                }))
            }];
        }
    }
    return ret;
};

export const convertDataToParentChild = (data) => {
    return data.map(it => {
        const ret = {
            name: it.label
        };
        if(Array.isArray(it.value)) {
            ret.children = convertDataToParentChild(it.value);
        }
        else {
            ret.value = it.value;
        }
        return ret;
    });
};

export const convertDataToHeatmap = (data) => {
    const ret = {
        xLabels: [],
        yLabels: [],
        data: [],
    };
    data.forEach((xIt, xIndex) => {
        if(!ret.xLabels.includes(xIt.label)) {
            ret.xLabels.push(xIt.label);
        }
        xIt.value.forEach((yIt, yIndex) => {
            if(!ret.yLabels.includes(yIt.label)) {
                ret.yLabels.push(yIt.label);
            }
            ret.data.push([xIndex, yIndex, yIt.value]);
        });
        
    });
    return ret;
};


const buildConfigFromData = (initialData, descriptor) => {
    const config = defaultConfig(descriptor.config);
    let { legendLabels, axisLabels, data, isGroupedData } = propsFromData(initialData, config);
    let { showZoom, showColorRange, layoutMode, groupMode, legendPosition, legend, min, max, showToolbox, showAxisX, showAxisY } = config;
    let chartType = descriptor.type;
    let extras = {};

    if(isGroupedData && ['line', 'pie'].includes(chartType)) {
        if(!!data && !!data[0] && data[0].data && data[0].data.length === 1) {
            // ungroup the data
            isGroupedData = false;

            legendLabels.legendLabels = data[0].data.map(it => it.name);
            axisLabels.axisLabels = data[0].data.map(it => it.name);
            data = [{
                data: data.map(it => ({ name: it.name, value: it.data[0].value, itemStyle: { color: it.data[0].color }, lineStyle: {color: it.data[0].color} }))
            }];
            legendLabels = data[0].data.map(it => it.name);
            axisLabels = data[0].data.map(it => it.name);
        }
    }

    const labelOption = {
        show: true,
        rotate: 0,
        align: 'center',
        verticalAlign: 'middle',
        position: 'top',
        distance: 15,
        formatter: (params) => {
            return formatValue(config.formatValue, params.value);
        },
        fontSize: 12,
        rich: {
            name: {}
        }
    };

    let seriesData = data.map(it => ({
        type: chartType,
        BasicChartGap: 0,
        radius: '65%',
        label: labelOption,
        emphasis: {
            focus: 'series'
        },
        stack: groupMode === "stacked",
        areaStyle: it?.data[0]?.areaStyle?.color ? it?.data[0]?.areaStyle : undefined,
        lineStyle: it?.data[0]?.lineStyle?.color ? it?.data[0]?.lineStyle : {}, 
        ...it, // name and data
    }));

    let xAxis = [
        {
            show: showAxisX,
            type: 'category',
            name: config.xLabel || "",
            axisTick: { show: false },
            data: axisLabels,
            min: undefined,
            max: undefined,
            position: 'center',
        }
    ];

    let yAxis = [
        {
            show: showAxisY,
            type: 'value',
            name: config.yLabel || "",
            min: undefined,
            max: undefined,
            position: 'left',
            axisLabel: {
                formatter: (v) => {
                    return formatValue(config.formatValue, v);
                },
            }
        }
    ];
    let tooltip = {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    };

    if(layoutMode === 'horizontal') {
        let temp = xAxis;
        xAxis = yAxis;
        yAxis = temp;
        labelOption.position = "right";
    }

    switch (chartType) {
        case "pie":
            xAxis = undefined;
            yAxis = undefined;
            showZoom = false;
            tooltip.trigger = "item";
            if(layoutMode === "doughnut") {
                seriesData = seriesData.map(it => ({
                    ...it, 
                    radius: ['35%', '65%'],
                }));
            }

            if (isGroupedData) {
                chartType = 'sunburst';
                data = convertDataToParentChild(initialData);
                seriesData = [{
                    type: chartType,
                    data: data,
                    radius: [0, '90%'],
                    label: {
                        rotate: 'radial'
                    }
                }];
            }
            break;
            case "sunburst":
                tooltip.trigger = "item";
                xAxis = undefined;
                yAxis = undefined;
                showZoom = false;
                data = convertDataToParentChild(initialData);
                seriesData = [{
                    type: 'sunburst',
                    data: data,
                    radius: [0, '90%'],
                    label: {
                        rotate: 'radial'    
                    }
                }];
                break;
            case "heatmap":
                data = convertDataToHeatmap(initialData);
                showZoom = false;
                showColorRange = true;
                seriesData = [{
                    type: 'heatmap',
                    data: data.data,
                    label: {
                        show: true,
                        formatter: (params) => {
                            return formatValue(config.formatValue, params.data[2]);
                        },
                        fontSize: 12,
                        rich: {
                            name: {}
                        }
                    },
                }];

                xAxis = [{
                    type: 'category',
                    data: data.xLabels,
                    splitArea: {
                        show: false
                    },
                    formatter: (v) => {
                        return formatValue(config.formatValue, v);
                    },
                }];
                yAxis = [{
                    type: 'category',
                    data: data.yLabels,
                    splitArea: {
                        show: false
                    },
                    formatter: (v) => {
                        return formatValue(config.formatValue, v);
                    },
                }];
                extras = {
                };
                break;
        default:
    }

    const options = {
        tooltip: tooltip,
        legend: legend ? {
            top: legendPosition,
            //data: legendLabels
        } : undefined,
        toolbox: {
            show: showToolbox,
            orient: 'horizontal',
            left: 'right',
            top: 'top',
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: false, type: ['bar', 'line', 'stack'] },
                restore: { show: false },
                saveAsImage: { show: true }
            }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: seriesData,
        dataZoom: showZoom ? [
            {
                type: 'inside',
            }, {}
        ] : undefined,
        visualMap: showColorRange ? {
            min: min,
            max: max,
            calculable: true,
            orient: showZoom ? 'vertical' : 'horizontal',
            left: showZoom ? 'right' : 'center',
            top: showZoom ?'center' : 'bottom',
            formatter: (v) => {
                return formatValue(config.formatValue, v);
            },
        } : undefined,
        ...extras
    };
    return options;
};



const BasicChart = (props) => {
    const { descriptor = {}, onClick } = props;
    const onClickWrapped = React.useCallback((args) => {
        onClick(args?.event?.event, args.data, descriptor);
    }, [onClick, descriptor]);
    if (!props.data || !props.data.length) {
        return <Box p={2} textAlign={"center"}>No Data</Box>;
    }
    let options = {};
    if(!!props.data[0].type) {
        // multi series / visual chart
        props.data.forEach((d, index) => {
            const desc = {
                ...descriptor, 
                type: d.type || descriptor.type
            };
            const o = buildConfigFromData(d.value, desc);
            if(index === 0) {
                options = o;
            }
            else {
                o.series.forEach(s => {
                    options.series.push(s);
                });
            }
        });
    }
    else {
        options = buildConfigFromData(props.data, descriptor);
    }

    return (
        <Chart options={options} onClick={onClickWrapped} />
    );
};

export const defaultConfig = (config = {}) => ({
    indexBy: "label",
    xLabel: "",
    yLabel: "Value",
    formatValue: null,
    min: 0,
    max: 1,
    grid: "y",
    groupMode: "grouped",
    groupModeInvert: false,
    layoutMode: "vertical",
    legend: true,
    legendPosition: "top",
    colorScheme: 'series',
    colorSchemeReversed: false,
    markers: [],
    showToolbox: true,
    showZoom: true,
    showColorRange: false,
    showAxisX: true,
    showAxisY: true,
    ...config
});

BasicChart.defaultProps = {
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            ...defaultConfig()
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus: undefined, // (event, value, descriptor) => {}
    onBlur: undefined, // (event, value, descriptor) => {}
    onClick: undefined, // (event, value, descriptor) => {}
    onKeyDown: undefined, // (event, value, descriptor) => {}
};

export default BasicChart;
