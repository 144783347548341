import React from 'react';
import BaseChart, {defaultConfig as baseChartConfig} from '../BasicChart/BasicChart';

const Bar = (props) => {
    return (
        <BaseChart {...props} />
    );
};

export const defaultConfig = (config = {}) => baseChartConfig(config);

Bar.defaultProps = {
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            ...defaultConfig()
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus: undefined, // (event, value, descriptor) => {}
    onBlur: undefined, // (event, value, descriptor) => {}
    onClick: undefined, // (event, value, descriptor) => {}
    onKeyDown: undefined, // (event, value, descriptor) => {}
};

export default Bar;
