import React from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        position: "relative",
        height: "100%",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "stretch",
        display: "flex",
        opacity: 0,
        transition: ".5s",
    },
    open: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        opacity: 1,
    },
    box: {
        height: "100%",
        alignItems: "stretch",
        overflow: "auto",

    },

}));

const Pane = ({width = "100%", className, minWidth, maxWidth, show = true, children, ...extraProps}) => {
    const classes = useStyles();
    return (
        <Box 
            className={(className || "") + (show ? " active" : " hidden")}
            sx={{...classes.root, ...(show ? classes.open : {}), ...extraProps.sx}}
            maxWidth={maxWidth}
            minWidth={show ? minWidth : 0}
            width={show ? width : 0}
        >
            <Box sx={classes.box} minWidth={minWidth} maxWidth={maxWidth} {...extraProps}>
                {children}
            </Box>
        </Box>
    );
};

Pane.defaultProps = {
    show: true,
    width: "100%",
};

Pane.propTypes = {
    ...Box.propTypes,
    show: PropTypes.bool,
};

export default Pane;
