import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Box
} from "@mui/material";
import makeSxStyles from '../../lib/makeSxStyles';

const useStyles = makeSxStyles(theme => ({
    root: {
        display: "inline-block",
        margin: 0,
        marginRight: theme.spacing(.5),
        "&:last-child": {
            margin: 0,
        },
    },
    chip: {
        "& .MuiChip-root": {
            textTransform: "uppercase",
        }
    },
    chipXs: {
        "& .MuiChip-root": {
            margin: 0,
            fontSize: 9,
            padding: 0,
            height: 14,
            fontWeight: "bold",
            textTransform: "uppercase",
        }
    },
}));

const InfoChip = ({color, title, size, ...extraProps}) => {
    let overrideColor = color;
    let overrideSize = size;
    const classes = useStyles();
    if(size === "xs") {
        overrideSize = "small";
    }
    return (
        <Box title={title} sx={{...classes.root, ...(size === "xs" ? classes.chipXs : classes.chip)}}>
            <Chip {...extraProps} size={overrideSize} color={overrideColor} />
        </Box>
    );
};

InfoChip.defaultProps = {
    color: "primary",
    title: "",
    size: "large",
};

InfoChip.propTypes = {
    ...Chip.propTypes,
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'default', 'success', 'warning', 'error', 'default']),
    title: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'small', 'medium']),
};


export default InfoChip;