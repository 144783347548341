export const asyncInitialState = {
    data: {},
    isLoading: false,
    isError: false,
    status: "INIT"
};

// creates the generic async actions prefixed with the provided key.
export const buildAsyncActions = (key) => ({
    // sync actions
    Reset: `${key}/RESET`,
    Set: `${key}/SET`,
    // async actions
    Loading: `${key}/LOADING`,
    Loaded: `${key}/LOADED`,
    Error: `${key}/ERROR`
});

// creates the generic async reducer based on the provided actions and initial state.
export const buildAsyncReducer = (actions, initialState = asyncInitialState) =>
    (state = initialState, action = {}) => {
        switch(action.type) {
            // Sync Actions
            case actions.Set:
                return {...state, data: {...state.data, [action.key]: action.data}};
            case actions.Reset:
                return {...initialState};
            // Async Actions
            case actions.Loading:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    status: "LOADING"
                };
            case actions.Loaded:
                return {
                    ...state,
                    data: (action.data !== undefined ? action.data : state.data),
                    isError: false,
                    isLoading: false,
                    status: "LOADED"
                };
            case actions.Error:
                return {
                    ...state,
                    isError: action.error || true,
                    data: action.data === undefined ? state.data : action.data,
                    isLoading: false,
                    status: "ERROR"
                };
            default:
                return state;
        }
    };
