import React from "react";
import PropTypes from "prop-types";
import EnhancedTable from "./EnhancedTable";
import { titleCase, valueToString } from '../../lib/string';

const stringToLink = (value) => {
    value = valueToString(value);
    if (typeof value === "string" && value.indexOf("http") === 0) {
        return (
            <a href={value} target={"_blank"} rel="noopener noreferrer">{value}</a>
        );
    }
    return value;
};

const KeyValueTable = ({keys, data, showHeader, onClick, ...extraProps}) => {
    const [sort, setSort] = React.useState({orderBy: undefined, order: "asc"});

    let rows = [];
    let columns = [];
    if(Array.isArray(data)) {
        if(!data || data.length === 0) {
            return null;
        }
        if(!keys) {
            keys = Object.keys(data[0]);
        }
        columns = keys.map((key, index) => ({
            id: key,
            isSortable: true,
            label: titleCase(key),
            props: {  align: index > 0  ? "right" : "left", component: index === 0 ? "th" : "td" },
            content: ({row}) => {
                return stringToLink(row[key]);
            }
        }));
        rows = data;
    }
    else {
        if(!keys) {
            keys = Object.keys(data);
        }
        rows = keys.map(key => ({
            label: titleCase(key),
            value: stringToLink(data[key]),
        }));

        columns = [
            {
                id: "label",
                label: "Label",
                props: {  align: "left", component: "th", width: "15%" },
                isSortable: true,
                content: ({row}) => {
                    return row.label;
                }
            },
            {
                id: "value",
                label: "Value",
                isSortable: true,
                props: { align: "left" },
                content: ({row}) => {
                    return row.value;
                }
            }
        ];
    }
    const handleRequestSort = (e, columnid) => {
        let order = sort.order;
        if(columnid === sort.orderBy && sort.order === "asc") {
            order = "desc";
        }
        else if(columnid === sort.orderBy && sort.order === "desc") {
            // reset sort to provided order.
            order = "asc";
            columnid = undefined;
        }
        else {
            order = "asc";
        }
        setSort({orderBy: columnid, order: order});
    };

    const sortedRows = [...rows];
    if(sort.orderBy) {
        sortedRows.sort(
            (a, b) => {
                const aSortValue = a[sort.orderBy];
                const bSortValue = b[sort.orderBy];
                return (aSortValue || "").toString().localeCompare((bSortValue || "").toString(), navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                });
            }
        );
        if(sort.order === "desc") {
            sortedRows.reverse();
        }
    }


    return (
        <EnhancedTable
            rows={sortedRows}
            showHeader={showHeader}
            columns={columns}
            dense={true}
            hover={false}
            onCellClick={onClick}
            onRequestSort={handleRequestSort}
            order={sort.order}
            orderBy={sort.orderBy}
            pagination={false}
            {...extraProps}
        />
    );
};

KeyValueTable.propTypes = {
    ...EnhancedTable.propTypes,
    keys: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.object,
};

export default KeyValueTable;
