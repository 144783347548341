import React, {useEffect, useRef} from "react";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { EnhancedTable, SmartLink, makeSxStyles, Button, useNotifications } from "@armus/armus-dashboard";
import api from "../../lib/api";
import {connectAppStore} from "../../data";
import {canWrite, getOrganization, getOrganizationName} from "../../data/UserStore";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar/Avatar";
import FileIcon from "@mui/icons-material/Description";
import UploadIcon from "@mui/icons-material/UploadFile";
import Chip from "../components/Chip";
import queryString from "query-string";
import moment from "moment";
import ErrorPage from "../ErrorPage";
import { Box } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const useStyles = makeSxStyles((theme) => ({
    header: {
        position: "relative",
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        background: theme.palette.mode === 'light' ? "#f5f5f5" : "#424242",
        borderTop: "1px solid rgba(0,0,0,.15)",
        borderBottom: "1px solid rgba(0,0,0,.15)"
    },
    streamAction: {
        marginTop: .75,
        marginLeft: 1,
        float: "right",
        verticalAlign: "middle"
    }
}));


const pageOptions = [25, 50, 100];
const validateQueryString = (qs) => {
    if(qs.page) {
        qs.page = parseInt(qs.page, 10);
        if(qs.page<0) {
            qs.page = 0;
        }
    }
    if(qs.size) {
        qs.size = parseInt(qs.size, 10);
        if(!pageOptions.includes(qs.size)) {
            qs.size = pageOptions[0];
        }
    }
    if(qs.orderBy) {
        if(!["path", "lastState", "lastUpdated"].includes(qs.orderBy)) {
            qs.orderBy = "lastUpdated";
        }
    }
    if(qs.order) {
        if(!["desc", "asc"].includes(qs.size)) {
            qs.order = "desc";
        }
    }
    return qs;
};

const buildStreamFileColumns = (userState, params, organization, doReprocess) => {
    return [
        { id: 'filename', isSortable: true, disablePadding: false, label: 'File Path', props: {align: "left"}, content: ({row}) =>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    component={SmartLink}
                    to={`/organization/${organization.key}/streams/${params.streamOrgKey || organization.key}/${params.streamName}/file/${row.id}`}
                    spacing={1}
                >
                    <Grid item>
                        <Avatar><FileIcon/></Avatar>
                    </Grid>
                    <Grid item>
                        <strong>{row.filename}</strong><br/>
                    </Grid>
                </Grid>
        },
        { id: 'lastState', isSortable: true, disablePadding: false, label: 'Last State', props: {align: "left", width: "10%"}, content: ({row}) => {
                return (<Chip status={row.lastState} />);
            }
        },
        //{ id: 'sha1', isSortable: false, disablePadding: false, label: 'SHA1', props: {align: "left", width: ""} },
        { id: 'lastUpdated', isSortable: true, disablePadding: false, label: 'Last Updated', props: {align: "right", width: ""}, content: ({row}) => {
                return moment(row.lastUpdated).format("MM/DD/YYYY HH:mm:ss");
            }
        },
        { id: 'reprocess', isSortable: false, disablePadding: false, label: 'Reprocess', props: {align: "left", width: "10%"}, content: ({row}) => {

                if (!['CANCELLED', 'SUCCESS', 'FAILED'].includes(row.lastState) || ! canWrite(userState, params.orgKey, params.implKey)) {
                    return null;
                }
                return (
                    <Button variant="contained" color="default" onClick={() => doReprocess(row)}>
                        Reprocess
                    </Button>
                );
            }
        }
    ];
};

const StreamFiles = (props) => {
    const {userState, organization, streamFilesStatus, streamFiles, history} = props;
    const classes = useStyles();
    const refreshIntervalRef = useRef();
    const params = props.match.params || {};
    const qs = validateQueryString(queryString.parse(history.location.search) || {});
    const { onPush } = useNotifications();

    // validate qs
    const [page, setPage] = React.useState(qs.page || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(qs.size || 25);
    const [order, setOrder] = React.useState(qs.order || 'desc');
    const [orderBy, setOrderBy] = React.useState(qs.orderBy || "lastUpdated");


    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (e, row) => {
        //console.log(e, row);
    };

    const doReprocess = (row) => {
        api.reprocessFile(params.orgKey, params.streamOrgKey, params.streamName, row.id).then(
            () => {doSearch(true);}
        );
    };

    const columns = buildStreamFileColumns(userState, params, organization, doReprocess);

    const doSearch = (force = false) => {
        const newSearch = `?page=${page}&size=${rowsPerPage}&orderBy=${orderBy}&order=${order}`;
        if(newSearch !== history.location.search || force) {
            const sort = orderBy + "," + order.toUpperCase();
            api.loadStreamFiles(params.orgKey, params.streamOrgKey, params.streamName, rowsPerPage, page, sort);
            api.loadStream(params.orgKey, params.streamOrgKey, params.streamName);

            history.replace({
                path: history.location.pathname,
                search: newSearch
            });
        }
    };

    const handleFileUpload = () => {
        var el = window._protected_reference = document.createElement("INPUT");
        el.type = "file";
        el.accept = "*";
        el.addEventListener('change', function(ev2) {
            if(el.files.length) {
                const file = el.files[0];
                const notification = {
                    key: file.name,
                    iconName: "upload_file_icon",
                    message: "Uploading File: " + file.name,
                    type: "default",
                    canClose: false,
                    canAutoClose: false,
                    progress: 0
                };
                onPush(notification);
                api.uploadFile(
                    params.orgKey,
                    params.streamOrgKey,
                    params.streamName,
                    "file",
                    file,
                    (percent) => { //onProgress
                        onPush({
                            ...notification,
                            progress: percent
                        });
                    }
                )
                .then(() => {
                    onPush({
                        ...notification,
                        message: "Upload Success: " + file.name,
                        type: "success",
                        canClose: true,
                        canAutoClose: true,
                        progress: 100
                    });
                    doSearch(true); // retrieve the latest list of files after upload.
                }).catch(() => {
                    onPush({
                        ...notification,
                        key: file.name,
                        message: "Upload Failed: " + file.name,
                        type: "error",
                        canClose: true,
                        progress: null
                    });
                });
            }
        });
        el.click();
    };

    useEffect(() => { // didMount
            api.loadStreams(params.orgKey); // load the streams so we have a little detail on this stream
            doSearch(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params.orgKey]);

    useEffect(() => { // didMount
            doSearch(false);
            clearInterval(refreshIntervalRef.current);
            refreshIntervalRef.current = setInterval(
                () => {
                    doSearch(true);
                },
                1000*60*1 // refresh once a minute
            );
            return () => {
                clearInterval(refreshIntervalRef.current);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params.orgKey, page, rowsPerPage, order, orderBy]
    );

    if(!organization) {
        return (
            <ErrorPage statusCode={404} />
        );
    }

    return (
        <React.Fragment>
            <Box sx={classes.header}>
                <Button
                    color="default"
                    size="medium"
                    sx={classes.streamAction}
                    onClick={() => doSearch(true)}
                    startIcon={<RefreshIcon />}
                >
                    Refresh Stream
                </Button>
                <Button
                    color="primary-alt"
                    size="medium"
                    sx={classes.streamAction}
                    onClick={handleFileUpload}
                    startIcon={<UploadIcon />}
                >
                    Upload File
                </Button>
                <Typography variant="h4" gutterBottom>
                    Files
                </Typography>
                <Typography color="text.secondary" variant="subtitle1">
                    File statuses related to the stream
                </Typography>
            </Box>
            <EnhancedTable
                dense={true}
                columns={columns}
                rows={streamFilesStatus === "ERROR" ? [] : streamFiles.content}
                isLoading={streamFilesStatus === "LOADING"}
                page={page}
                order={order}
                orderBy={orderBy}
                total={streamFiles.totalElements}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={pageOptions}
                onRequestSort={handleRequestSort}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                onRowClick={handleRowClick}
                emptyListMessage={streamFilesStatus === "ERROR" ? "Something bad happened, Please try again in a few moments." : "No items available."}
                pagination={true}
            />
        </React.Fragment>
    );
};

export default withRouter(connectAppStore(([state, dispatch], props) => {
    const params = props.match.params || {};
    return {
        userState: state.user,
        streamsStatus: state.streams.status,
        stream: state.stream.data || {},
        streamFilesStatus: state.streamFiles.status,
        streamFiles: state.streamFiles.data || {},
        organization: getOrganization(state.user, params.orgKey),
        getOrgName: (orgKey) => getOrganizationName(state.user, orgKey)
    };
})(StreamFiles));
