import html2canvas from "html2canvas";

export const getFileName = (title = "untitled") => {
    const date = new Date();
    return title.toLowerCase().replace(/[^a-z0-9]/gi, '-') + "-" + date.getMonth() + "-" + date.getDay() + "-" + date.getFullYear();
};

export const exportImage = (title = "", element = null, bgColor = "#fff") => {
    element = element === null ? document.body : element;
    const filename = getFileName(title);
    html2canvas(element, {
        backgroundColor: bgColor,
        foreignObjectRendering: false,
        x: window.scrollX + element.getBoundingClientRect().left ,
        y: window.scrollY + element.getBoundingClientRect().top,
    }).then(function (canvas) {
        const link = document.createElement('a');
        link.download = filename + '.png';
        link.href = canvas.toDataURL();
        link.click();

    });
};

export const exportCSV = (title = "", data) => {
    const filename = getFileName(title);
    const csvContent = "data:text/csv;charset=utf-8," + data[0].map(row => {
        return (row.map(cell => cell[0]).join(","));
    }).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.download = filename + ".csv";
    link.href = encodedUri;
    link.click();
};